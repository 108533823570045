import { isQueryParamMatched } from "./queryParamMatcher";
import { resolveStaticAssetPath } from "./staticAsset";

/**
 * @typedef {Object} QueryParamMatcher
 * @property {string} name Query param name to match.
 * @property {string} value Query param value to match.
 */

/**
 * @typedef {Object} Partnership
 * @property {QueryParamMatcher | QueryParamMatcher[]} [showWhenQueryParam] Conditions to match partnership
 * @property {string} [swapLogoUrl] Partnership logo src
 * @property {number} [swapLogoHeightPx] Logo height in pixels
 */

/**
 * @typedef {Object} PartnershipResult
 * @property {string} imageUrl Matched partnership logo src
 * @property {number | null} imageHeightPx Logo height in pixels
 */

/**
 * @param {Partnership} config
 * @returns {PartnershipResult}
 */
const getPartnershipLogoImage = (config) => {
  return {
    imageUrl: config.swapLogoUrl ? resolveStaticAssetPath(config.swapLogoUrl) : "",
    imageHeightPx: config.swapLogoHeightPx ?? null,
  };
};

/**
 * @param {Partnership} config
 * @returns {boolean}
 */
const isPartnershipMatched = (config) => {
  if (!config.swapLogoUrl || !config.showWhenQueryParam) {
    return null;
  }

  return (
    Array.isArray(config.showWhenQueryParam)
      ? config.showWhenQueryParam
      : [config.showWhenQueryParam]
  ).some((condition) => isQueryParamMatched(condition.name, condition.value));
};

/**
 * @param {Partnership | Partnership[]} partnershipConfigInUserJourney
 * @returns {PartnershipResult | null}
 */
export const matchPartnership = (partnershipConfigInUserJourney) => {
  if (!partnershipConfigInUserJourney) return null;

  const matchedPartner = (
    Array.isArray(partnershipConfigInUserJourney)
      ? partnershipConfigInUserJourney
      : [partnershipConfigInUserJourney]
  ).find((config) => isPartnershipMatched(config));

  return matchedPartner ? getPartnershipLogoImage(matchedPartner) : null;
};
