import DOMPurify from "dompurify";
import React from "react";
import AppStore from "../../../store/AppStore";

const MessageBox = ({ name, attributes, label, values }) => {
  const type = attributes.MessageType ? attributes.MessageType : "";
  const messageBoxOccupationIsVisible = AppStore.getMessageBoxOccupationVisibility();

  if (!AppStore.getMessageBoxConvictionVisibility()) {
    AppStore.setVisibilityControls({ ["message-box-cross-sell-convictions"]: false });
    AppStore.setVisibilityControls({ ["ld-convictions-opt-in"]: false });
  }

  if (!messageBoxOccupationIsVisible) {
    AppStore.setVisibilityControls({ ["message-box-cross-sell-occupations"]: false });
  }

  return (
    <div className={`${type} ${type}-${name}`}>
      <div className={`${type}-container`}>
        <div className={`${type}-title-container`}>
          {attributes.ShowImage && <div className={`${type}-image`}></div>}
          {attributes.ShowTitle && (
            <label
              className={`${type}-title`}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(values.Title, {
                  USE_PROFILES: { html: true },
                  ADD_TAGS: ["a", "p", "div", "span"],
                  ADD_ATTR: ["href", "target", "name", "class"],
                }),
              }}></label>
          )}
        </div>
        {attributes.ShowSubTitle && (
          <label
            className={`${type}-subtitle`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(values.SubTitle, {
                USE_PROFILES: { html: true },
                ADD_TAGS: ["a", "p", "div", "span"],
                ADD_ATTR: ["href", "target", "name", "class"],
              }),
            }}></label>
        )}
        {attributes.ShowLabel && (
          <label
            className={`${type}-label`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(label, {
                USE_PROFILES: { html: true },
                ADD_TAGS: ["a", "p", "div", "span"],
                ADD_ATTR: ["href", "target", "name", "class"],
              }),
            }}></label>
        )}
      </div>
    </div>
  );
};

export default MessageBox;
