import ClientLogging from "@providers/loggingProvider";
import queryString from "query-string";
import { cookies } from "./cookies";

const TOKEN_QUERY_PARAM_NAME = "prepopQuoteJwt";
const TOKEN_COOKIE_NAME = "PrepopQuote";

export function handlePrepopulateJwt() {
  const parsedQueryStr = queryString.parse(window.location.search);

  const token = parsedQueryStr[TOKEN_QUERY_PARAM_NAME];

  if (!token) return;

  cookies.set(TOKEN_COOKIE_NAME, token, {
    secure: !!import.meta.env.PROD,
    sameSite: "Lax",
  });

  try {
    window.history.replaceState(
      null,
      "",
      queryString.exclude(location.href, [TOKEN_QUERY_PARAM_NAME])
    );
  } catch (err) {
    ClientLogging.logError(err, "Remove prepopulation token from query string");
  }
}
