export const listOfLicenceDefenceOccupationsArray = [
    "HGV Driver",
    "Builder",
    "Carpenter",
    "Mechanic",
    "Electrician",
    "Plumber",
    "Electrical Engineer",
    "Landscape Gardener",
    "Painter And Decorator",
    "Plumbing & Heating Engineer",
    "Lorry Driver",
    "Gardener",
    "Electrical Contractor",
    "Bricklayer",
    "Plasterer",
    "Ground Worker",
    "Roofer",
    "Van Driver",
    "Taxi Driver",
    "HGV Mechanic",
    "Farmer",
    "Motor Mechanic",
    "Technician",
    "Driver",
    "Cleaner",
    "Heating Engineer",
    "Maintenance Man or Woman",
    "Floor Layer",
    "Fabricator",
    "Police Officer",
    "Bus Driver",
    "Courier",
    "Tree Surgeon",
    "Agricultural Engineer",
    "Gas Technician",
    "Scaffolder",
    "Road Worker",
    "Hairdresser",
    "Train Driver",
    "Vehicle Valet",
    "Window Cleaner",
    "Building Contractor",
    "Double Glazing Fitter",
    "Paramedic",
    "Carpetfitter",
    "Firefighter",
    "Labourer",
    "Soldier",
    "Sales Director"
];