import React from "react";
import SideBar from "./components/page-components/SideBar";

const AppError = () => (
  /// / leave this as a single line
  <div className="wrapper-apperror d-flex flex-row">
    <div className="wrapper-apperror-message p-3">
      <div className="form-group page-title pt-2 pb-4 title">
        Application Error
      </div>
      <form noValidate="">
        <div className="qs-label d-inline-block col-lg-12 col-md-12 col-sm-12 col-12 pull-left pl-0 pr-0">
          <div className="applicationerror-text-label">
            <label className="label label-applicationerror-text">
              We are sorry, there appears to have been a technical problem
              whilst dealing with your quotation. This problem has been logged
              and will be investigated by our technical team. If you continue to
              have problems getting a quote please call us on{" "}
              <a
                href="tel:0330 022 8038"
                className="phone extra-pages-phone-anchor"
              >
                <span className="phone extra-pages-phone">0330 022 8038</span>
              </a>
            </label>
          </div>
        </div>
      </form>
    </div>

    <div className="col-12 col-md-4 bd-sidebar d-inline-block pull-right">
      <SideBar
        sidebar_info={{
          phone: "0330 022 8038",
          title: "Can we help?",
          text: "If you still can't find what you are looking for or are not sure about something please call the team on",
          help: "The team is available from: <br/> Mon - Fri 9am - 5pm",
          button_label: "More help",
          version: "base",
        }}
      />
    </div>
  </div>
);

export default AppError;
