import dayjs from "dayjs";
import { sha256 } from "js-sha256";
import { cookies } from "../helpers/cookies";
import dateParser from "../helpers/dateParser";
import ClientLogging from "../providers/loggingProvider";
import AppStore from "../store/AppStore";

const Covatic = {
    settings: {},
    enabled: false,
    clientKey: "",
    saltKey: "",
    init(settings) {
        try {
            this.settings = settings.UserJourneySettings?.tracking.covatic;
            this.enabled = this.settings.enabled;
            this.clientKey = this.settings.clientKey;
            this.saltKey = this.settings.saltKey;

            if (this.enabled === true) {
                const head = document.getElementsByTagName("head")[0];
                const toInsert = document
                    .createRange()
                    .createContextualFragment(
                        this.getCovaticTag(this.clientKey)
                    );
                head.appendChild(toInsert);
            }
        } catch (ex) {
            ClientLogging.logError(ex, "Covatic init");
        }
    },
    getCovaticTag() {
        const tag = `<script src="https://browser.covatic.io/sdk/v1/bauer.js" type="text/javascript"></script>`;
        return tag;
    },
    handleNewPage() {
        try {
            if (typeof covaticBrowserSDK !== "undefined") {
                let currentPage = AppStore.getCurrentPageName();

                if (cookies.get("CookieConsent") === "true") {
                    /*client config*/
                    covaticBrowserSDK.Client(this.clientKey);
                    covaticBrowserSDK.notifyConsent(true);

                    covaticBrowserSDK.isReady().then(function (isSdkReady) {
                        if (isSdkReady) {

                            covaticBrowserSDK.notifyEvent(
                                new covaticBrowserSDK.MediaConsumption(
                                    currentPage, "insurance-quote", {}, [], "", 0, 0,
                                    covaticBrowserSDK.MediaConsumption.Action.commenceMedia,
                                    covaticBrowserSDK.MediaConsumption.Type.text)
                            );

                            covaticBrowserSDK.notifyEvent(
                                new covaticBrowserSDK.MediaConsumption(
                                    currentPage, "insurance-quote", {}, [], "", 0, 0,
                                    covaticBrowserSDK.MediaConsumption.Action.ceaseMedia,
                                    covaticBrowserSDK.MediaConsumption.Type.text)
                            );

                            Covatic.sendPostcodeNotification();

                            //send postcode notificaation if proposer's age > 18
                            Covatic.sendAgeNotification();

                            Covatic.sendLinkingHash();
                        }
                    });
                }
            }
        } catch (ex) {
            ClientLogging.logError(ex, "Covatic handle newpage");
        }
    },
    sendPostcodeNotification() {
        let postcodeValue = AppStore.getControlByName("proposer-postcode")?.trim();
        if (postcodeValue)
            covaticBrowserSDK.notifyPostcode(postcodeValue.replace(/\s+/g, ''));
    },
    sendAgeNotification() {
        let dob = AppStore.getControlByName("date-of-birth")?.trim();
        if (dob) {
            let age = dateParser.getDateDiffInYears(dob, dayjs());

            if (age >= 18)
                covaticBrowserSDK.notifyAge(age);
        }
    },
    sendLinkingHash() {
        let proposerEmail = AppStore.getControlByName("email")?.trim();
        if (proposerEmail)
            covaticBrowserSDK.setLinkingHash(sha256(`${proposerEmail}${this.saltKey}`));
    }

}
export default Covatic;
