import { toJS } from "mobx";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";
import loadformJson from "../jsonsource/loadform.json";
import ClientLogging from "../providers/loggingProvider";
import AppStore from "../store/AppStore";

const Floodlight = {
  data: {},

  schema: {},

  userJourneyStructure: {},

  enabled: false,

  init(userJourney) {
    try {
      const settings = userJourney.UserJourneySettings;
      if (
        !isNullOrUndefined(settings.tracking) &&
        !isNullOrUndefined(settings.tracking.floodlight)
      ) {
        this.enabled = settings.tracking.floodlight.enabled === "true";
        if (this.enabled) {
          this.userJourneyStructure = JSON.parse(loadformJson.userJourneyStructure);

          this.schema = settings.tracking.floodlight.schema;
        }
      }
    } catch (ex) {
      ClientLogging.logError(ex, "Floodlight init");
    }
  },

  formatValues(oldValue, floodlightKey, controlName) {
    let value = oldValue;
    switch (floodlightKey) {
      case "u10":
      case "u2":
      case "u17":
      case "u23":
      case "u30":
      case "u38":
      case "u41":
      case "u43":
      case "u50":
      case "u73": //date formatting
        if (!isNullOrUndefined(value) && value !== "")
          value = value.split("-").reverse().join("/");
        break;
      case "u7":
      case "u19":
      case "u42":
      case "u81":
      case "u25":
      case "u28":
      case "u29":
      case "u53":
      case "u72":
      case "u67":
      case "u74":
        if (!isNullOrUndefined(value) && value !== "") {
          if (value === true || value === "yes") value = "True";
          else value = "False";
        }
        break;
      case "u84":
      case "u85": //dob only year
        if (!isNullOrUndefined(value) && value !== "") value = value.split("-")[0];
        break;
      case "u13":
      case "u14": //occupation industry main rider
        value = this.getHelperValues(value, controlName);
        break;
      case "u46":
      case "u47": //occupattion industry add rider
        value = this.getHelperValues(value, controlName);
        break;
      case "u31": //conviction desc
      case "u24": //claims desc
        value = this.getHelperValues(value, controlName + "-helper");
        break;
      case "u55": //code lookup
        value = this.getHelperValues(value, controlName);
        break;
      case "u4": //postcode only first part
        if (!isNullOrUndefined(value) && value !== "") value = value.split(" ")[0];
        break;
      case "u20": //no. of add drivers; for bike detect if additional control is set
        if (!isNullOrUndefined(value) && value !== "") value = "1";
        break;
      case "u64": //model
        if (!isNullOrUndefined(value) && value !== "") value = value.split(",")[0];
        break;
      case "u70":
      case "u78":
        if (!isNullOrUndefined(value) && value !== "") {
          if (value === true || value === "yes")
            value = AppStore.getControlByName("markings-dropdown");
          else value = "NONE";
        }
        break;
      case "u69":
        if (!isNullOrUndefined(value) && value !== "") {
          if (value === true || value === "yes")
            value = AppStore.getControlByName("security-devices-dropdown");
          else value = "NONE";
        }
        break;
      case "u83":
        //Quote confirmation; page title from yourquotes, quotesummary
        const pages = controlName.split("|");
        value =
          AppStore.currentPageName === pages[0] || AppStore.currentPageName === pages[1]
            ? "1"
            : "0";
        break;
      case "u82":
        value = AppStore.getQuoteRefUid();
        if (!isNullOrUndefined(value) && value !== "") value = value.replace(/-/g, "");
        break;
    }
    return value;
  },

  getHelperValues(code, controlName) {
    //Replacement for AppSotore.getDescriptionsByCode. Needed because userjourneystructure in appstore
    //does not load all controls, hence the descriptions aren't available, loadform.json is used instead.

    let userJourneyStructure = this.userJourneyStructure;
    let pages = [];
    let description = "";
    let controls = [];
    let matchList = [];
    pages = userJourneyStructure.Pages;
    pages.map((page) => {
      page.Components &&
        page.Components[0].Controls.forEach((control) => {
          controls.push(control);
          control.Children.forEach((child) => {
            controls.push(child);
          });
        });
    });
    controls.forEach((c) => {
      if (c.Name === controlName) {
        matchList = c.ControlValuesList;
        return matchList;
      }
    });

    if (matchList && matchList.length > 0) {
      matchList.forEach((element) => {
        if (element.Key === code) {
          {
            description = element.Value;
            return description;
          }
        }
      });
    }
    return description;
  },

  updateData() {
    try {
      Object.keys(this.schema).forEach((section) => {
        Object.keys(this.schema[section]).forEach((floodlightKey) => {
          let controlName = this.schema[section][floodlightKey];
          let value = "";

          if (section === "main_form") {
            value = AppStore.getControlByName(controlName);
          } else if (section === "predefined") {
            value = this.schema[section][floodlightKey];
          } else {
            //section is assumed to be an array and will pick only first entry; used for claims/convictions
            value = toJS(AppStore.getControlByName(section));
            if (!isNullOrUndefined(value) && value.length > 0)
              value = value[0][controlName];
            else value = "";
          }

          value = this.formatValues(value, floodlightKey, controlName);

          if (!isNullOrUndefined(value) && value !== "" && !Number.isNaN(value))
            this.data[floodlightKey] = value;
        });
      });
    } catch (ex) {
      ClientLogging.logError(ex, "Floodlight updateData");
    }
  },

  handleNewPage() {
    try {
      if (this.enabled) {
        this.updateData();

        //Remove tag to prevent duplication from GTM
        const tag = document.getElementsByName("floodlight-push");
        if (!isNullOrUndefined(tag) && tag.length > 0) {
          tag[0].remove();
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "floodlight-data",
          "form-data": this.data,
        });
      }
    } catch (ex) {
      ClientLogging.logError(ex, "Floodlight handleNewPage");
    }
  },
};

export default Floodlight;
