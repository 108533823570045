import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import React from "react";
import { isQueryParamMatched } from "../helpers/queryParamMatcher";
import Button from "./controls/Button.Component";

const Footer = ({ footerInfo, isResultsPage }) => {
  const year = new Date().getFullYear();

  const copyrightContent = footerInfo?.copyright
    ? `\u00A9 ${footerInfo.copyright}`.replace("%%YEAR%%", ` ${year} `)
    : `\u00A9 ${year}`;
  const mustSwapContent = footerInfo?.textWhenQueryParam
    ? isQueryParamMatched(
        footerInfo.textWhenQueryParam.name,
        footerInfo.textWhenQueryParam.value
      )
    : false;
  const content = (
    (mustSwapContent ? footerInfo.textWhenQueryParam.text : footerInfo?.text) || ""
  ).replace("%%COPYRIGHT%%", `${copyrightContent}`);
  const terms = footerInfo?.termsLink ? footerInfo.termsLink.split("|")[0] : "";
  const termsLink = footerInfo?.termsLink ? footerInfo.termsLink.split("|")[1] : "";

  const privacy = footerInfo?.privacyLink ? footerInfo.privacyLink.split("|")[0] : "";
  const privacyLink = footerInfo?.privacyLink ? footerInfo.privacyLink.split("|")[1] : "";

  const cookies = footerInfo?.cookiesLink ? footerInfo.cookiesLink.split("|")[0] : "";
  const cookiesLink = footerInfo?.cookiesLink ? footerInfo.cookiesLink.split("|")[1] : "";

  const footerCopyright = footerInfo?.copyright
    ? footerInfo.copyright.split("|")[0].replace("%%YEAR%%", ` ${year} `)
    : "";
  const showAdditionalFooterText =
    footerInfo?.showAdditionalFooterText === "true" || false;
  let additionalFooterText = footerInfo?.additionalFooterText
    ? footerInfo.additionalFooterText
    : "";

  const hasCompetition = footerInfo?.hasCompetition === "true" || false;
  const getAnchor = (link, content) => {
    let hrefLink = hasCompetition ? link : `${import.meta.env.VITE_SITE_ROUTE}/${link}`;
    return `<a
        target="_blank"
        href="${hrefLink}"
        rel="noreferrer">
        ${content}
      </a>`;
  };

  if (footerInfo.additionalFooterTextLink && footerInfo.additionalFooterTextLinkText) {
    additionalFooterText = additionalFooterText.replace(
      "%%LINK%%",
      getAnchor(
        footerInfo.additionalFooterTextLink,
        footerInfo.additionalFooterTextLinkText
      )
    );
  }

  const showFooterLinks = footerInfo?.showFooterLinks === "true" || false;

  let replacedText = content.replace("%%COOKIES%%", getAnchor(cookiesLink, cookies));
  replacedText = replacedText.replace("%%TERMSCONDITIONS%%", getAnchor(termsLink, terms));
  replacedText = replacedText.replace(
    "%%PRIVACYPOLICY%%",
    getAnchor(privacyLink, privacy)
  );

  let replacedTextContent = (
    <p
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(replacedText, {
          USE_PROFILES: { html: true },
          ADD_TAGS: ["a", "p", "div", "span", "nav", "ul", "li"],
          ADD_ATTR: ["href", "target", "name", "class"],
        }),
      }}
    />
  );

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer
      data-testid="footer"
      className={`footer footer-fixed-bottom${
        isResultsPage === "yourquotes" ? " results-footer-bg" : ""
      } p-4`}>
      <div className="container col-12 col-sm-12 col-md-12 col-lg-10">
        {showAdditionalFooterText && (
          <p
            data-testid="footer-additional-text"
            className="footer-additional-text"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(additionalFooterText, {
                USE_PROFILES: { html: true },
                ADD_TAGS: ["a", "p", "div", "span", "nav", "ul", "li"],
                ADD_ATTR: ["href", "target", "name", "class"],
              }),
            }}
          />
        )}

        {replacedTextContent}

        {showFooterLinks && (
          <div
            data-testid="footer-links"
            className="footer footer-links-wrapper col-12 col-sm-12 col-md-12 col-lg-10">
            {footerCopyright && (
              <div data-testid="footer-copyright">{footerCopyright}</div>
            )}
            <div>
              <a
                key="anchor-terms"
                target="_blank"
                href={`${import.meta.env.VITE_SITE_ROUTE}/${termsLink}`}
                rel="noreferrer">
                {terms}
              </a>
              <span>{footerInfo.linkSeparator || ""}</span>
              <a
                key="anchor-privacy"
                target="_blank"
                href={`${import.meta.env.VITE_SITE_ROUTE}/${privacyLink}`}
                rel="noreferrer">
                {privacy}
              </a>
            </div>
          </div>
        )}

        {footerInfo?.showLogo === "true" && (
          <div className="footer-logo-wrapper">
            <div data-testid="footer-logo" className="footer-logo" />
          </div>
        )}

        {footerInfo?.showBackToTopButton === "true" && (
          <Button
            type="button"
            id="back-to-top"
            name="back-to-top"
            label="Back to top"
            className="back-to-top"
            handleClick={handleBackToTop}
            icon="arrow-up"
          />
        )}
      </div>
    </footer>
  );
};

Footer.propTypes = {
  footerInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isResultsPage: PropTypes.string.isRequired,
};

export default Footer;
