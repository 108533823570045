import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Label from "../controls/StaticControls/Label.Component";

const CheckboxSingle = ({ id, name, label, handleCheckboxChange, currentValue }) => {
  const [randomNumber] = useState(() => Math.floor(Math.random() * 100) + 1);
  const [isChecked, setIsChecked] = useState(() =>
    ["bike-competition", "bike-competition-promo"].includes(name) ? false : !!currentValue
  );
  const inputId = name + "_" + randomNumber;

  useEffect(() => {
    handleCheckboxChange(name, isChecked);
  }, []);

  return (
    <div className={`form-group form-group-container form-group-${name} checkbox-single`}>
      <label htmlFor={inputId} className="row">
        <div className="label-group col-lg-9 col-md-9 col-sm-9 col-9 d-inline-block">
          <Label id={id} label={label} forInput={inputId} />
        </div>
        <div className="form-group col-lg-3 col-md-3 col-sm-3 col-3 d-inline-block">
          <div className="form-check form-checkbox form-check-inline">
            <input
              id={inputId}
              className="form-check-input"
              type="checkbox"
              name={name}
              onChange={(event) => {
                handleCheckboxChange(name, event.currentTarget.checked);
                setIsChecked(event.currentTarget.checked);
              }}
              checked={isChecked}
            />
            <label
              className="form-check-label"
              htmlFor={inputId}
              id={`label-for-${name}`}>
              <svg
                aria-hidden="true"
                focusable="false"
                viewBox="0 0 19 15"
                enableBackground="new 0 0 19 15;">
                <path
                  className="st0"
                  d="M17.943,3.859l-9.88,10.065c-0.754,0.769-1.977,0.769-2.731,0.001c0,0,0,0,0,0L1.075,9.588
	                                    c-0.754-0.769-0.754-2.015,0-2.783s1.977-0.769,2.732,0l0,0l2.891,2.947l8.514-8.675c0.754-0.769,1.977-0.769,2.732,0
	                                    c0.754,0.769,0.754,2.015,0,2.783"
                />
              </svg>
            </label>
          </div>
        </div>
      </label>
    </div>
  );
};

CheckboxSingle.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CheckboxSingle;
