import AppError from "./AppError";

const BoundaryFallback = () => {
  return (
    <div className="error-boundary-wrapper container bd-container mt-2">
      <AppError />
    </div>
  );
};

export default BoundaryFallback;
