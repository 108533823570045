import reactHtmlParser from "html-react-parser";
import PropTypes from "prop-types";
import React from "react";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import { resolveStaticAssetPath } from "../../../helpers/staticAsset";
import AppStore from "../../../store/AppStore";
import Error from "../StaticControls/Error.Component";
import Info from "../StaticControls/Info.Component";
import Label from "../StaticControls/Label.Component";
import Tooltip from "../StaticControls/Tooltip.Component";

const FormRowAd = ({
  id,
  name,
  label,
  controlValuesList,
  handleChange,
  handleInteraction,
  currentValue,
  helpMessages,
  defaultValue,
  infoMessages,
  validation,
  attributes,
}) => {
  const errorClass = validation && validation.isValid === false ? "error" : "";
  const errorNextLineClass = name === "preferred-payment" ? "next-line" : "";
  const widthClass =
    name === "preferred-payment" ? "col-lg-8 col-md-8" : "col-lg-4 col-md-5";
  const highlightInfoClass = name === "preferred-payment" ? "info-highlight" : "";
  const buttonWidthClass =
    errorNextLineClass === "" ? "col-lg-4 col-md-8" : "col-lg-8 col-md-8";

  const adTitle = !isNullOrUndefined(attributes.adTitle) ? attributes.adTitle : "";
  const adContent = !isNullOrUndefined(attributes.adContent) ? attributes.adContent : "";

  let adImageElement = "";

  if (attributes.adHasImage) {
    const adImageUrl = resolveStaticAssetPath(attributes.adImageName);
    adImageElement = '<img src="' + adImageUrl + '" alt=" "' + "/>";
  }

  const versionType =
    !isNullOrUndefined(AppStore.getUserJourneySettings().customControls) &&
    !isNullOrUndefined(AppStore.getUserJourneySettings().customControls.customRadio)
      ? AppStore.getUserJourneySettings().customControls.customRadio
      : "";
  const versionTypeStyle = versionType !== "" ? "radio-version-" + versionType : "";

  const desktopTooltipToggle =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.desktopTooltipToggle)
      ? attributes.desktopTooltipToggle
      : false;

  if (isNullOrUndefined(currentValue) || currentValue.length === 0) {
    AppStore.setFormData({ [name]: defaultValue !== "" ? defaultValue : "" });
  }

  return (
    <React.Fragment>
      <div
        className={`form-group form-group-container form-group-${name} ${
          versionType === "button" ? "custom-group" : ""
        }`}>
        <div id={`row-${name}`} className={`row row-${errorClass} `}>
          <div className="form-row-ad-container">
            <div className="form-row-ad-text">
              <div className="form-row-ad-title">{reactHtmlParser(adTitle)}</div>
              <div className="form-row-ad-content">{reactHtmlParser(adContent)}</div>
            </div>

            {attributes.adHasImage && (
              <div className="form-row-ad-image">{reactHtmlParser(adImageElement)}</div>
            )}
          </div>
          <div className="label-group col-lg-4 col-md-4 col-sm-12 col-12">
            <Label id={name} label={label} />
            <Tooltip
              helpMessages={helpMessages != null ? helpMessages.help : ""}
              controlName={name}
              desktopToggle={desktopTooltipToggle}
            />
          </div>
          <div
            className={`form-group ${
              versionType === "button"
                ? `${buttonWidthClass} col-sm-12 col-12`
                : `${widthClass} col-sm-12 col-12`
            }`}>
            {controlValuesList.map((item) => (
              <div
                key={name + "_" + item.Key}
                className={`form-check form-check-inline ${versionTypeStyle}`}>
                <input
                  id={name + "_" + item.Key}
                  key={name + "_" + item.Key}
                  className="form-check-input"
                  type="radio"
                  name={name}
                  value={item.Key}
                  onChange={(event) => {
                    handleInteraction(event);
                    handleChange(event);
                  }}
                  checked={
                    isNullOrUndefined(currentValue) || currentValue.length === 0
                      ? !isNullOrUndefined(defaultValue) &&
                        defaultValue.length !== 0 &&
                        item.Key === defaultValue
                        ? "checked"
                        : ""
                      : item.Key === currentValue
                      ? "checked"
                      : ""
                  }
                />
                <label className="form-check-label" htmlFor={name + "_" + item.Key}>
                  {item.Value}
                </label>
              </div>
            ))}
            <Info
              infoMessages={infoMessages != null ? infoMessages.info : ""}
              className={highlightInfoClass}
            />
          </div>
          <Error
            validation={validation ? validation : ""}
            className={errorNextLineClass}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

FormRowAd.propTypes = {
  name: PropTypes.string.isRequired,
  attributes: PropTypes.object.isRequired,
};

export default FormRowAd;
