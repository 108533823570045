import DOMPurify from "dompurify";
import { forwardRef } from "react";

const CheckboxLabel = forwardRef(
  ({ parentName, label, version, isChecked, keyDownHandler }, ref) => {
    // changeHandler enacts the changes that clicking a checkbox would cause
    const v1Check = (
      <label
        className="form-check-label"
        htmlFor={parentName}
        id={`label-for-${parentName}`}>
        <svg
          aria-hidden="true"
          focusable="false"
          viewBox="0 0 19 15"
          enableBackground="new 0 0 19 15;">
          <path
            className="st0"
            d="M17.943,3.859l-9.88,10.065c-0.754,0.769-1.977,0.769-2.731,0.001c0,0,0,0,0,0L1.075,9.588c-0.754-0.769-0.754-2.015,0-2.783s1.977-0.769,2.732,0l0,0l2.891,2.947l8.514-8.675c0.754-0.769,1.977-0.769,2.732,0c0.754,0.769,0.754,2.015,0,2.783"
          />
        </svg>
      </label>
    );

    const v2Check = (
      <label
        className="form-check-label"
        htmlFor={parentName}
        id={`label-for-${parentName}`}>
        <svg
          aria-hidden="true"
          focusable="false"
          viewBox="0 0 19 15"
          enableBackground="new 0 0 19 15;">
          <path
            className="st0"
            d="M15 0L6.59999 8.25L2.925 4.575L0.75 6.75L4.425 10.425L6.59999 12.6L8.77501 10.425L17.175 2.175L15 0Z"
          />
        </svg>
      </label>
    );

    const v3Check = (
      <label
        id={`${parentName}-label`}
        ref={ref}
        htmlFor={parentName}
        className={`form-check form-checkbox form-check-label form-check-inline custom-checkbox-container ${
          isChecked ? "checked" : ""
        }`}
        data-testid={`${parentName.replace(" ", "-").toLowerCase()}_checkbox`}
        onKeyDown={keyDownHandler}
        tabIndex={0}>
        <div id={`label-for-${parentName}`}>
          <i />
        </div>
        {label && (
          <span
            className="form-check-control-label"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(label, {
                USE_PROFILES: { html: true },
                ADD_TAGS: ["a", "p", "div", "span"],
                ADD_ATTR: ["href", "target", "name", "class"],
              }),
            }}></span>
        )}
      </label>
    );

    switch (version) {
      case 1:
        return v1Check;
      case 2:
        return v2Check;
      case 3:
        return v3Check;
      default:
        return v1Check;
    }
  }
);

export default CheckboxLabel;
