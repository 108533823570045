import { isNullOrUndefined } from "@helpers/isNullOrUndefined";
import { observer } from "mobx-react";
import React, { Fragment, useEffect, useState } from "react";
import ControlRowFactory from "../../factories/ControlRowFactory";
import { default as helpers } from "../../helpers/controlsLogicHelper";
import AppStore from "../../store/AppStore";
import Error from "./StaticControls/Error.Component";
import Info from "./StaticControls/Info.Component";
import Label from "./StaticControls/Label.Component";
import TextArea from "./StaticControls/TextArea.Component";
import Tooltip from "./StaticControls/Tooltip.Component";

const LicenceNumber = ({
  id,
  name,
  label,
  type,
  required,
  handleBlur,
  handleChange,
  currentValue,
  defaultValue,
  helpMessages,
  infoMessages,
  extraInfo,
  validation,
  attributes,
}) => {
  const customFormGroupClass = attributes?.customFormGroupClass ?? "";
  const errorClass = validation && validation.isValid === false ? "row-error" : "";
  const allowedCharsRegex = new RegExp(
    !isNullOrUndefined(attributes.allowedCharsRegex) ? attributes.allowedCharsRegex : null
  );
  const sectionMaxLengths = attributes.sectionMaxLengths || {
    1: 5,
    2: 6,
    3: 2,
    4: 3,
  };

  let initialValues = { 1: "", 2: "", 3: "", 4: "" };
  const [customValidation, setCustomValidation] = useState(false);
  const [licenceNumberValues, setLicenceNumberValues] = useState(initialValues);
  const defaultValidationMessage = attributes.defaultValidationMessage || "";
  const usesCustomValidation = attributes.usesCustomValidation || false;

  // select an appropriate validation message, if usesCustomValidation is set in attributes
  // if validation is set inidividually for each input, provide a default message if more than one field throws an error
  useEffect(() => {
    if (usesCustomValidation) {
      if (validation?.messages?.length > 1) {
        setCustomValidation({
          isValid: false,
          messages: defaultValidationMessage,
        });
      } else setCustomValidation(false);
    }
  }, [validation]);

  // returns a 16-character value to set to formData.
  // uses the values from the inputs (including newly-added values), and fills any non-filled inputs with blank spaces
  const getCompleteNumber = (newValue = null, section = null) => {
    let completeValue = "";

    [1, 2, 3, 4].forEach((int) => {
      let newSectionValue =
        int === section && newValue ? newValue : licenceNumberValues[int]; // if an input has changed, use the newvalue for the field instead

      // add blank spaces to meet the maximum length of the given input
      const diffToMaxLength = sectionMaxLengths[int] - newSectionValue.length;
      for (let i = 0; i < diffToMaxLength; i++) {
        newSectionValue += " ";
      }

      completeValue += newSectionValue;
    });
    return completeValue;
  };

  // on displaying the control, set the input values to autocomplete part of the licence number, based on the user's details
  const licenceNumberAutocomplete = () => {
    const formatDateofbirth = (dob, isFemale) => {
      dob = dob.split("-");
      let monthFirstDigit = parseInt(dob[1][0]);
      monthFirstDigit += isFemale ? 5 : 0;
      dob[1] = monthFirstDigit.toString() + dob[1].substr(1);

      let result = dob[0][2] + dob[1] + dob[2] + dob[0][3];
      return result;
    };

    const formatSurname = (surname) => {
      if (surname.toUpperCase().startsWith("MAC"))
        surname = surname.slice(0, 1) + surname.slice(2);
      surname = surname.padEnd(5, "9");
      surname = surname.substr(0, 5).toUpperCase();

      return surname;
    };

    //https://ukdriving.org.uk/driving-licence-number/
    let additional = name.includes("additional") ? "-additional" : "";
    let surname = AppStore.getControlByName("surname" + additional);
    let dob = AppStore.getControlByName("date-of-birth" + additional);
    let isFemale = AppStore.getControlByName("gender" + additional) === "Female";
    if (
      isNullOrUndefined(surname) ||
      isNullOrUndefined(dob) ||
      surname === "" ||
      dob === ""
    ) {
      return "";
    }

    let result = formatSurname(surname) + formatDateofbirth(dob, isFemale);

    setLicenceNumberValues({
      1: result.substr(0, 5),
      2: result.substr(5, 6),
      3: result.substr(11, 2),
      4: result.substr(13, 3),
    });

    // as we are populating the initial two fields, set the has changed status for these to true
    AppStore.setHasChanged(`${name}-1`, true);
    AppStore.setHasChanged(`${name}-2`, true);
  };

  let isEmpty =
    isNullOrUndefined(currentValue) ||
    currentValue === "0000000000000000" ||
    currentValue === "" ||
    currentValue.length !== 16;

  const changeHandler = (event, section) => {
    const formatNewValue = (value) => {
      let newValue = value;
      if (!allowedCharsRegex.test(newValue)) {
        newValue = helpers.ReplaceByRegex(name, newValue);
      }
      newValue = newValue.toUpperCase();

      if (newValue.length > sectionMaxLengths[section])
        newValue = newValue.slice(0, sectionMaxLengths[section]);

      return newValue;
    };

    let newValue = formatNewValue(event.target.value);

    // set the new value for the given input
    setLicenceNumberValues(() => {
      return { ...licenceNumberValues, [section]: newValue };
    });

    handleChange({ target: { name: name, value: getCompleteNumber() } });

    // if the current input is complete, jump to the next one
    if (newValue.length === event.target.maxLength && section !== 4) {
      document.getElementById(`${name}-${section}`).blur();
      document.getElementById(`${name}-${section + 1}`).focus();
    }
  };

  const blurHandler = (event, section) => {
    AppStore.setHasChanged(event.target.name, true);
    if (
      AppStore.getHasChanged(`${name}-1`) &&
      AppStore.getHasChanged(`${name}-2`) &&
      AppStore.getHasChanged(`${name}-3`) &&
      AppStore.getHasChanged(`${name}-4`)
    ) {
      handleBlur({
        target: {
          name: name,
          value: getCompleteNumber(event.target.value, section),
        },
      });
    }
  };

  // on control mounting
  useEffect(() => {
    // if there is no currentValue, autocomplete the field value based on the user's details.
    if (isEmpty) {
      licenceNumberAutocomplete();
    }
    // otherwise, set the values for the individual fields based on the 16-character value
    else if (!isNullOrUndefined(currentValue) && currentValue.length === 16) {
      setLicenceNumberValues({
        1: currentValue.substr(0, 5),
        2: currentValue.substr(5, 6),
        3: currentValue.substr(11, 2),
        4: currentValue.substr(13, 3),
      });
      AppStore.setHasChanged(`${name}-3`, true);
      AppStore.setHasChanged(`${name}-4`, true);
    }
  }, []);

  // each time the licenceNumberValues change, set the form data for the control
  useEffect(() => {
    if (
      !isNullOrUndefined(licenceNumberValues) &&
      licenceNumberValues !== initialValues
    ) {
      AppStore.setFormData({ [name]: getCompleteNumber() });
    }
  }, [licenceNumberValues]);

  // Display 4 text inputs. The format
  const controlComponent = (
    <Fragment>
      <input
        className={`form-control`}
        type="text"
        id={`${name}-1`}
        name={`${name}-1`}
        data-testid={`test-${name}-first`}
        required={required}
        onBlur={(event) => {
          blurHandler(event, 1);
        }}
        onChange={(event) => {
          changeHandler(event, 1);
        }}
        value={licenceNumberValues[1]}
        maxLength={5}
        placeholder={"- - - - -"}
      />
      <input
        className={`form-control`}
        type="text"
        id={`${name}-2`}
        name={`${name}-2`}
        data-testid={`test-${name}-second`}
        required={required}
        onBlur={(event) => {
          blurHandler(event, 2);
        }}
        onChange={(event) => {
          changeHandler(event, 2);
        }}
        value={licenceNumberValues[2]}
        maxLength={6}
        placeholder={"- - - - - -"}
      />
      <input
        className={`form-control`}
        type="text"
        id={`${name}-3`}
        name={`${name}-3`}
        data-testid={`test-${name}-third`}
        required={required}
        onBlur={(event) => {
          blurHandler(event, 3);
        }}
        onChange={(event) => {
          changeHandler(event, 3);
        }}
        value={licenceNumberValues[3]}
        maxLength={2}
        placeholder={"- -"}
      />
      <input
        className={`form-control`}
        type="text"
        id={`${name}-4`}
        name={`${name}-4`}
        data-testid={`test-${name}-fourth`}
        required={required}
        onBlur={(event) => {
          blurHandler(event, 4);
        }}
        onChange={(event) => {
          changeHandler(event, 4);
        }}
        value={licenceNumberValues[4]}
        maxLength={3}
        placeholder={"- - -"}
      />
    </Fragment>
  );

  const componentMapping = {
    label: (
      <Label
        key={`labelkey-${name}`}
        id={id}
        label={label}
        forInput={name}
        validation={validation}
        defaultValue={defaultValue}
      />
    ), // forInput, validation and defaultValue are added for live validation purposes.
    tooltip: (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
      />
    ),
    info: (
      <>
        {extraInfo &&
          extraInfo.map(
            (ei) =>
              ei.text && (
                <TextArea
                  key={`extrainfotextkey-${name}`}
                  name={`extrainfotextkey-${name}`}
                  label={ei.text}
                  className={"extraInfo"}
                />
              )
          )}
        <Info
          key={`infokey-${name}`}
          infoMessages={infoMessages != null ? infoMessages.info : ""}
        />
      </>
    ),
    error: (
      <Error
        key={`errorkey-${name}`}
        validation={validation}
        forInput={name}
        name={name}
      />
    ),
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `col-lg-4 col-md-5 col-sm-12 col-12`,
  };

  return (
    <div
      className={`form-group form-group-container form-group-${name} custom-group ${customFormGroupClass}`}>
      <div id={`row-${name}`} className={`row ${errorClass}`} name={`${errorClass}`}>
        <ControlRowFactory
          controlName={name}
          groupClasses={groupClasses}
          componentMappings={componentMapping}
        />
      </div>
    </div>
  );
};

export default observer(LicenceNumber);
