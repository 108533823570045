import { isNullOrUndefined } from "./isNullOrUndefined";

export default {
  push: (data) => {
    if (!isNullOrUndefined(data)) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
    }
  },
  checkEvent: (event) => {
    let response = false;
    if (!isNullOrUndefined(event)) {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.forEach((el) => {
        response = { event: event in el ? true : false, el: el };
      });
    }

    return response;
  },
};
