import { isNullOrUndefined } from "@helpers/isNullOrUndefined";
import DOMPurify from "dompurify";
import React from "react";
import accesibilityHelper from "../../../helpers/accessibilityHelper";
import ReactHover from "../../../helpers/tooltip/reactHover";
import AppStore from "../../../store/AppStore";
import SVGIcon from "./SVGIcon.Component";

const optionsCursorTrueWithMargin = {
  followCursor: true,
  shiftX: 0,
  shiftY: 0,
};

class TooltipV1 extends React.Component {
  constructor(props) {
    super(props);

    const expandOnLoad = props.expandOnLoad || false; // determined in Controls.json
    this.state = {
      showOnClick: expandOnLoad,
    };
    this.accessKey = accesibilityHelper.getKeyboardAccessKey(this.props.controlName);
  }

  toggle() {
    this.setState({ showOnClick: !this.state.showOnClick });

    let allClickablelElements = [];

    const listener = () => {
      removeEventListener(allClickablelElements);
    };

    const addEventListener = (elements) => {
      elements.forEach((element) => {
        element.addEventListener("click", listener);
      });
    };

    const removeEventListener = (elements) => {
      elements.forEach((element) => element.removeEventListener("click", listener));
    };

    if (!this.state.showOnClick) {
      allClickablelElements = document.querySelectorAll(
        "input, .help-icon, select, button"
      );
      addEventListener(allClickablelElements);
    } else {
      removeEventListener(allClickablelElements);
    }
  }

  handleClick() {
    this.toggle();
  }

  handleKeyDown(ev) {
    if (
      ev.code === "Space" ||
      ev.code === "Enter" ||
      (!this.state.showOnClick && ev.code === "ArrowDown") ||
      (this.state.showOnClick && ev.code === "ArrowUp")
    ) {
      ev.preventDefault();
      this.toggle();
    }
  }

  render() {
    const svgTitle = this.props.title ? <title>{this.props.title}</title> : "";
    const helpTitle =
      this.props.helpMessages != null ? this.props.helpMessages.title : "";
    let helpMessage =
      this.props.helpMessages != null ? this.props.helpMessages.message : "";
    if (typeof this.props.helpMessages === "string")
      helpMessage = this.props.helpMessages;
    const helpid = this.props.controlName
      ? `help-${this.props.controlName.replace(/\s+/g, "").toLowerCase()}`
      : "help-icon";

    const overrideDisplay = !isNullOrUndefined(this.props.overrideDisplay)
      ? this.props.overrideDisplay
      : false;
    const iconVisibility =
      !this.props.helpMessages && !this.props.helpTitle
        ? "d-none"
        : `tooltip-container ${
            overrideDisplay ? "" : "d-inline-block"
          } col-lg-2 col-md-2 col-sm-0 col-0`;

    const inheritCss = !isNullOrUndefined(this.props.className)
      ? this.props.className
      : "";
    const showImage =
      !isNullOrUndefined(this.props.helpMessages) &&
      this.props.helpMessages.showImage === "true"
        ? "help-img"
        : "";
    const desktopTooltipToggle =
      !isNullOrUndefined(AppStore.getUserJourneySettings()?.tooltip) &&
      !isNullOrUndefined(
        AppStore.getUserJourneySettings()?.tooltip.desktopTooltipToggle
      ) &&
      this.props.controlName !== "results-filter-panel"
        ? AppStore.getUserJourneySettings()?.tooltip.desktopTooltipToggle
        : false;
    const buttonLabel = this.props.helpMessages?.buttonLabel
      ? this.props.helpMessages.buttonLabel
      : null;
    const showCustomIcon = this.props.helpMessages?.icon ? true : false;
    const customIcon = (
      <SVGIcon
        className={`${
          !this.state.showOnClick ? "tooltip-icon-open" : "tooltip-icon-close"
        }`}
        svgName={
          !this.state.showOnClick
            ? this.props.helpMessages?.icon
            : this.props.helpMessages?.closeIcon || this.props.helpMessages.icon
        }
        svgUrl={"/images/common/bike"}
      />
    );

    const testId = this.props.controlName ? `test-${this.props.controlName}-help` : "";

    const defaultContent = (
      <svg
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 40 40"
        enableBackground="new 0 0 40 40;"
        pointerEvents="none"
        className={this.props.cssClass}
        id={this.props.id}>
        {svgTitle}
        <circle className="tooltip-background" cx="20.001" cy="19.999" r="20" />
        <g>
          <path
            className="tooltip-foreground"
            d="M22.616,29.72c-0.169,0.395-0.405,0.737-0.706,1.026c-0.301,0.289-0.653,0.515-1.054,0.678
                      c-0.402,0.163-0.835,0.245-1.299,0.245c-0.477,0-0.916-0.082-1.318-0.245c-0.402-0.163-0.75-0.389-1.045-0.678
                      c-0.295-0.288-0.527-0.631-0.696-1.026c-0.169-0.395-0.254-0.819-0.254-1.27c0-0.452,0.085-0.872,0.254-1.261
                      c0.169-0.389,0.401-0.728,0.696-1.016c0.295-0.288,0.643-0.518,1.045-0.687c0.401-0.169,0.84-0.254,1.318-0.254
                      c0.464,0,0.897,0.085,1.299,0.254c0.402,0.169,0.753,0.398,1.054,0.687c0.301,0.289,0.536,0.628,0.706,1.016
                      c0.169,0.389,0.254,0.809,0.254,1.261C22.87,28.902,22.785,29.325,22.616,29.72z"
          />
          <path
            className="tooltip-foreground"
            d="M26.044,15.957c-0.22,0.576-0.5,1.078-0.84,1.507c-0.34,0.429-0.712,0.798-1.114,1.107
                      c-0.403,0.309-0.785,0.599-1.146,0.871c-0.361,0.272-0.675,0.539-0.942,0.8c-0.267,0.262-0.432,0.555-0.494,0.879l-0.439,2.197
                      h-3.327l-0.345-2.527c-0.011-0.063-0.018-0.126-0.024-0.188c-0.005-0.063-0.008-0.126-0.008-0.188c0-0.46,0.11-0.863,0.33-1.209
                      c0.22-0.345,0.497-0.662,0.832-0.95c0.335-0.288,0.696-0.565,1.083-0.832c0.387-0.267,0.748-0.555,1.083-0.863
                      c0.335-0.308,0.612-0.654,0.832-1.036c0.22-0.382,0.33-0.834,0.33-1.358c0-0.628-0.207-1.127-0.62-1.499
                      c-0.413-0.371-0.981-0.557-1.703-0.557c-0.555,0-1.018,0.058-1.389,0.173c-0.372,0.115-0.691,0.243-0.957,0.385
                      c-0.267,0.141-0.5,0.27-0.698,0.385c-0.199,0.115-0.398,0.173-0.596,0.173c-0.45,0-0.785-0.194-1.004-0.581l-1.287-1.978
                      c0.387-0.324,0.808-0.628,1.264-0.91c0.455-0.283,0.944-0.531,1.468-0.746c0.523-0.214,1.085-0.382,1.687-0.502
                      c0.602-0.12,1.253-0.18,1.954-0.18c0.983,0,1.87,0.131,2.66,0.392c0.79,0.262,1.462,0.636,2.017,1.122
                      c0.555,0.487,0.981,1.073,1.279,1.758c0.298,0.685,0.447,1.447,0.447,2.284C26.373,14.691,26.263,15.382,26.044,15.957z"
          />
        </g>
      </svg>
    );

    return (
      <div
        className={`${iconVisibility} ${inheritCss} ${
          this.state.showOnClick ? "show-tooltip" : "hide-tooltip"
        }`}
        onClick={(ev) => {
          this.handleClick();
        }}
        onKeyDown={(ev) => {
          this.handleKeyDown(ev);
        }}
        data-testid={testId}
        tabIndex={0}>
        <ReactHover options={optionsCursorTrueWithMargin} className="tooltip-wrapper">
          <ReactHover.Trigger
            passedAccessKey={desktopTooltipToggle ? this.accessKey : null}
            type="trigger"
            className="tooltip-wrapper"
            desktopToggle={
              desktopTooltipToggle ? desktopTooltipToggle : this.props.desktopToggle
            }>
            <div
              data-toggle="popover"
              className="help-tooltip"
              data-target={`#${helpid}`}>
              <div className={"tooltip-image"} />
              {buttonLabel ? (
                <label className="tooltip-label">{buttonLabel}</label>
              ) : showCustomIcon ? (
                customIcon
              ) : (
                defaultContent
              )}
            </div>
          </ReactHover.Trigger>

          {this.state.showOnClick && (
            <ReactHover.Hover
              passedAccessKey={desktopTooltipToggle === false ? this.accessKey : null}
              type="hover"
              className="help-wrapper">
              <div
                id={helpid}
                className={`help help-${this.props.controlName
                  .replace(/\s+/g, "")
                  .toLowerCase()} ${showImage}`}>
                <div className="title help-title">{helpTitle}</div>
                <div
                  className="help-message"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(helpMessage, {
                      USE_PROFILES: { html: true },
                      ADD_TAGS: ["a", "p", "div", "span"],
                      ADD_ATTR: ["href", "target", "name", "class"],
                    }),
                  }}
                />
              </div>
            </ReactHover.Hover>
          )}
        </ReactHover>
      </div>
    );
  }
}

export default TooltipV1;
