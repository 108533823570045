import { resolveStaticAssetPath } from "@helpers/staticAsset";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useBoundingBox } from "../../../hooks/useBoundingBox";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

export const getSideImageBehavior = (
  sideImageUjCfg = {},
  currentPageUrl = "",
  enabled = true
) => {
  const { showOnPages = [] } = sideImageUjCfg;

  const isVisibleByPage = showOnPages.some((page) => page === currentPageUrl);
  const canRender = !!(enabled && sideImageUjCfg && isVisibleByPage);
  const isRevealForInnerWrapper = canRender && sideImageUjCfg.attachRevealToInnerWrapper;
  const isRevealForOuterWrapper = canRender && !sideImageUjCfg.attachRevealToInnerWrapper;
  const isOverlapsWithInfoContainerOnMobile =
    canRender && sideImageUjCfg.overlapsWithInfoContaineroOnMobile;

  return {
    sideImageUjCfg,
    canRender,
    isRevealForInnerWrapper,
    isRevealForOuterWrapper,
    isOverlapsWithInfoContainerOnMobile,
  };
};

const SideImage = ({ sideImageUjCfg = {} }) => {
  const { revealBtnText = "Side image", imgSrc = "" } = sideImageUjCfg;

  const [isOpened, setIsOpened] = useState(false);
  const toggle = () => setIsOpened((o) => !o);

  const triggerRef = useRef();
  const triggerRect = useBoundingBox(triggerRef, { listenResize: true });

  const containerRef = useRef();
  useOutsideClick({
    enabled: isOpened,
    ref: containerRef,
    handler: toggle,
  });

  return (
    <div
      id="sideImageContainer"
      ref={containerRef}
      className={`side-image-container ${isOpened ? "opened" : ""}`}>
      <div
        className="side-image-inner-container"
        style={{
          "--side-image-trigger-width": triggerRect.width + "px",
          "--side-image-trigger-height": triggerRect.height + "px",
        }}>
        <button
          type="button"
          tabIndex={-1}
          ref={triggerRef}
          onClick={toggle}
          className="side-image-trigger">
          {revealBtnText}
        </button>
        <img
          src={resolveStaticAssetPath(imgSrc)}
          alt={revealBtnText}
          className="side-image-img"
        />
      </div>
    </div>
  );
};

SideImage.propTypes = {
  sideImageUjCfg: PropTypes.object,
};

export default SideImage;
