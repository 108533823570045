import React from "react";
import { BulletNavBar, NavBar } from "./page-components";

const Navigation = ({ pages, currentPage, onPageSelect, type }) => {
  const navBarProps = {
    pages,
    currentPage,
    onPageSelect,
  };

  if (type === "bullet") {
    return <BulletNavBar {...navBarProps} />;
  }

  return <NavBar {...navBarProps} />;
};

export default Navigation;
