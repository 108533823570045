import dayjs from "dayjs";
import { sha256 } from "js-sha256";
import { cookies } from "../helpers/cookies";
import dateParser from "../helpers/dateParser";
import { isNullOrUndefined } from "@helpers/isNullOrUndefined";
import ClientLogging from "../providers/loggingProvider";
import AppStore from "../store/AppStore";

const Permutive = {
  init(settings) {
    try {
      if (settings.enabled === "true") {
        const head = document.getElementsByTagName("head")[0];
        const toInsert = document
          .createRange()
          .createContextualFragment(
            this.getPermutiveTag(settings.apiKey, settings.workspaceID)
          );
        head.appendChild(toInsert);
      }
    } catch (ex) {
      ClientLogging.logError(ex, "Permutive init");
    }
  },
  handleNewPage() {
    try {
      if (window.permutive) {
        let cookieConsent = { "opt-in": false };
        if (cookies.get("CookieConsent") === "true") {
          cookieConsent = { opt_in: true, token: "true" };
        }
          window.permutive.consent(cookieConsent);

        this.updateData();

        this.sendUserID();
        }
    } catch (ex) {
      ClientLogging.logError(ex, "Permutive newpage");
    }
  },
  getPermutiveTag(apiKey, workspaceID) {
    const tag = `<script>
            !function(n,e,i){if(!n){n = n || {}, window.permutive = n, n.q = [], n.config = i || {}, n.config.apiKey = e, n.config.environment = n.config.environment || "production";for(var o=["addon","identify","track","trigger","query","segment","segments","ready","on","once","user","consent"],r=0;r<o.length; r++){var t=o[r];n[t]=function(e){return function(){var i=Array.prototype.slice.call(arguments,0);n.q.push({functionName:e,arguments:i})}}(t)}}}(window.permutive, "${apiKey}", { "consentRequired": true });
            window.googletag=window.googletag||{ },window.googletag.cmd=window.googletag.cmd||[],window.googletag.cmd.push(function(){if(0===window.googletag.pubads().getTargeting("permutive").length){var g=window.localStorage.getItem("_pdfps");window.googletag.pubads().setTargeting("permutive",g?JSON.parse(g):[])}});
        </script>
        <script async src="https://cdn.permutive.app/${workspaceID}-web.js"></script>`;

    return tag;
  },
  getCurrentPageInfo(currentPage, field = "Url") {
    const pages = AppStore.getUserJourneyStructure().Pages;

    let foundPage = pages.find(
        (page) => page.Title.toLowerCase().replace(/ /g, "") === currentPage.toLowerCase().replace(/ /g, "")
    );

    return  foundPage[field];
  },
  updateData() {
    try {
      let data = {
        page: {},
      };

      const schema = AppStore.getUserJourneySettings().tracking.permutive.schema;
      const templatePrefix = AppStore.getUserJourneySettings().tracking.permutive.templatePrefix;
      Object.keys(schema).forEach((field) => {  
        data.page[field] = this.formatFormData(schema[field]);
      }); 

      if (!isNullOrUndefined(templatePrefix)) {
        let currentPageName = AppStore.getCurrentPageName();
        let currentPageIndex = this.getCurrentPageInfo(currentPageName,"OrderNo");
        let currentPageUrl = this.getCurrentPageInfo(currentPageName,"Url");
          
        let pageTitleFormated = currentPageName.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ').replace(/ /g, "");

        let currentPageIsResults = (currentPageUrl === "yourquotes");
        let templatePageTitle = currentPageIsResults ? 'Results' : 'Page' + currentPageIndex + '.' + pageTitleFormated;

        data.page["template"] = `${templatePrefix}.${templatePageTitle}`;

      }
      
        window.permutive.addon("web", data);
    } catch (ex) {
      ClientLogging.logError(ex, "Permutive updateData");
    }
  },

  formatFormData(schema) {
      let data = {};
      
      Object.keys(schema).forEach((permutiveKey) => {
          const controlName = schema[permutiveKey];
          let value = AppStore.getControlByName(controlName);

          if (!isNullOrUndefined(value) && value !== "") {
              switch (permutiveKey) {
                  case "dob":
                      value = dayjs(value).format("YYYY-MM-DDThh:mm:ss[Z]");
                      break;
                  case "age":
                      value = dateParser.getDateDiffInYears(value, dayjs());
                      break;
                  case "make":
                      value = value.toLowerCase();
                      break;
                  case "model":
                      let model = (value.split(",")[0]).split(" ")[0];
                      let make = AppStore.getControlByName(schema["make"]);
                      value = (`${make}_${model}`).toLowerCase();
                      break;
                  case "value":
                  case "seats":
                  case "ncd":
                  case "other_vehicles_ncd":
                  case "year_of_manufacture":
                  case "voluntary_excess":
                  case "mileage_business":
                  case "mileage_personal":
                      value = parseInt(value);
                      break;
                 case "engine":
                      value = parseFloat(value);
                      break;
                  case "insurance_renewal_date":
                  case "licence_date_obtained":
                  case "purchase_date":
                      value = dayjs(value).format("YYYY-MM-DDT00:00:00[Z]");
                      break;
                  case "licence_not_yet_obtained":
                  case "not_purchased_yet":
                      value = Boolean(value);
                      break;
                  case "home_owner":
                  case "modified":
                  case "uk_model":
                  case "right_hand_drive":
                  case "ncd_protection":
                  case "optional_extras":
                  case "has_children_under_16":
                  case "at_home_overnight":
                  case "car_owner":
                      value = value === "yes";
                      break;
                  case "occupation_type":
                  case "occupation_industry":
                  case "student":
                  case "licence_type":
                  case "insurance_cover_type":
                  case "usage_type":
                  case "advanced_driving_qual":
                  case "payment_preference":
                      value = AppStore.getDescriptionByCode(value, controlName);
                      break;
                  case "fuel":
                  case "transmission":
                  case "storage":
                      value = AppStore.getDescriptionByCode(value, controlName).toLowerCase();
                      break;
                  case "reg":
                      value = value.toUpperCase();
                      break;
                  case "other_vehicles":
                      if (value === "NA") value = false;
                      else value = true;
                      break;
                  case "breakdown_cover":
                      value = value.breakdown === true;
                      break;
                  case "legal_protection":
                      value = value.legalprotection === true;
                      break;
                  case "key_cover":
                      value = value.keycare === true;
                      break;
                  case "postcode_district":
                      value = value.split(" ")[0];
                      break;
              }
          }

          if (permutiveKey === "security_devices") {
              //car
              if (Array.isArray(schema["security_devices"])) {
                  let securityDevices = [];
                  schema["security_devices"].forEach((control) => {
                      const val = AppStore.getControlByName(control);
                      const desc = AppStore.getDescriptionByCode(val, control);
                      if (!isNullOrUndefined(desc) && desc !== "") securityDevices.push(desc);
                  });
                  if (securityDevices.length)
                      value = securityDevices;
              }
              else {
                  //bike
                  value = AppStore.getDescriptionByCode(value, controlName);
              }
          }

          if (permutiveKey === "tracker") {
              let trackerName = AppStore.getControlByName("tracker-dropdown");
              if (!isNullOrUndefined(trackerName) && trackerName !== "NONE")
                  value = AppStore.getDescriptionByCode(trackerName, "tracker-dropdown");
              else
                  value = undefined;
          }
           
          if (permutiveKey === "occupations") {
            let occupationsList = [];
            schema["occupations"].forEach((control) => {
                const val = AppStore.getControlByName(control);
                const desc = AppStore.getDescriptionByCode(val, control);
                if (!isNullOrUndefined(desc) && desc !== "") occupationsList.push(desc);
            });
            if(occupationsList.length)
                value = occupationsList;
          }

          if (permutiveKey === "additional_driver") {
              let addDrivers = AppStore.getJSControlValue("additional-drivers");

              if (!isNullOrUndefined(addDrivers) && addDrivers.length > 0) value = true;
              else value = false;
          }

         if (!isNullOrUndefined(value) && value !== "")
              data[permutiveKey] = value;
      });
      
    return data;
  },

  sendUserID() {
    try {
      const sendUserIdEnabled =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.enabled ===
          "true";

      const saltKey =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
            .saltKey
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
          .saltKey !== ""
          ? AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
              .saltKey
          : false;
      const saltTag =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions.tag
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
          .tag !== ""
          ? AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
              .tag
          : "bauer_id";
      const saltPriority =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions.tag
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
          .priority !== ""
          ? AppStore.getUserJourneySettings().tracking.permutive.sendUserID.saltOptions
              .priority
          : "0";

      const emailTag =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions.tag
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions
          .tag !== ""
          ? AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions
              .tag
          : "email_sha256";
      const emailPriority =
        !isNullOrUndefined(
          AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions.tag
        ) &&
        AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions
          .priority !== ""
          ? AppStore.getUserJourneySettings().tracking.permutive.sendUserID.emailOptions
              .priority
          : "1";

      if (sendUserIdEnabled && saltKey) {
        let user_email =
          !isNullOrUndefined(AppStore.getControlByName("email")) &&
          AppStore.getControlByName("email") != ""
            ? AppStore.getControlByName("email").trim()
            : false;

        if (user_email) {
          let hashed_email = sha256(user_email);
          let salted_hashed_email = sha256(user_email + saltKey);

          window.permutive.identify([
            {
              id: salted_hashed_email,
              tag: saltTag,
              priority: saltPriority,
            },
            {
              id: hashed_email,
              tag: emailTag,
              priority: emailPriority,
            },
          ]);
        }
      }
    } catch (ex) {
      ClientLogging.logError(ex, "Permutive userID");
    }
  },
};

export default Permutive;
