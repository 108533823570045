import PropTypes from "prop-types";
import React, { Component } from "react";
import { isNullOrUndefined } from "../isNullOrUndefined";

export default class Hover extends Component {
  static propTypes = {
    type: PropTypes.string,
    children: PropTypes.object,
    styles: PropTypes.object,
    setVisibility: PropTypes.func,
    getCursorPos: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.visibility = false;
  }

  render() {
    const { styles, className } = this.props.children.props;
    return (
      <div
        accessKey={this.props.passedAccessKey}
        onClick={this.onClick.bind(this)}
        style={styles}
        className="message-wrapper">
        {this.props.children.props.children}
      </div>
    );
  }

  onMouseOver() {
    const { setVisibility } = this.props.children.props;
    this.visibility = true;
    setVisibility(this.visibility);
  }

  onClick() {
    if (!isNullOrUndefined(this.props.passedAccessKey)) {
      const { setVisibility } = this.props.children.props;
      this.visibility = !this.visibility;
      setVisibility(this.visibility);
    }
  }

  onMouseOut() {
    const { setVisibility } = this.props.children.props;
    this.visibility = false;
    setVisibility(this.visibility);
  }

  onMouseMove(e) {
    const { getCursorPos } = this.props.children.props;
    getCursorPos(e);
  }
}
