import ControlFactory from "../../factories/ControlsFactory";

const ButtonFooterBanner = ({ attributes }) => {
  /*
        ButtonFooterBanner is used to simplify the display of the 'Previous' and 'Continue' submit buttons, found at the bottom of each page.
        In controls.json, replace the continue and previous buttons with the ButtonFooterBanner, and set the following attributes appropriately:
         - nextLabel: the label for the 'continue' button. Also is used to check if a 'continue' button should exist
         - previousLabel: the label for the 'previous' button. Also is used to check if a 'previous' button should exist
         - page: the name of the current page. This is used to set the className.
         - nextPage: the name of the next page. This isn't required as redirect behaviour is handled on submitting the form.
         - prevPage: the name of the previous page. This IS required as redirect behaviour is handled with the onClick() method in the controlFactory.jsx file.
        
        The redirection rules are applied as usual, handled by the FormComponent component, and determined by the given page name.
    */
  const hasForwardButton =
    attributes.nextLabel &&
    attributes.nextLabel !== "" &&
    attributes.nextLabel !== undefined &&
    attributes.nextLabel !== null;
  const hasBackwardButton =
    attributes.previousLabel &&
    attributes.previousLabel !== "" &&
    attributes.previousLabel !== undefined &&
    attributes.previousLabel !== null;

  const nextLabel = hasForwardButton ? attributes.nextLabel : "";
  const previousLabel = hasBackwardButton ? attributes.previousLabel : "";

  const nextPage = attributes.nextPage ? attributes.nextPage : "";
  const prevPage = attributes.prevPage ? attributes.prevPage : "";

  const nextAdditionalRedirectValue = attributes.nextAdditionalRedirectValue
    ? attributes.nextAdditionalRedirectValue
    : "";
  const previousAdditionalRedirectValue =
    attributes.previousAdditionalRedirectValue
      ? attributes.previousAdditionalRedirectValue
      : "";

  // For this component to work, we need the use of controlsFactory's handleClick() function.
  // Therefore here we must redefine the buttonFooterBanner model, pushing it into the controlsFactory,
  // and using the version of ButtonFooterBanner in the controls folder.
  // (This isn't ideal - I couldn't think of another way in short notice - DW)

  const model = {
    Type: "ButtonBanner",
    Name: `button-banner-${attributes.page}`,
    LogicalName: null,
    ParentName: null,
    Label: "",
    Values: "",
    DefaultValue: null,
    ValidationResources: null,
    Attributes: {
      formtarget: "Form",
      nextLabel: nextLabel,
      submitShowIcon: attributes.submitShowIcon,
      submitIcon: attributes.submitIcon,
      backShowIcon: attributes.backShowIcon,
      backIcon: attributes.backIcon,
      previousLabel: previousLabel,
      page: attributes.page,
      nextPage: nextPage,
      prevPage: prevPage,
      nextAdditionalRedirectValue: nextAdditionalRedirectValue,
      previousAdditionalRedirectValue: previousAdditionalRedirectValue,
    },
    InfoMessages: null,
    HelpMessages: null,
    CurrentValue: null,
    IsSaveable: false,
    IsSavedInQuoteSummary: false,
  };

  return <ControlFactory model={model} />;
};

export default ButtonFooterBanner;
