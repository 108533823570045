import { isNullOrUndefined } from "@helpers/isNullOrUndefined";
import DOMPurify from "dompurify";
import { observer } from "mobx-react";
import { useState } from "react";
import AppStore from "../../../store/AppStore";
import TooltipStore from "../../../store/TooltipStore";
import SVGIcon from "./SVGIcon.Component";

const TooltipCustom = ({
  controlName,
  expandOnLoad,
  title,
  helpMessages,
  overrideDisplay,
  className,
  id,
  width,
  height,
  cssClass,
  desktopToggle,
}) => {
  //When exclusiveToggleEnabled = true, when a tooltip is clicked, the rest will be hidden
  const exclusiveToggleEnabled =
    AppStore.getUserJourneySettings().tooltip?.exclusiveToggleEnabled === true;

  const [isMessageVisible, setIsMessageVisible] = useState(false);
  const tooltipName = `tooltip-${controlName}`;
  const showImage =
    !isNullOrUndefined(helpMessages) && helpMessages.showImage === "true"
      ? "help-img"
      : "";

  const helpTitle =
    !isNullOrUndefined(helpMessages) && helpMessages !== "" ? helpMessages.title : "";

  const helpMessage =
    !isNullOrUndefined(helpMessages) && helpMessages !== "" ? helpMessages.message : "";

  const shouldShowTooltip = helpMessage !== "";

  const messageContent = {
    __html: DOMPurify.sanitize(helpMessage, {
      USE_PROFILES: { html: true },
      ADD_TAGS: ["a", "p", "div", "span", "ul", "li"],
      ADD_ATTR: ["href", "target", "name", "class"],
    }),
  };

  const helpid = controlName
    ? `help-${controlName.replace(/\s+/g, "").toLowerCase()}`
    : "help-icon";

  const handleClick = () => {
    if (exclusiveToggleEnabled) {
      if (TooltipStore.visibleTooltip === tooltipName) TooltipStore.hideTooltips();
      else TooltipStore.showTooltip(tooltipName);
    } else {
      setIsMessageVisible(!isMessageVisible);
    }
  };

  const shouldShowMessage = exclusiveToggleEnabled
    ? TooltipStore.visibleTooltip === tooltipName
    : isMessageVisible;

  return (
    shouldShowTooltip && (
      <>
        <div
          className="tooltip-button"
          data-testid={`test-tooltip-button-${controlName}`}
          onClick={handleClick}>
          <SVGIcon
            className={`${
              !shouldShowMessage ? "tooltip-icon-open" : "tooltip-icon-close"
            }`}
            svgName={
              !shouldShowMessage
                ? helpMessages?.icon
                : helpMessages?.closeIcon || helpMessages.icon
            }
            svgUrl={"/images/common/bike"}
          />
        </div>
        {shouldShowMessage && (
          <div
            className="tooltip-wrapper"
            data-testid={`test-tooltip-wrapper-${controlName}`}
            onClick={handleClick}>
            <div className="title help-title">{helpTitle}</div>
            <div className="help-message" dangerouslySetInnerHTML={messageContent} />
          </div>
        )}
      </>
    )
  );
};

export default observer(TooltipCustom);
