import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import ControlRowFactory from "../../../factories/ControlRowFactory";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import AppStore from "../../../store/AppStore";
import Error from "../../controls/StaticControls/Error.Component";
import Info from "../../controls/StaticControls/Info.Component";
import Label from "../../controls/StaticControls/Label.Component";
import Tooltip from "../../controls/StaticControls/Tooltip.Component";
import TextArea from "../StaticControls/TextArea.Component";

const DateControl = ({
  id,
  name,
  label,
  currentValue,
  handleDateChange,
  handleBlur,
  handleInteraction,
  helpMessages,
  infoMessages,
  extraInfo,
  attributes,
  validation,
  defaultValue,
}) => {
  const emptyInitialValue = attributes.emptyInitialValue
    ? attributes.emptyInitialValue
    : false;
  const liveValidation = AppStore.liveValidation;
  const errorClass = validation && validation.isValid === false ? "error" : "";

  let dateChanged = true;
  const date = currentValue === "" ? "YYYY-MM-DD" : currentValue;
  const currentDate = dayjs();
  const daysRange =
    attributes !== null && !isNullOrUndefined(attributes.daysRange)
      ? attributes.daysRange
      : null;

  let getDateArray = function (start, range) {
    let arr = [];
    let currentValue = dayjs(start);
    const endDate = dayjs().add(range, "day");
    while (currentValue <= endDate) {
      let displayFormat = dayjs(currentValue).format("dddd, DD MMMM YYYY");
      let saveFormat = dayjs(currentValue).format("YYYY-MM-DD");
      arr.push([`${saveFormat}`, `${displayFormat}`]);
      currentValue = dayjs(currentValue).add(1, "day");
    }
    return arr;
  };

  let futureDatesArr = getDateArray(currentDate, daysRange);

  const defaultValues = !emptyInitialValue
    ? [["PleaseSelect", "Please select"]]
    : [["PleaseSelect", ""]];
  const datesValues = defaultValues.concat(futureDatesArr);
  const cleanDisplay = attributes.cleanDisplay;

  const controlComponent = (
    <div
      key={`controlkey-${name}`}
      className={`${
        cleanDisplay
          ? ""
          : "field-date-part field-date-part-day d-inline-block col-lg-7 col-md-7 col-sm-12"
      }`}>
      <select
        className="form-control d-inline-block col-lg-12 col-md-12 col-sm-12 col-12"
        id={`${name}`}
        name={`${name}`}
        value={date}
        data-fieldname={`${name}`}
        onBlur={(ev) => {
          liveValidation && handleBlur(ev);
        }}
        onChange={(event) => {
          dateChanged = event.target.value !== currentValue;
          handleDateChange(name, `${event.target.value}`, dateChanged);
          handleInteraction(event);
        }}>
        {datesValues.map((item) => (
          <option value={item[0]} key={name + item[0]}>
            {item[1]}
          </option>
        ))}
      </select>
    </div>
  );

  const componentMapping = {
    label: (
      <Label
        key={`labelkey-${name}`}
        id={id}
        label={label}
        forInput={name}
        validation={validation ? validation : ""}
        defaultValue={defaultValue}
      />
    ),
    tooltip: (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
      />
    ),
    info: (
      <>
        {extraInfo &&
          extraInfo.map(
            (ei) =>
              ei.text && (
                <TextArea
                  key={`extrainfotextkey-${name}`}
                  name={`extrainfotextkey-${name}`}
                  label={ei.text}
                  className={"extraInfo"}
                />
              )
          )}
        <Info
          key={`infokey-${name}`}
          infoMessages={infoMessages != null ? infoMessages.info : ""}
        />
      </>
    ),
    error: (
      <Error
        key={`errorkey-${name}`}
        validation={validation ? validation : ""}
        className="next-line"
      />
    ),
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `col-lg-8 col-md-8 col-sm-12 col-12`,
  };

  return (
    <div className={`form-group form-group-container form-group-${name}`}>
      <div id={`row-${name}`} className={`row row-${errorClass} `}>
        <ControlRowFactory
          controlName={name}
          groupClasses={groupClasses}
          componentMappings={componentMapping}
        />
      </div>
    </div>
  );
};

DateControl.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DateControl;
