import DOMPurify from "dompurify";
import React, { useReducer } from "react";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import NeedHelpPhone from "../controls/StaticControls/Phone.Component";

const SidebarV1 = ({ attributes }) => {
  const [isActive, toggleActive] = useReducer((state) => !state, false);

  const phone =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.phone)
      ? attributes.phone
      : "";
  const title =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.title)
      ? attributes.title
      : "";
  const text =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.text)
      ? attributes.text
      : "";
  const help =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.help)
      ? attributes.help
      : "";
  const button_label =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.button_label)
      ? attributes.button_label
      : "";

  return (
    <React.Fragment>
      <div className={`sidebar-box text-center ${isActive ? "help-active" : ""}`}>
        <div className="alternate-container">
          <button
            type="button"
            className="btn help-area-questions-alternate"
            onClick={toggleActive}>
            <i className={`fas fa-comment`}></i>
            {button_label}
          </button>
        </div>
        <div className="need-help-content-wrapper mx-3">
          <p className="title"> {title}</p>
          <p
            className="help-text"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(text, {
                USE_PROFILES: { html: true },
                ADD_TAGS: ["a", "p", "div", "span"],
                ADD_ATTR: ["href", "target", "name", "class"],
              }),
            }}></p>
          <div className="phone-container mt-2 mb-2">
            <NeedHelpPhone id="sidePhone" name="sidebar-phone" value={phone} />
          </div>
          <p
            className="help-text my-0"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(help, {
                USE_PROFILES: { html: true },
                ADD_TAGS: ["a", "p", "div", "span"],
                ADD_ATTR: ["href", "target", "name", "class"],
              }),
            }}></p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SidebarV1;
