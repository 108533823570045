import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import React from "react";

const TextArea = ({ id, name, label, className }) => {
  return (
    <div className={className}>
      <label
        className={`label label-${name}`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(label, {
            USE_PROFILES: { html: true },
            ADD_TAGS: ["a", "p", "div", "span"],
            ADD_ATTR: ["href", "target", "name", "class"],
          }),
        }}></label>
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TextArea;
