import dayjs from "dayjs";
import { controlNames } from "../../constants";
import AppStore from "../../store/AppStore";
import { default as DateParser } from "../dateParser";
import { isNullOrUndefined } from "../isNullOrUndefined";

function updateCarLicenceControlsVisibility(
  showType = false,
  showDate = false,
  showNCB = false
) {
  AppStore.setVisibilityControls({
    [controlNames.bikeLicenceTypeCar]: showType,
  });
  AppStore.setVisibilityControls({
    [controlNames.bikeDateLicenceObtainedCar]: showDate,
  });
  AppStore.setVisibilityControls({
    [controlNames.bikeCarNCB]: showNCB,
  });
}

function resetCarLicenceControlValues() {
  AppStore.deleteFormData(controlNames.bikeLicenceTypeCar);
  AppStore.deleteFormData(controlNames.bikeDateLicenceObtainedCar);
  AppStore.deleteFormData(controlNames.bikeCarNCB);
}

function setNoCarLicenceValues() {
  AppStore.deleteFormData(controlNames.bikeDateLicenceObtainedCar);
  AppStore.setFormData({
    [controlNames.bikeLicenceTypeCar]: "N",
  });
  AppStore.setFormData({
    [controlNames.bikeCarNCB]: "0",
  });
}

function manipulateControlsPostRender(controlName, controlValue) {
  const NQSCarLicence =
    AppStore.getUserJourneySettings()?.customControls?.NQSCarLicence || false;

  if (controlName === controlNames.hasCarLicenceBike) {
    if (controlValue !== "yes") {
      updateCarLicenceControlsVisibility(false, false, false);
      setNoCarLicenceValues();
    }

    // DoB has been changed to >16, and so licence values need to be reset
    // this method is used to override loadform override behaviour, and isn't ideal
    if (AppStore.getPolicyCarToBeRevealed()) {
      resetCarLicenceControlValues();
      updateCarLicenceControlsVisibility(false, false, false);
      AppStore.deleteFormData(controlName);
      AppStore.setPolicyCarToBeRevealed(false);
    }
  }

  // DoB has been changed to >16, and so licence values need to be reset
  // this method is used to override loadform override behaviour, and isn't ideal
  if (controlName === controlNames.bikeLicenceTypeCar && !NQSCarLicence) {
    if (AppStore.getPolicyCarToBeRevealed()) {
      resetCarLicenceControlValues();
      updateCarLicenceControlsVisibility(true, true, true);
      AppStore.setPolicyCarToBeRevealed(false);
    }
  }
}

function manipulateControlsOnChange(controlName, controlValue) {
  if (controlName === controlNames.bikeLicenceTypeCar) {
    if (controlValue === "N") {
      updateCarLicenceControlsVisibility(true, false, false);
      setNoCarLicenceValues();
    } else {
      updateCarLicenceControlsVisibility(true, true, true);
    }
  } else if (controlName === controlNames.hasCarLicenceBike) {
    if (controlValue === "no" || controlValue === "") {
      updateCarLicenceControlsVisibility(false, false, false);
      setNoCarLicenceValues();
    } else {
      updateCarLicenceControlsVisibility(true, true, true);
      resetCarLicenceControlValues();
    }
  }
}

function manipulateControlsPreRender(controlName) {
  const NQSCarLicence =
    AppStore.getUserJourneySettings()?.customControls?.NQSCarLicence || false;

  if (controlName === controlNames.hasCarLicenceBike) {
    const dob = AppStore.getControlByName(controlNames.dateOfBirth);
    const age = DateParser.getDateDiffInYears(dob, dayjs());
    const controlValue = AppStore.getControlByName(controlName);
    const typeCar = AppStore.getControlByName(controlNames.bikeLicenceTypeCar);

    // user is 16 or younger - hide car questions (bike product)
    if (age <= 16) {
      AppStore.setVisibilityControls({
        [controlName]: false,
      });
      updateCarLicenceControlsVisibility(false, false, false);

      AppStore.deleteFormData(controlName);
      setNoCarLicenceValues();
    }
    // user is 17 or older - show car questions (bike product)
    else {
      AppStore.setVisibilityControls({
        [controlName]: true,
      });

      if (AppStore.getControlByName(controlName) === "yes") {
        updateCarLicenceControlsVisibility(true, true, true);
      } else {
        // show or hide car controls based on car-licence-bike value
        if ((isNullOrUndefined(controlValue) || controlValue === "") && typeCar === "N") {
          AppStore.setFormData({ [controlName]: "no" });
          updateCarLicenceControlsVisibility(false, false, false);
          setNoCarLicenceValues();
        } else {
          const hasValues = [
            controlNames.bikeLicenceTypeCar,
            controlNames.bikeDateLicenceObtainedCar,
            controlNames.bikeCarNCB,
          ].some((name) => {
            const val = AppStore.getControlByName(name);
            return !isNullOrUndefined(val) && val !== "";
          });

          if (hasValues && controlValue !== "no") {
            AppStore.setFormData({ [controlName]: "yes" });
            updateCarLicenceControlsVisibility(true, true, true);
          } else {
            resetCarLicenceControlValues();
            updateCarLicenceControlsVisibility(false, false, false);
          }
        }
      }
    }
  } else if (controlName === controlNames.bikeLicenceTypeCar && !NQSCarLicence) {
    const dateOfBirth = AppStore.getControlByName(controlNames.dateOfBirth);
    const currentDate = dayjs().format("YYYY-MM-DD");
    const age = DateParser.getDateDiffInYears(dateOfBirth, currentDate);
    const hasCarLicenceValue = AppStore.getControlByName(controlNames.hasCarLicenceBike);
    const controlValue = AppStore.getControlByName(controlName);

    // recall from NQS
    if (hasCarLicenceValue === "no") {
      AppStore.setFormData({ [controlName]: "N" });
    } else if (age <= 16) {
      updateCarLicenceControlsVisibility(false, false, false);
      setNoCarLicenceValues();
    } else if (controlValue === "N") {
      setNoCarLicenceValues();
      updateCarLicenceControlsVisibility(true, false, false);
    } else {
      updateCarLicenceControlsVisibility(true, true, true);
    }
  }
}

export default {
  manipulateControlsPreRender,
  manipulateControlsPostRender,
  manipulateControlsOnChange,
  resetCarLicenceControlValues,
  updateCarLicenceControlsVisibility,
  setNoCarLicenceValues,
};
