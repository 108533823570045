import PropTypes from "prop-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import logoClickStream from "../../helpers/logoClickStream";
import { preserveQueryStringParams } from "../AppLink";
import PromptPopup from "../controls/PromptPopup.Component";

const LogoPromptPopup = ({ renderTrigger, logoCfg, isHrefExternal }) => {
  const { redirectPopup, redirectToHref, redirectToNewTab } = logoCfg;

  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const open = async () => {
    setIsOpen(true);
    await logoClickStream.redirectPopupOpen();
  };
  const close = async (params = {}) => {
    const { sendAnalytics = true } = params;

    setIsOpen(false);

    if (sendAnalytics) {
      await logoClickStream.redirectPopupClose();
    }
  };

  const runActionFromConfig = async (actionType) => {
    switch (actionType) {
      case "close": {
        await close();
        break;
      }
      case "redirect": {
        await logoClickStream.userRedirected();

        if (redirectToNewTab) {
          const newTab = window.open(preserveQueryStringParams(redirectToHref), "_blank");
          newTab?.focus();
        } else if (isHrefExternal) {
          // do not use react router to avoid react-router base url prepend
          window.location = redirectToHref;
          await close({ sendAnalytics: false });
        } else {
          history.push(preserveQueryStringParams(redirectToHref));
          await close({ sendAnalytics: false });
        }

        break;
      }
      default:
        await close();
    }
  };

  const handleActionClick = () => {
    runActionFromConfig(redirectPopup.actionBtnAction);
  };

  const handleCancelClick = () => {
    runActionFromConfig(redirectPopup.cancelBtnAction);
  };

  return (
    <>
      {renderTrigger({ open, close })}
      <PromptPopup
        dataTestId="logo-redirect-popup"
        isOpen={isOpen}
        handleClose={close}
        text={redirectPopup.text}
        title={redirectPopup.title}
        hasCloseBtn
        closeOnOutsideClick
        closeOnEsc
        actionBtn={{
          id: "redirect-action-btn",
          label: redirectPopup.actionBtnText,
          onClick: handleActionClick,
        }}
        cancelBtn={{
          id: "redirect-cancel-btn",
          label: redirectPopup.cancelBtnText,
          onClick: handleCancelClick,
        }}
      />
    </>
  );
};

LogoPromptPopup.propTypes = {
  renderTrigger: PropTypes.func.isRequired,
  logoCfg: PropTypes.object.isRequired,
  isHrefExternal: PropTypes.bool,
};

export default LogoPromptPopup;
