import { useEffect } from "react";

const useSaberFeedbackWidget = () => {
  useEffect(() => {
    const apiKey = import.meta.env.VITE_SABER_WIDGET_API_KEY;

    if (!apiKey) return;

    window.Saber = {
      apiKey: apiKey,
      com: [],
      do: function () {
        this.com.push(arguments);
      },
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://widget.saberfeedback.com/v2/widget.js";
    script.integrity =
      "sha384-xjEsBujTgrEY9fz8qgQVWOXjCRl5E3YqloMADL5VxWLG6R8v6WFdsfyA0kzbLAAD";
    script.crossOrigin = "anonymous";
    script.async = true;

    const head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
  }, []);
};

export default useSaberFeedbackWidget;
