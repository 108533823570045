import { isNullOrUndefined } from "@helpers/isNullOrUndefined";
import { controlNames } from "../../constants";
import AppStore from "../../store/AppStore";

const radioToDataListMapping = [
  [controlNames.motoringConvictionsRadio, controlNames.motoringConvictions],
  [controlNames.accidentClaimsRadio, controlNames.accidentClaims],
  [
    controlNames.motoringConvictionsRadioAdditional,
    controlNames.motoringConvictionsAdditional,
  ],
  [controlNames.accidentClaimsRadioAdditional, controlNames.accidentClaimsAdditional],
];

const submitSendersMapping = {
  "delete-accident-claims": [
    controlNames.accidentClaimsRadio,
    controlNames.accidentClaims,
  ],
  "delete-accident-claims-additional": [
    controlNames.accidentClaimsRadioAdditional,
    controlNames.accidentClaimsAdditional,
  ],
  "delete-motoring-convictions": [
    controlNames.motoringConvictionsRadio,
    controlNames.motoringConvictions,
  ],
  "delete-motoring-convictions-additional": [
    controlNames.motoringConvictionsRadioAdditional,
    controlNames.motoringConvictionsAdditional,
  ],
};

function handleLicenceDefence(controlName) {
  if (controlName === "motoring-convictions-additional") {
    AppStore.setVisibilityControls({ ["ld-convictions-opt-in"]: false });
    AppStore.setVisibilityControls({ ["message-box-cross-sell-convictions"]: false });
  }
  if (controlName === "motoring-convictions") {
    const licenceDefenceOccupationOptInValue =
      AppStore.getLicenceDefenceOccupationOptInValue();
    if (
      licenceDefenceOccupationOptInValue === "yes" ||
      licenceDefenceOccupationOptInValue === "no"
    ) {
      AppStore.setVisibilityControls({ ["ld-convictions-opt-in"]: false });
      AppStore.setVisibilityControls({
        ["message-box-cross-sell-convictions"]: false,
      });
    } else {
      AppStore.setMessageBoxConvictionVisibility(true);
      AppStore.setVisibilityControls({ ["ld-convictions-opt-in"]: true });
      AppStore.setVisibilityControls({
        ["message-box-cross-sell-convictions"]: true,
      });
    }
  }
}

function handleVisibilityOnLoad(controlName, radioName, dataListName) {
  if (controlName === radioName) {
    const radioValue = AppStore.getControlByName(controlName);
    const dataListValue = AppStore.getControlByName(dataListName);

    const licenceTypeName = controlName.includes("additional")
      ? controlNames.licenceTypeAdditional
      : controlNames.licenceType;
    const licenceTypeValue = AppStore.getControlByName(licenceTypeName);
    const previousPage = AppStore.getDetectedPrevPage();
    const isRecalled =
      licenceTypeValue &&
      licenceTypeValue !== "" &&
      (isNullOrUndefined(radioValue) || radioValue === "") &&
      previousPage !== "accidentinformation" &&
      previousPage !== "convictioninformation";

    if (!isNullOrUndefined(dataListValue) && dataListValue.length > 0) {
      AppStore.setVisibilityControls({ [dataListName]: true });
      AppStore.setFormData({ [radioName]: "yes" });
    } else if (isRecalled) {
      //Has no value, but has licecne = was recalled and had no claims/convictions
      AppStore.setVisibilityControls({ [dataListName]: false });
      AppStore.setFormData({ [radioName]: "" });
      AppStore.deleteFormData(dataListName);
    } else if (radioValue === "yes") {
      AppStore.setVisibilityControls({ [dataListName]: true });
    } else if (radioValue === "no") {
      AppStore.setVisibilityControls({ [dataListName]: false });
      AppStore.deleteFormData(dataListName);
    } else if (isNullOrUndefined(dataListValue) || dataListValue.length === 0) {
      AppStore.setVisibilityControls({ [dataListName]: false });
      AppStore.deleteFormData(dataListName);
    }
  }
}

function handleDataListDelete(radioName, dataListName) {
  const radioExists = AppStore.getControlExists(radioName);
  const radioValue = AppStore.getControlByName(radioName);

  if (radioExists && !isNullOrUndefined(radioValue) && radioValue !== "") {
    const dataListValue = AppStore.getControlByName(dataListName);

    if (!isNullOrUndefined(dataListValue) && dataListValue.length === 0) {
      AppStore.setFormData({ [radioName]: "" });
      AppStore.setVisibilityControls({ [dataListName]: false });
      AppStore.setHasChanged(radioName, false);
      AppStore.deleteFormData(dataListName);
    }
  }
}

function handleRadioChange(controlName, controlValue, radioName, dataListName) {
  if (controlName === radioName) {
    if (controlValue === "yes") {
      AppStore.setVisibilityControls({ [dataListName]: true });
    } else if (controlValue === "no") {
      AppStore.setVisibilityControls({ [dataListName]: false });
      AppStore.deleteFormData(dataListName);
    }
  }
}

function getIsOptionDisabled(controlName, controlValue, optionKey) {
  let returnVal = false;

  radioToDataListMapping.forEach((mapping) => {
    //When there is a claim/conviction, disable 'no' option on radio
    if (controlName === mapping[0]) {
      const dataListValue = AppStore.getControlByName(mapping[1]);
      if (
        !isNullOrUndefined(dataListValue) &&
        dataListValue.length > 0 &&
        controlValue === "yes" &&
        optionKey === "no"
      )
        returnVal = true;
    }
  });

  return returnVal;
}

function manipulateControls(controlName, controlValue) {}

function manipulateControlsOnChange(controlName, controlValue) {
  radioToDataListMapping.forEach((mapping) =>
    handleRadioChange(controlName, controlValue, ...mapping)
  );
}

function manipulateControlsPreRender(controlName) {}

function manipulateControlsPostRender(controlName, controlValue) {
  handleLicenceDefence(controlName);

  radioToDataListMapping.forEach((mapping) =>
    handleVisibilityOnLoad(controlName, ...mapping)
  );
}

function manipulateControlsPostDelete(submitSender, isError) {
  if (!isError && !isNullOrUndefined(submitSendersMapping[submitSender])) {
    handleDataListDelete(...submitSendersMapping[submitSender]);
  }
}

export default {
  manipulateControls,
  manipulateControlsOnChange,
  manipulateControlsPreRender,
  manipulateControlsPostRender,
  manipulateControlsPostDelete,
  getIsOptionDisabled,
};
