import useAdLudioPixel from "@tracking/AdLudio.jsx";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import useSaberFeedbackWidget from "../tracking/SaberFeedback.jsx";
import Vistats from "../tracking/VistatsComponent.jsx";

const Head = ({ appTitle, metaDescription }) => {
  useSaberFeedbackWidget();
  useAdLudioPixel();

  return (
    <>
      <Helmet>
        <title>{appTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=yes, shrink-to-fit=no"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=yes"
        />
      </Helmet>
      <Vistats />
    </>
  );
};
Head.propTypes = {
  themeName: PropTypes.string.isRequired,
};

export default Head;
