import DOMPurify from "dompurify";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import { resolveStaticAssetPath } from "../../helpers/staticAsset";
import AppStore from "../../store/AppStore";
import Button from "./SimpleButton.Component";

const QhbPopup = observer(({ attributes }) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  let timeout = null;

  const header =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.header)
      ? attributes.header
      : "";
  const text =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.text)
      ? attributes.text
      : "";
  const subtitle =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.subtitle)
      ? attributes.subtitle
      : "";
  const btnLabel =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.button_label)
      ? attributes.button_label
      : "";
  const imageUrl =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.imageUrl)
      ? resolveStaticAssetPath(attributes.imageUrl)
      : "";
  const imgAlt =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.imgAlt)
      ? attributes.imgAlt
      : "";
  const timerValue =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.timer)
      ? attributes.timer
      : "0";
  const popupLayout =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.layout)
      ? attributes.layout
      : "";
  const showCloseBtn =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.showCloseBtn)
      ? attributes.showCloseBtn
      : false;
  const isLayoutV2 = popupLayout === "dfp-qhb-popup-v2" ? true : false;

  const finishedLoadingResultsKeyName = "finishedLoadingResults";

  let responsesLoaded = toJS(AppStore.journeyResults.get(finishedLoadingResultsKeyName));
  responsesLoaded = responsesLoaded === undefined ? false : responsesLoaded;

  const isRecalled = AppStore.getRecallState() === "RecallComplete" ? true : false;

  const initiatePopupTimer = () => {
    timeout = setTimeout(() => {
      setIsModalVisible(false);
    }, timerValue);
  };

  const handleModalClose = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible && (responsesLoaded || isRecalled)) {
      handleModalClose();
    } else {
      initiatePopupTimer();
    }
  }, [responsesLoaded]);

  return (
    <>
      {!isLayoutV2 && (
        <div
          id="dfp-qhb"
          className={`dfp-qhb-popup d-flex flex-column overlay ${
            isModalVisible ? "is-active" : ""
          }`}>
          <div className={`qhb-card`}>
            <div className="qhb-card-head d-flex">
              <p className="qhb-card-title mb-0 m-auto">{header}</p>
              <div className="loader-container d-flex">
                <div className="loader">Loading...</div>
              </div>
              {showCloseBtn && (
                <Button
                  type="button"
                  id="qhb-close-btn"
                  name="qhb-close-btn"
                  label="X"
                  className={`qhb-close`}
                  onClick={handleModalClose}
                />
              )}
            </div>
            <div className="qhb-card-body d-flex">
              <div className="content flex-column align-items-center px-4">
                <label
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(text, {
                      USE_PROFILES: { html: true },
                      ADD_TAGS: ["a", "p", "div", "span"],
                      ADD_ATTR: ["href", "target", "name", "class"],
                    }),
                  }}></label>
                <p className="subtitle mb-0">{subtitle}</p>
              </div>
              <img src={imageUrl} alt={imgAlt} />
            </div>
            <div className="qhb-card-footer d-flex">
              <Button
                type="button"
                id="qhb-continue-btn"
                name="qhb-continue-btn"
                label={btnLabel}
                className={`qhb-continue`}
                onClick={handleModalClose}
              />
            </div>
          </div>
        </div>
      )}
      {isLayoutV2 && (
        <div
          id="dfp-qhb"
          className={`dfp-qhb-popup d-flex flex-column overlay ${
            isModalVisible ? "is-active" : ""
          } ${popupLayout}`}>
          <div className="qhb-card qhb-card-header">
            <div className="qhb-header qhb-card-head d-flex justify-content-center">
              <p className="qhb-header-title qhb-card-title m-0 mr-4">{header}</p>
              <div className="loader-container d-flex">
                <div className="loader">Loading...</div>
              </div>
            </div>
          </div>
          <div className="qhb-card qhb-card-main">
            <div className="qhb-card-head d-flex">
              <h5 className="qhb-card-title mb-0 m-auto">{subtitle}</h5>
              <button type="button" className="close p-2" onClick={handleModalClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="qhb-card-body d-flex flex-column align-items-center p-0">
              <div className="qhb-image-container d-flex col-12 py-4 justify-content-center">
                <span></span>
              </div>
              <div className="content flex-column align-items-center px-5 pt-3 text-center">
                <label
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(text, {
                      USE_PROFILES: { html: true },
                      ADD_TAGS: ["a", "p", "div", "span"],
                      ADD_ATTR: ["href", "target", "name", "class"],
                    }),
                  }}></label>
              </div>
            </div>
            <div className="qhb-card-footer d-flex">
              <Button
                type="button"
                id="qhb-continue-btn"
                name="qhb-continue-btn"
                label={btnLabel}
                className={`qhb-continue mb-4`}
                onClick={handleModalClose}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default QhbPopup;
