class UserData {
  constructor(
    quoteRef,
    timespan,
    collectedQuoteData,
    inProgressQuoteData,
    tokenValue,
    validationResult,
    userJourneyStructure,
    formJsonPath,
    activeDriver,
    responses,
    recallState,
    skin,
    version,
    entryQueryString,
    deviceType,
    uid,
    visitorId
  ) {
    this.quoteRef = quoteRef;
    this.timespan = timespan;
    this.collectedQuoteData = collectedQuoteData;
    this.inProgressQuoteData = inProgressQuoteData;
    this.tokenValue = tokenValue;
    this.validationResult = validationResult;
    this.userJourneyStructure = userJourneyStructure;
    this.formJsonPath = formJsonPath;
    this.activedriver = activeDriver;
    this.responses = responses;
    this.recallState = recallState;
    this.skin = skin;
    this.version = version;
    this.entryQueryString = entryQueryString;
    this.deviceType = deviceType;
    this.uid = uid;
    this.visitorId = visitorId;
  }
}

export default UserData;
