import { Actions, Categories, Labels, Messages } from "../constants";
import ClickStreamProvider from "../providers/ClickStreamProvider";

const sendLogoClickStream = (evAction, evMessage) => {
  return ClickStreamProvider.handleClickStreamEvent(
    Categories.General,
    evAction,
    Labels.Logo,
    evMessage
  );
}

export default {
  sendLogoClickStream,
  redirectPopupOpen: () =>
    sendLogoClickStream(Actions.LogoRedirectPopupOpen, Messages.LogoRedirectPopupOpen),
  redirectPopupClose: () =>
    sendLogoClickStream(Actions.LogoRedirectPopupClose, Messages.LogoRedirectPopupClose),
  userRedirected: () =>
    sendLogoClickStream(Actions.LogoUserRedirected, Messages.LogoUserRedirected),
};
