import split from "lodash/split";
import { cookies } from "../helpers/cookies";
import googleAnalyticsHelper from "../helpers/googleAnalyticsHelper";
import { vistatsV1Call } from "../helpers/vistatsV1Helper";

/**
 * Class used to detect 'ShouldRedirectToSsoUrl' cookie and redirecting the user to the url set in cookie
 */
export default class SsoRedirectHandler {
  constructor() {
    this.redirectSsoCookieName = "ShouldRedirectToSsoUrl";
  }
  /**
   * Checks cookies and if cookie "ShouldRedirectToSsoUrl" is present it will redirect to the url in the cookie and remove the cookie.
   */
  handleSsoRedirect = () => {
    let ssoRedirectCookieValue = cookies.get(this.redirectSsoCookieName);
    if (ssoRedirectCookieValue) {
      let entryPathsAndUrl = split(ssoRedirectCookieValue, "|");
      let entryPaths = split(entryPathsAndUrl[0], ",");
      let ssoRedirectUrl = entryPathsAndUrl[1];

      if (this.pathnameIsInCookie(entryPaths)) {
        cookies.remove(this.redirectSsoCookieName, {
          domain: "." + window.location.hostname,
          path: "/",
        });
        window.location.href = ssoRedirectUrl;
      }
    }
  };

  pathnameIsInCookie = (entryPaths) => {
    if (entryPaths) {
      for (let i = 0; i < entryPaths.length; i++) {
        if (entryPaths[i] === window.location.pathname) {
          return true;
        }
      }
    }

    return false;
  };

  processLoadFormResult = (loadformresult) => {
    if (loadformresult.data.ssoRedirectTo) {
      window.location.href = loadformresult.data.ssoRedirectTo;
    }

    if (loadformresult.data.prepopStatus) {
      googleAnalyticsHelper.pushPrepopStatus();

      vistatsV1Call(
        `Question-started`,
        window.location.href,
        () => `Page=${window.location.href}&Question-started`,
        () => `Question-started`
      );
    }
  };
}
