import { isNullOrUndefined } from "@helpers/isNullOrUndefined";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import ControlRowFactory from "../../factories/ControlRowFactory";
import { default as helpers } from "../../helpers/controlsLogicHelper";
import AppStore from "../../store/AppStore";
import Error from "./StaticControls/Error.Component";
import Info from "./StaticControls/Info.Component";
import Label from "./StaticControls/Label.Component";
import TextArea from "./StaticControls/TextArea.Component";
import Tooltip from "./StaticControls/Tooltip.Component";

const Membership = ({
  id,
  name,
  label,
  type,
  required,
  handleBlur,
  handleChange,
  currentValue,
  defaultValue,
  helpMessages,
  infoMessages,
  extraInfo,
  validation,
  attributes,
}) => {
  const customFormGroupClass = attributes?.customFormGroupClass ?? "";
  const errorClass = validation && validation.isValid === false ? "row-error" : "";
  const allowedCharsRegex = new RegExp(
    !isNullOrUndefined(attributes.allowedCharsRegex) ? attributes.allowedCharsRegex : null
  );

  //Lenght of inputs
  const sectionMaxLengths = attributes.sectionMaxLengths || {
    1: 4,
    2: 12,
  };

  let initialValues = { 1: "9939", 2: "" };
  const [membershipNumberValues, setMembershipNumberValues] = useState(initialValues);

  // returns a 16-character value to set to formData.
  const getCompleteNumber = (newValue = null, section = null) => {
    let completeValue = "";

    [1, 2].forEach((int) => {
      let newSectionValue =
        int === section && newValue ? newValue : membershipNumberValues[int]; // if an input has changed, use the newvalue for the field instead

      completeValue += newSectionValue;
    });
    return completeValue;
  };

  const changeHandler = (event, section) => {
    const formatNewValue = (value) => {
      let newValue = value;
      if (!allowedCharsRegex.test(newValue)) {
        newValue = helpers.ReplaceByRegex(name, newValue);
      }

      if (newValue.length > sectionMaxLengths[section])
        newValue = newValue.slice(0, sectionMaxLengths[section]);

      return newValue;
    };

    let newValue = formatNewValue(event.target.value);

    // set the new value for the given input
    setMembershipNumberValues(() => {
      return { ...membershipNumberValues, [section]: newValue };
    });

    handleChange({ target: { name: name, value: getCompleteNumber() } });
  };

  // on control mounting
  useEffect(() => {
    if (!isNullOrUndefined(currentValue) && currentValue.length === 16) {
      setMembershipNumberValues({
        1: currentValue.substr(0, 4),
        2: currentValue.substr(4, 12)
      });
      AppStore.setHasChanged(`${name}-1`, true);
      AppStore.setHasChanged(`${name}-2`, true);
    }
  }, []);

  // each time the membershipNumberValues change, set the form data for the control
  useEffect(() => {
    if (
      !isNullOrUndefined(membershipNumberValues) &&
      membershipNumberValues !== initialValues
    ) {
      AppStore.setFormData({ [name]: getCompleteNumber() });
    }
  }, [membershipNumberValues]);

  // Display 4 text inputs. The format
  const controlComponent = (
    <Fragment>
      <input
        className={`form-control`}
        type="text"
        id={`${name}-1`}
        name={`${name}-1`}
        data-testid={`test-${name}-first`}
        required={required}
        disabled
        value={membershipNumberValues[1]}
        maxLength={4}
        placeholder={""}
      />
      <input
        className={`form-control`}
        type="text"
        id={`${name}-2`}
        name={`${name}-2`}
        data-testid={`test-${name}-second`}
        required={required}
        onChange={(event) => {
          changeHandler(event, 2);
        }}
        value={membershipNumberValues[2]}
        maxLength={12}
        placeholder={""}
      />
    </Fragment>
  );

  const componentMapping = {
    label: (
      <Label
        key={`labelkey-${name}`}
        id={id}
        label={label}
        forInput={name}
        validation={validation}
        defaultValue={defaultValue}
      />
    ), // forInput, validation and defaultValue are added for live validation purposes.
    tooltip: (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
      />
    ),
    info: (
      <>
        {extraInfo?.map(
          (ei) =>
            ei.text && (
              <TextArea
                key={`extrainfotextkey-${name}`}
                name={`extrainfotextkey-${name}`}
                label={ei.text}
                className={"extraInfo"}
              />
            )
        )}
        <Info
          key={`infokey-${name}`}
          infoMessages={infoMessages != null ? infoMessages.info : ""}
        />
      </>
    ),
    error: (
      <Error
        key={`errorkey-${name}`}
        validation={validation}
        forInput={name}
        name={name}
      />
    ),
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `membership-form-group col-lg-4 col-md-5 col-sm-12 col-12`,
  };

  return (
    <div
      className={`form-group form-group-container form-group-${name} custom-group ${customFormGroupClass}`}>
      <div id={`row-${name}`} className={`row ${errorClass}`} name={`${errorClass}`}>
        <ControlRowFactory
          controlName={name}
          groupClasses={groupClasses}
          componentMappings={componentMapping}
        />
      </div>
    </div>
  );
};

Membership.propTypes = {
  extraInfo: PropTypes.array,
  helpMessages: PropTypes.shape({
    help: PropTypes.object,
  }),
  infoMessages: PropTypes.shape({
    info: PropTypes.object,
  }),
};

export default observer(Membership);
