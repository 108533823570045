import React, { Fragment } from "react";
import { HashLink as Link } from "react-router-hash-link";
import reactStringReplace from "react-string-replace";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import AppStore from "../../../store/AppStore";
import { GoCoCookieBanner } from "./GoCoCookieBanner";

export default (props = {}) => {
  const cookieBannerVersion = isNullOrUndefined(
    AppStore.getUserJourneySettings().cookieBanner.version
  )
    ? "v1"
    : AppStore.getUserJourneySettings().cookieBanner.version;
  const {
    onToggleConsentCookies = Function,
    onDecline = Function,
    onConfirm = Function,
  } = props;

  const closeButton =
    cookieBannerVersion !== "gocov2" ? (
      <button
        type="button"
        className="react-cookie-law-accept-btn"
        onClick={() => onConfirm()}>
        <svg
          viewBox="0 0 15 14.75"
          enableBackground="new 0 0 15 14.75;"
          xmlSpace="preserve"
          width="18"
          height="18">
          <path
            className="cross"
            d="M9.705,7.583l3.898-3.898c0.586-0.586,0.586-1.535,0-2.121s-1.535-0.586-2.121,0L7.583,5.462L3.686,1.564
	                        c-0.586-0.586-1.535-0.586-2.121,0s-0.586,1.535,0,2.121l3.898,3.898l-3.898,3.898c-0.586,0.586-0.586,1.535,0,2.121
	                        c0.293,0.293,0.677,0.439,1.061,0.439s0.768-0.146,1.061-0.439l3.898-3.898l3.898,3.898c0.293,0.293,0.677,0.439,1.061,0.439
	                        s0.768-0.146,1.061-0.439c0.586-0.586,0.586-1.535,0-2.121L9.705,7.583z"
          />
        </svg>
      </button>
    ) : (
      <div className="react-cookie-law-accept-div" onClick={() => onConfirm()}>
        <svg
          viewBox="0 0 15 14.75"
          enableBackground="new 0 0 15 14.75;"
          xmlSpace="preserve"
          width="10"
          height="10">
          <path
            className="cross"
            fill="black"
            d="M9.705,7.583l3.898-3.898c0.586-0.586,0.586-1.535,0-2.121s-1.535-0.586-2.121,0L7.583,5.462L3.686,1.564
                        c-0.586-0.586-1.535-0.586-2.121,0s-0.586,1.535,0,2.121l3.898,3.898l-3.898,3.898c-0.586,0.586-0.586,1.535,0,2.121
                        c0.293,0.293,0.677,0.439,1.061,0.439s0.768-0.146,1.061-0.439l3.898-3.898l3.898,3.898c0.293,0.293,0.677,0.439,1.061,0.439
                        s0.768-0.146,1.061-0.439c0.586-0.586,0.586-1.535,0-2.121L9.705,7.583z"
          />
        </svg>
      </div>
    );

  if (
    cookieBannerVersion === "v1" ||
    cookieBannerVersion === "nqs" ||
    cookieBannerVersion === "gocov2"
  ) {
    const {
      className = "",
      message = AppStore.getUserJourneySettings().cookieBanner &&
      AppStore.getUserJourneySettings().cookieBanner.acceptText
        ? AppStore.getUserJourneySettings().cookieBanner.acceptText
        : "",
      policyLinkExternal = AppStore.getUserJourneySettings().cookieBanner &&
      AppStore.getUserJourneySettings().cookieBanner.policyLinkExternal
        ? AppStore.getUserJourneySettings().cookieBanner.policyLinkExternal
        : "",
      policyLinkInternal = AppStore.getUserJourneySettings().cookieBanner &&
      AppStore.getUserJourneySettings().cookieBanner.policyLink
        ? `${import.meta.env.VITE_SITE_ROUTE}/${
            AppStore.getUserJourneySettings().cookieBanner.policyLink
          }#CookiePolicy`
        : "",
      policyLink = AppStore.getUserJourneySettings().cookieBanner && policyLinkExternal
        ? policyLinkExternal
        : AppStore.getUserJourneySettings().cookieBanner && policyLinkInternal
        ? policyLinkInternal
        : "",
      isPolicyLinkExternal = policyLinkExternal !== "" ? true : false,
      privacyPolicyLinkText = AppStore.getUserJourneySettings().cookieBanner &&
      AppStore.getUserJourneySettings().cookieBanner.policyLinkText
        ? AppStore.getUserJourneySettings().cookieBanner.policyLinkText
        : "cookie policy",
      showDeclineButton = false,
      acceptButtonText = "x",
      declineButtonText = AppStore.getUserJourneySettings().cookieBanner &&
      AppStore.getUserJourneySettings().cookieBanner.declineButton
        ? AppStore.getUserJourneySettings().cookieBanner.declineButton
        : "Decline",
      declineText = AppStore.getUserJourneySettings().cookieBanner &&
      AppStore.getUserJourneySettings().cookieBanner.declineText
        ? AppStore.getUserJourneySettings().cookieBanner.declineText
        : "",
    } = props;

    let declineContent = (
      <Fragment>
        {` ${declineText}`}
        <button
          type="button"
          id="cookieConsentFalse"
          className="btn btn-link react-cookie-law-decline-btn p-0"
          onClick={() => onDecline()}>
          {declineButtonText}
        </button>
        .
      </Fragment>
    );

    if (cookieBannerVersion === "nqs" || cookieBannerVersion === "gocov2") {
      declineContent = <div className="decline-content">{declineContent}</div>;
    }

    return cookieBannerVersion !== "gocov2" ? (
      <div className={`react-cookie-law-dialog ${className}`}>
        <div className="react-cookie-law-container">
          <div className="react-cookie-law-msg">
            {message}
            {isPolicyLinkExternal ? (
              <a
                href={policyLinkExternal}
                id="cookieConsentTrue"
                className="btn btn-link react-cookie-law-policy p-0"
                target="_blank"
                onClick={() => onConfirm()}>
                {privacyPolicyLinkText}
              </a>
            ) : (
              <Link
                smooth
                to={policyLink}
                id="cookieConsentTrue"
                className="btn btn-link react-cookie-law-policy p-0"
                target="_blank"
                onClick={() => onConfirm()}>
                {privacyPolicyLinkText}
              </Link>
            )}
            {"."}
            {declineContent}
          </div>

          <div className="react-cookie-law-button-wrapper">
            {showDeclineButton && (
              <button
                type="button"
                className="react-cookie-law-decline-btn"
                onClick={() => onDecline()}>
                <span>{declineButtonText}</span>
              </button>
            )}
            {closeButton}
          </div>
        </div>
      </div>
    ) : (
      <GoCoCookieBanner
        {...{
          message,
          privacyPolicyLinkText,
          policyLink,
          onConfirm,
          declineText,
          declineButtonText,
          onDecline,
          closeButton,
        }}
      />
    );
  } else if (cookieBannerVersion === "v2") {
    const {
      text = AppStore.getUserJourneySettings().cookieBanner.text,
      policyLink = AppStore.getUserJourneySettings().cookieBanner &&
      AppStore.getUserJourneySettings().cookieBanner.policyLink
        ? `${import.meta.env.VITE_SITE_ROUTE}/${
            AppStore.getUserJourneySettings().cookieBanner.policyLink
          }#CookiePolicy`
        : "",
      privacyPolicyLinkText = AppStore.getUserJourneySettings().cookieBanner
        .policyLinkText,
      declineButtonText = AppStore.getUserJourneySettings().cookieBanner
        .declineButtonText,
    } = props;

    let content = reactStringReplace(text, "%%DECLINETEXT%%", (match, i) => (
      <button
        key={`cookie-banner-decline-text`}
        type="button"
        id="cookieConsentFalse"
        className="btn btn-link react-cookie-law-decline-btn p-0"
        onClick={() => onDecline()}>
        {declineButtonText}
      </button>
    ));

    content = reactStringReplace(content, "%%PRIVACYPOLICYTEXT%%", (match, i) => (
      <Link
        key={`cookie-banner-policy-link`}
        smooth
        to={policyLink}
        id="cookieConsentTrue"
        className="btn btn-link react-cookie-law-policy p-0"
        target="_blank"
        onClick={() => onConfirm()}>
        {privacyPolicyLinkText}
      </Link>
    ));

    return (
      <div className={`react-cookie-law-dialog`}>
        <div className="react-cookie-law-container">
          <div className="react-cookie-law-msg">{content}</div>
          <div className="react-cookie-law-button-wrapper">{closeButton}</div>
        </div>
      </div>
    );
  }

  return null;
};
