import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import helper from "../../helpers/controlsLogicHelper";
import AppStore from "../../store/AppStore";
import Info from "../controls/StaticControls/Info.Component";
import Label from "../controls/StaticControls/Label.Component";
import Tooltip from "../controls/StaticControls/Tooltip.Component";

const RadioOptIn = ({
  id,
  name,
  label,
  controlValuesList,
  handleChange,
  handleInteraction,
  helpMessages,
  infoMessages,
}) => {
  let [selectedValue, setSelectedValue] = useState(
    name === "ld-convictions-opt-in"
      ? null
      : AppStore.getLicenceDefenceOccupationOptInValue()
  );

  const versionType =
    AppStore.getUserJourneySettings()?.customControls?.customRadio || "";
  const versionTypeStyle = versionType !== "" ? "radio-version-" + versionType : "";
  const info = infoMessages?.info || "";
  const infoClass = infoMessages?.info?.class || "";
  const infoPosition = infoMessages?.info?.position || "before";

  useEffect(() => {
    if (name === "ld-occupations-opt-in") {
      // Get visibility from AppStore
      const isVisible = AppStore.getLicenceDefenceOccupationOptInVisibility();

      // Set visibility for cross-sell occupations
      helper.SetCrossSellOccupationsVisibility(
        isVisible || selectedValue === "yes" || selectedValue === "no"
      );

      // Set opt-in value if necessary
      if (isVisible) {
        if (selectedValue === null) {
          const ldOccupationsOptInValue =
            AppStore.getLicenceDefenceOccupationOptInValue();
          helper.SetCrossSellOccupationsOptInValue(ldOccupationsOptInValue);
          helper.SetCrossSellConvictionsVisibility(true);
        } else if (
          selectedValue !== "yes" &&
          selectedValue !== "no" &&
          selectedValue !== null
        ) {
          helper.SetCrossSellConvictionsVisibility(true);
        } else {
          helper.SetCrossSellOccupationsOptInValue(selectedValue);
          helper.SetCrossSellConvictionsVisibility(false);
        }
      }
    }

    if (name === "ld-convictions-opt-in") {
      // Get visibility from AppStore
      const isVisible = AppStore.getLicenceDefenceConvictionOptInVisibility();

      // Set visibility for cross-sell occupations
      helper.SetCrossSellConvictionsVisibility(
        isVisible || selectedValue === "yes" || selectedValue === "no"
      );
    }
  }, [selectedValue, name]);

  return (
    <React.Fragment>
      <div
        className={`form-group form-group-container form-group-${name} ${
          versionType === "button" ? "custom-group" : ""
        }`}>
        <div className={`row`}>
          <div className="label-group col-lg-4 col-md-4 col-sm-12 col-12">
            <Label id={name} label={label} />
            <Tooltip
              helpMessages={helpMessages != null ? helpMessages.help : ""}
              controlName={name}
            />
          </div>
          {infoPosition === "before" && (
            <Info infoMessages={info} className={infoClass} />
          )}
          <div
            className={`form-group d-inline-block ${
              versionType === "button"
                ? "col-lg-8 col-md-8 col-sm-12 col-12"
                : `col-lg-4 col-md-5 col-sm-12 col-12`
            }`}>
            {controlValuesList.map((item) => (
              <div
                key={name + "_" + item.Key}
                className={`form-check form-check-inline ${versionTypeStyle} ${
                  selectedValue === item.Key ? "checked" : ""
                }`}>
                <input
                  id={name + "_" + item.Key}
                  className="form-check-input"
                  type="radio"
                  name={name}
                  value={item.Key}
                  onChange={(event) => {
                    setSelectedValue(event.currentTarget.value);
                    if (name === "ld-occupations-opt-in") {
                      helper.SetCrossSellConvictionsVisibility(false);
                    }
                    handleInteraction(event);
                    handleChange(event);
                  }}
                  checked={item.Key === selectedValue ? "checked" : ""}
                />
                <label className="form-check-label" htmlFor={name + "_" + item.Key}>
                  {item.Value}
                </label>
              </div>
            ))}
          </div>

          {infoPosition === "after" && <Info infoMessages={info} className={infoClass} />}
        </div>
      </div>
    </React.Fragment>
  );
};

RadioOptIn.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RadioOptIn;
