import React from "react";
import AppStore from "../../../store/AppStore";
import TooltipV1 from "./TooltipV1.Component";
import TooltipV2 from "./TooltipV2.Component";

const Tooltip = (props) => {
  const tooltipVersion = AppStore.getUserJourneySettings().tooltip?.version || 1;

  if (tooltipVersion === 2) return <TooltipV2 {...props} />;

  return <TooltipV1 {...props} />;
};

export default Tooltip;
