import { runInAction, toJS } from "mobx";
import AppStore from "../../src/store/AppStore";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";

class AppStoreActions {
  clearDriverDetailsOnAddDriver(jsonCollectedQuoteData) {
    AppStore.addingNewDriver = false;

    let addDrivers = AppStore.getJSControlValue("additional-drivers");
    if (!isNullOrUndefined(addDrivers)) {
      var currentAddDriverIndex = AppStore.getCurrentDriver();
      if (addDrivers.length < currentAddDriverIndex) {
        //new driver added. Remove all addDrive fields from formData
        AppStore.removePageData("additionaldriverinformation", jsonCollectedQuoteData);
      }
    }
  }

  saveConvictionInAdditionalDrivers(pageIndex) {
    if (AppStore.addingConvictionToAddDriver === true) {
      AppStore.addingConvictionToAddDriver = false;

      runInAction(() => {
        var currentDriverIndex = AppStore.getCurrentDriver() - 1;
        let name = "motoring-convictions-additional";
        let driversArray = AppStore.getControlByName("additional-drivers");

        if (!isNullOrUndefined(driversArray)) {
          var convictionsAddDriver = driversArray[currentDriverIndex];
          let convictions = convictionsAddDriver[name];
          let driverUpdatedconvictions = [...convictions];
          driverUpdatedconvictions.push(AppStore.getFormData(pageIndex));
          convictionsAddDriver[name] = driverUpdatedconvictions;
          driversArray[currentDriverIndex] = convictionsAddDriver;
          AppStore.setFormData({ "additional-drivers": driversArray });
        }
      });
    }
  }

  saveClaimInAdditionalDrivers(pageIndex) {
    if (AppStore.addingClaimToAddDriver === true) {
      AppStore.addingClaimToAddDriver = false;

      runInAction(() => {
        var currentDriverIndex = AppStore.getCurrentDriver() - 1;
        let name = "accident-claims-additional";
        let driversArray = AppStore.getControlByName("additional-drivers");

        if (!isNullOrUndefined(driversArray)) {
          var claimsAddDriver = driversArray[currentDriverIndex];
          let claims = claimsAddDriver[name];
          let driverUpdatedClaims = [...claims];
          driverUpdatedClaims.push(AppStore.getFormData(pageIndex));
          claimsAddDriver[name] = driverUpdatedClaims;
          driversArray[currentDriverIndex] = claimsAddDriver;
          AppStore.setFormData({ "additional-drivers": driversArray });
        }
      });
    }
  }

  handleDeleteSubmitAction(
    activeElement,
    submitSender,
    pageIndex,
    sendDeleteActionResultToApi
  ) {
    let nextJsonPath = activeElement.getAttribute("jsonpath");
    let endpoint = `/${import.meta.env.VITE_PAGE_API_ENDPOINT}/validateform`;

    var userData = AppStore.getUserData();
    userData.redirectTo = AppStore.getPageNameByIndex(pageIndex);
    userData.nextJsonPath = isNullOrUndefined(nextJsonPath) ? "" : nextJsonPath;

    let inProgressQuoteData;

    if (submitSender.includes("delete-accident-claims")) {
      inProgressQuoteData = this.handleClaimDelete(
        submitSender,
        inProgressQuoteData,
        pageIndex
      );
    }

    if (submitSender.includes("delete-motoring-convictions")) {
      inProgressQuoteData = this.handleConvictionDelete(
        submitSender,
        inProgressQuoteData,
        pageIndex
      );
    }

    if (submitSender.includes("delete-additional-drivers")) {
      inProgressQuoteData = this.handleAdditionalDriverDelete(
        submitSender,
        inProgressQuoteData,
        pageIndex
      );
    }

    if (submitSender.includes("delete-joint-policy")) {
      inProgressQuoteData = this.handleAdditionalRiderDelete(
        submitSender,
        inProgressQuoteData,
        pageIndex
      );
    }

    inProgressQuoteData = JSON.stringify(inProgressQuoteData);
    userData.formJsonPath = AppStore.getJsonPath(pageIndex);
    userData.activeDriver = AppStore.getCurrentDriver();
    userData.activeRider = AppStore.getAdditionalRiderIndex();

    userData = sendDeleteActionResultToApi(endpoint, inProgressQuoteData, userData);

    return userData;
  }

  handleClaimDelete(submitSender, inProgressQuoteData, pageIndex) {
    let name = submitSender.includes("accident-claims-additional")
      ? "accident-claims-additional"
      : "accident-claims";

    if (AppStore.getClaimToDelete() !== -1) {
      runInAction(() => {
        let index = AppStore.getClaimToDelete();
        let currentClaimList = AppStore.getControlByName(name);
        let updatedList = [...currentClaimList];
        updatedList.splice(index, 1);
        AppStore.setFormData({ [name]: updatedList });

        if (submitSender.includes("accident-claims-additional")) {
          var currentDriverIndex = AppStore.getCurrentDriver() - 1;
          let driversArray = AppStore.getControlByName("additional-drivers");
          if (!isNullOrUndefined(driversArray)) {
            var claimsAddDriver = driversArray[currentDriverIndex];
            let claims = claimsAddDriver[name];
            let driverUpdatedClaims = [...claims];
            driverUpdatedClaims.splice(index, 1);
            claimsAddDriver[name] = driverUpdatedClaims;
            driversArray[currentDriverIndex] = claimsAddDriver;
            AppStore.setFormData({ "additional-drivers": driversArray });
            inProgressQuoteData = toJS(driversArray[currentDriverIndex]);
          } else {
            inProgressQuoteData = AppStore.getServerQuoteData(pageIndex);
            let claims = inProgressQuoteData[name];
            let updatedClaims = [...claims];
            updatedClaims.splice(index, 1);
            inProgressQuoteData[name] = updatedClaims;
          }
        } else {
          inProgressQuoteData = AppStore.getServerQuoteData(pageIndex);
          let claims = inProgressQuoteData[name];
          let updatedClaims = [...claims];
          updatedClaims.splice(index, 1);
          inProgressQuoteData[name] = updatedClaims;
        }
      });
    }
    return inProgressQuoteData;
  }

  handleAdditionalDriverDelete(inProgressQuoteData, pageIndex) {
    let name = "additional-drivers";

    runInAction(() => {
      if (AppStore.getAdditionalDriverToDelete() !== -1) {
        let index = AppStore.getAdditionalDriverToDelete();
        AppStore.setAdditionalDriverToDelete(-1);

        let additionalDrivers = AppStore.getControlByName(name);
        if (!isNullOrUndefined(additionalDrivers) && additionalDrivers.length > 0) {
          let updatedList = [...additionalDrivers];
          updatedList.splice(index, 1);
          AppStore.setFormData({ [name]: updatedList });
        }

        inProgressQuoteData = AppStore.getServerQuoteData(pageIndex);
        let drivers = inProgressQuoteData[name];

        if (!isNullOrUndefined(drivers) && drivers.length > 0) {
          let updatedDrivers = [...drivers];
          updatedDrivers.splice(index, 1);
          inProgressQuoteData[name] = updatedDrivers;
        }
      }
    });
    return inProgressQuoteData;
  }

  handleConvictionDelete(submitSender, inProgressQuoteData, pageIndex) {
    let name = submitSender.includes("motoring-convictions-additional")
      ? "motoring-convictions-additional"
      : "motoring-convictions";

    if (AppStore.getConvictionToDelete() !== -1) {
      runInAction(() => {
        let index = AppStore.getConvictionToDelete();
        AppStore.setConvictionToDelete(-1);
        let currentConvictionList = AppStore.getControlByName(name);
        let updatedList = [...currentConvictionList];
        updatedList.splice(index, 1);
        AppStore.setFormData({ [name]: updatedList });

        if (submitSender.includes("motoring-convictions-additional")) {
          var currentDriverIndex = AppStore.getCurrentDriver() - 1;
          let driversArray = AppStore.getControlByName("additional-drivers");
          if (!isNullOrUndefined(driversArray)) {
            var ConvictionsAddDriver = driversArray[currentDriverIndex];
            let Convictions = ConvictionsAddDriver[name];
            let driverUpdatedConvictions = [...Convictions];
            driverUpdatedConvictions.splice(index, 1);
            ConvictionsAddDriver[name] = driverUpdatedConvictions;
            driversArray[currentDriverIndex] = ConvictionsAddDriver;
            AppStore.setFormData({ "additional-drivers": driversArray });
            inProgressQuoteData = toJS(driversArray[currentDriverIndex]);
          } else {
            inProgressQuoteData = AppStore.getServerQuoteData(pageIndex);
            let Convictions = inProgressQuoteData[name];
            let updatedConvictions = [...Convictions];
            updatedConvictions.splice(index, 1);
            inProgressQuoteData[name] = updatedConvictions;
          }
        } else {
          inProgressQuoteData = AppStore.getServerQuoteData(pageIndex);
          let Convictions = inProgressQuoteData[name];
          let updatedConvictions = [...Convictions];
          updatedConvictions.splice(index, 1);
          inProgressQuoteData[name] = updatedConvictions;
        }
      });
    }
    return inProgressQuoteData;
  }

  handleAdditionalRiderDelete(submitSender, inProgressQuoteData, pageIndex) {
    let index = AppStore.getAdditionalDriverToDelete();
    if (index !== -1) {
      AppStore.setAdditionalDriverToDelete(-1);
      inProgressQuoteData = AppStore.getServerQuoteData(pageIndex);
    }
    return inProgressQuoteData;
  }

  getControlsWithAction() {
    let userJourneyStructure = AppStore.getUserJourneyStructure();

    let controlsWithActions = [];

    userJourneyStructure.Pages.forEach((page) => {
      page.Components &&
        page.Components.forEach((component) => {
          component.Controls.forEach((control) => {
            if (
              !isNullOrUndefined(control.Actions) &&
              control.Actions.length > 0 &&
              !controlsWithActions.some((c) => c.Name === control.Name)
            ) {
              controlsWithActions.push(control);
            }

            if (!isNullOrUndefined(control.Children) && control.Children.length > 0) {
              control.Children.forEach((childControl) => {
                if (
                  !isNullOrUndefined(childControl.Actions) &&
                  childControl.Actions.length > 0 &&
                  !controlsWithActions.some((c) => c.Name === childControl.Name)
                ) {
                  controlsWithActions.push(childControl);
                }
              });
            }
          });
        });
    });
    return controlsWithActions;
  }

  handleAction(action, newValue, controlName) {
    switch (action.ActionName) {
      case "dependentControlsRemovedForSelectedValue":
        this.executeDependentControlsRemoval(action, newValue);
        break;
      case "changeControlVisibility":
        this.executeChangeControlVisibility(action, newValue);
        break;
      case "mapRadioWithOppositeRadio":
        this.executeMapRadioWithOppositeRadio(action, newValue, controlName);
        break;
      case "mapRadioWithOppositeCheckbox":
        this.executeMapRadioWithOppositeCheckbox(action, newValue, controlName);
        break;
      default:
        break;
    }
  }

  forEachControlActions(controlName, onActionCallback) {
    let controlsWithActions = this.getControlsWithAction();

    if (isNullOrUndefined(controlsWithActions) || controlsWithActions.length === 0)
      return;

    for (let i = 0; i < controlsWithActions.length; i++) {
      if (controlsWithActions[i].Name.toLowerCase() === controlName.toLowerCase()) {
        if (
          isNullOrUndefined(controlsWithActions[i].Actions) ||
          controlsWithActions[i].Actions.length === 0
        )
          return;

        for (let j = 0; j < controlsWithActions[i].Actions.length; j++) {
          let action = controlsWithActions[i].Actions[j];

          onActionCallback(action);
        }
      }
    }
  }

  executeActionsOnLoad(controlName, value) {
    this.forEachControlActions(controlName, (action) => {
      if (
        !isNullOrUndefined(action.ActionName) &&
        !isNullOrUndefined(action.ActionProperties) &&
        action.ActionProperties.length !== 0 &&
        action.ActionProperties.OnLoad === "true"
      )
        this.handleAction(action, value, controlName);
    });
  }

  executeActionsOnChange(changedControl, newValue) {
    this.forEachControlActions(changedControl, (action) => {
      if (
        !isNullOrUndefined(action.ActionName) &&
        !isNullOrUndefined(action.ActionProperties) &&
        action.ActionProperties.length !== 0
      )
        this.handleAction(action, newValue, changedControl);
    });
  }

  executeDependentControlsRemoval(action, newValue) {
    let dependentControls = action.ActionProperties["DependentControls"];

    if (!this.controlValueMatchesActionExpectation(action, newValue)) return;

    this.RemoveControls(dependentControls);
  }

  executeChangeControlVisibility(action, newValue) {
    if (!this.controlValueMatchesActionExpectation(action, newValue)) return;

    let controlsToBeShown = action.ActionProperties["ShowControls"];
    let controlsToBeHidden = action.ActionProperties["HideControls"];
    let controlsToBeHiddenOnCondition = action.ActionProperties["HideControlsOnCodition"];

    if (!isNullOrUndefined(controlsToBeShown) && controlsToBeShown.length > 0) {
      for (let i = 0; i < controlsToBeShown.length; i++) {
        AppStore.setVisibilityControls({ [controlsToBeShown[i]]: true });
      }
    }

    if (!isNullOrUndefined(controlsToBeHidden) && controlsToBeHidden.length > 0) {
      for (let i = 0; i < controlsToBeHidden.length; i++) {
        AppStore.setVisibilityControls({ [controlsToBeHidden[i]]: false });
        AppStore.setHasChanged(controlsToBeHidden[i], false);
      }
    }

    if (
      !isNullOrUndefined(controlsToBeHiddenOnCondition) &&
      controlsToBeHiddenOnCondition.length > 0
    )
      this.executeControlsHiddenOnCondition(controlsToBeHiddenOnCondition);
  }

  executeMapRadioWithOppositeRadio(action, newValue, controlName) {
    let dependentControlName = action.ActionProperties["MatchingControl"];
    // onChange
    if (!isNullOrUndefined(newValue) && newValue !== "") {
      if (newValue === "yes") AppStore.setFormData({ [dependentControlName]: "no" });
      else AppStore.setFormData({ [dependentControlName]: "yes" });
    } else {
      // onLoad, reverse mapping, recall
      let dependentControlValue = "";
      let quoteData = JSON.parse(AppStore.getUserData().collectedQuoteData);
      if (!isNullOrUndefined(quoteData)) {
        dependentControlValue = quoteData[dependentControlName];
        if (!isNullOrUndefined(dependentControlValue)) {
          if (dependentControlValue === "yes")
            AppStore.setFormData({ [controlName]: "no" });
          else AppStore.setFormData({ [controlName]: "yes" });
        }
      }
    }
  }

  executeMapRadioWithOppositeCheckbox(action, newValue, controlName) {
    let dependentControlName = action.ActionProperties["MatchingControl"];
    // onChange
    if (!isNullOrUndefined(newValue) && newValue !== "") {
      if (newValue === "yes") AppStore.setFormData({ [dependentControlName]: "false" });
      else AppStore.setFormData({ [dependentControlName]: "true" });
    } else {
      // onLoad, reverse mapping, recall
      let dependentControlValue = "";
      let quoteData = JSON.parse(AppStore.getUserData().collectedQuoteData);
      if (!isNullOrUndefined(quoteData)) {
        dependentControlValue = quoteData[dependentControlName];
        if (!isNullOrUndefined(dependentControlValue)) {
          if (dependentControlValue === "true")
            AppStore.setFormData({ [controlName]: "no" });
          else AppStore.setFormData({ [controlName]: "yes" });
        }
      }
    }
  }

  executeControlsHiddenOnCondition(controlsToBeHiddenOnCondition) {
    for (let k = 0; k < controlsToBeHiddenOnCondition.length; k++) {
      let controlsToHide = controlsToBeHiddenOnCondition[k]["ControlsToHide"];
      let conditions = controlsToBeHiddenOnCondition[k]["Conditions"];

      if (
        !isNullOrUndefined(controlsToHide) &&
        controlsToHide.length > 0 &&
        !isNullOrUndefined(conditions) &&
        conditions.length > 0
      ) {
        let oneConditionMatches = false;

        for (let n = 0; n < conditions.length; n++) {
          let oneCondition = conditions[n].split("=");
          let controlNameToCheck = oneCondition[0];
          let controlValueToCheck = oneCondition[1];

          let controlValue = AppStore.getControlByName(controlNameToCheck);
          if (controlValueToCheck === "undefined") {
            if (controlValue === undefined) {
              oneConditionMatches = true;
              break;
            }
          } else if (controlValue === controlValueToCheck) {
            oneConditionMatches = true;
            break;
          }
        }

        if (oneConditionMatches === true) {
          for (let j = 0; j < controlsToHide.length; j++) {
            AppStore.setVisibilityControls({ [controlsToHide[j]]: false });
          }
        }
      }
    }
  }

  controlValueMatchesActionExpectation(action, newValue) {
    let actionProps = ["AnythingOtherThan", "SelectedValue"];

    let actionValues = [];
    let actionProp;

    if (
      isNullOrUndefined(action.ActionProperties) ||
      action.ActionProperties.length === 0
    )
      return false;

    let actionPropsKeys = Object.keys(action.ActionProperties);

    for (let i = 0; i < actionProps.length; i++) {
      for (let j = 0; j < actionPropsKeys.length; j++) {
        if (actionProps[i].toLowerCase() === actionPropsKeys[j].toLowerCase()) {
          actionValues = action.ActionProperties[actionProps[i]];
          actionProp = actionProps[i];
          break;
        }
      }
    }

    if (isNullOrUndefined(actionValues) || actionValues.length === 0) return false;

    switch (actionProp.toLowerCase()) {
      case "anythingotherthan":
        return !actionValues.some(
          (v) => String(v).toLowerCase() === String(newValue).toLowerCase()
        );
      case "selectedvalue":
        return actionValues.some(
          (v) => String(v).toLowerCase() === String(newValue).toLowerCase()
        );
      default:
        return false;
    }
  }

  RemoveControls(dependentControls) {
    if (isNullOrUndefined(dependentControls) || dependentControls.length === 0) return;

    for (let i = 0; i < dependentControls.length; i++) {
      AppStore.deleteFormData(dependentControls[i]);
    }
  }
}

export default new AppStoreActions();
