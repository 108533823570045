import { observer } from "mobx-react";
import React, { Fragment, useEffect, useState } from "react";
import { controlNames } from "../../../constants";
import ControlRowFactory from "../../../factories/ControlRowFactory";
import ControlsFactory from "../../../factories/ControlsFactory";
import accessibilityHelper from "../../../helpers/accessibilityHelper";
import {
  cardSetHandler,
  formatPostcode,
  handleAddressState,
  handlePostcodeButtonClick,
  postcodeControlNames,
  showManualEntryHandler,
  updateLookupStatusOnLoad,
  updateManualInputVisibility,
} from "../../../helpers/addressLookupHelper";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import AppStore from "../../../store/AppStore";
import PostcodeButton from "../SimpleButton.Component";
import Error from "../StaticControls/Error.Component";
import Info from "../StaticControls/Info.Component";
import Label from "../StaticControls/Label.Component";
import TextArea from "../StaticControls/TextArea.Component";
import Tooltip from "../StaticControls/Tooltip.Component";
import AddressLookupInfoCard from "./AddressLookupInfoCard";

const AddressLookupCustom = ({
  id,
  name,
  label,
  type,
  required,
  children,
  currentValue,
  defaultValue,
  handleInteraction,
  handleChange,
  handleBlur,
  validateControl,
  helpMessages,
  infoMessages,
  validation,
  validationResult,
  attributes,
  extraInfo,
}) => {
  const hideOptionalHouseNumberInput = attributes.hideOptionalHouseNumberInput === true;

  const [validationState, setValidationState] = useState({
    isValid: false,
    messages: [],
  });
  const newNames = postcodeControlNames();
  const usesInfoCard = attributes.showInfoCard || false;
  const streetValue = AppStore.getControlByName(newNames.streetControlName);
  const addressState = AppStore.getAddressState();
  const addressIsManual = addressState.addressIsManual;
  const infoCardVisible = addressState.infoCardVisible;
  const addressListVisible = addressState.addressListVisible;
  const addressNotListedError = addressState.addressNotListedError;
  const addressEmptyError = addressState.addressEmptyError;
  const addressListValues = addressState.addressListValues;
  const mustHideInfoMessages = attributes.hideInfoMessagesWhenCompleted
    ? infoCardVisible
    : false;

  const [houseNameNumberValue, setHouseNumberNameValue] = useState("");

  const hasExcludedRules = true;

  const allowedCharsRegex = new RegExp(attributes?.allowedCharsRegex);
  const errorClass = validation.isValid === false ? "error" : "";
  const buttonLabel = attributes?.buttonLabel;
  const errorAddressNotFound = attributes?.errorAddressNotFound;
  const errorSearchEmpty = attributes?.errorSearchEmpty;
  const errorButtonLabel = attributes?.errorButtonLabel;

  const postcodeDropdownControl = children.find(
    (it) => it.LogicalName === "postcode-dropdown"
  );

  postcodeDropdownControl.ControlValuesList = addressListValues;

  //Check the manual fields button control value, if true, it means manual fields were visible
  if (addressIsManual === true) {
    AppStore.setVisibilityControls({ [name]: false });
  }

  //If the value for the postcode-manual is undefined, quote was recalled from v1 site
  if (
    isNullOrUndefined(
      AppStore.getControlByName(controlNames.postcodeManualControlName)
    ) &&
    currentValue
  )
    AppStore.setFormData({
      [controlNames.postcodeManualControlName]: formatPostcode(currentValue),
    });
  // **USE EFFECTS** //
  // on component load, determine the state and present control accordingly
  // (checks for manual input && info card visibility)
  useEffect(() => {
    updateLookupStatusOnLoad(true);
  }, []);

  // when the value of the 'button-address-is-manual' button is changed, update the manual input visibility accordingly
  useEffect(() => {
    if (addressIsManual === true) {
      updateManualInputVisibility(true);
    } else if (addressIsManual === false) {
      updateManualInputVisibility(false);
    }
  }, [addressIsManual]);

  // handle the error state of the component, given its custom error requirements
  // (postcode button not clicked, address not listed in dropdown)
  useEffect(() => {
    let resultValidation = { isValid: false, messages: [] };

    if (!addressNotListedError)
      AppStore.setAddressState({ addressNotListedError: false }); // There is no error for the address list

    if (addressNotListedError) resultValidation.messages = [errorAddressNotFound];
    // There is an error for the address list
    else if (addressEmptyError) resultValidation.messages = [errorSearchEmpty];
    // The postcode button has not been clicked
    else resultValidation = validation; // otherwise, normal validation applies

    setValidationState(resultValidation);
  }, [addressNotListedError, addressEmptyError, validation]);
  useEffect(() => {
    handleAddressState(usesInfoCard, infoCardVisible, streetValue);
  }, [usesInfoCard, infoCardVisible, streetValue]);
  // **end USE EFFECTS** //

  const controlComponent = (
    <div key={`controlkey-${name}`} className="postcode-container">
      {!addressIsManual && !infoCardVisible && (
        <div key={`controlkey-${name}`} className="lookup-details-container">
          <div className="input-container col-lg-5 col-md-5 col-sm-6 col-5 d-inline-block pr-0 pl-0">
            <label htmlFor={name}>{attributes.PostcodeLabel || "Postcode"}</label>
            <input
              type={
                attributes.type != null || attributes.type !== "undefined"
                  ? attributes.type
                  : type
              }
              className="form-control"
              data-testid={`test-${name}`}
              id={name}
              placeholder={
                attributes.placeholder != null || attributes.placeholder !== "undefined"
                  ? attributes.placeholder
                  : ""
              }
              name={name}
              required={required}
              onBlur={(ev) => {
                ev.target.value = formatPostcode(ev.target.value);
                handleBlur(ev, false, hasExcludedRules);
              }}
              onChange={(ev) => {
                handleInteraction(ev);
                handleChange(ev);
                AppStore.setAddressState({
                  setAddressNotListedError: false,
                  setAddressEmptyError: false,
                });
                setValidationState({ isValid: true, messages: [] });
              }}
              // we set value as current value, instead of addressLookupFields[name], as there were difficulties getting the proposer-postcode value to be set properly
              value={currentValue}
              onKeyDown={(ev) => {
                if (!allowedCharsRegex.test(ev.key)) {
                  ev.preventDefault();
                }

                if (ev.key === "Enter") {
                  ev.preventDefault();
                  handlePostcodeButtonClick(validateControl, houseNameNumberValue);
                }
              }}
              autoComplete={accessibilityHelper.getAutoCompleteAttribute(name)}
              x-autocompletetype={accessibilityHelper.getAutoCompleteAttribute(name)}
              maxLength={
                attributes.maxLength != null || attributes.maxLength !== "undefined"
                  ? attributes.maxLength
                  : "200"
              }
            />
          </div>
          {!hideOptionalHouseNumberInput && (
            <div className="input-container col-lg-5 col-md-5 col-sm-6 col-5 d-inline-block pr-0 pl-0">
              <label htmlFor={`${name}-house-number`}>
                {attributes.HouseumberLabel || "House number or name (optional)"}
              </label>
              <input
                type={
                  attributes.type != null || attributes.type !== "undefined"
                    ? attributes.type
                    : type
                }
                autoComplete={accessibilityHelper.getAutoCompleteAttribute(
                  `${name}-house-number`
                )}
                x-autocompletetype={accessibilityHelper.getAutoCompleteAttribute(
                  `${name}-house-number`
                )}
                className="form-control"
                data-testid={`test-${name}-house-number`}
                id={`${name}-house-number`}
                placeholder={
                  attributes.placeholder != null || attributes.placeholder !== "undefined"
                    ? attributes.placeholder
                    : ""
                }
                name={controlNames.houseNumberControlName}
                required={false}
                onChange={(ev) => {
                  setHouseNumberNameValue(ev.target.value);
                  handleInteraction(ev);
                }}
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    handlePostcodeButtonClick(validateControl, houseNameNumberValue);
                  }
                }}
                value={houseNameNumberValue}
                maxLength={100}
              />
            </div>
          )}
        </div>
      )}
      {infoCardVisible ? (
        ""
      ) : (
        <div className="button-container col-lg-7 col-md-7 col-sm-6 col-7 d-inline-block pr-0">
          <PostcodeButton
            id={`${name}-button`}
            type="button"
            className="btn-postcode btn-secondary"
            name={`${name}-button`}
            data-testid={`test-${name}-button`}
            onKeyDown={(event) =>
              accessibilityHelper.replicateClickAction(event, `${name}-button`)
            }
            onClick={() => {
              handlePostcodeButtonClick(validateControl, houseNameNumberValue);
            }}
            icon="search"
            label={buttonLabel}></PostcodeButton>
        </div>
      )}
      {addressListVisible && (
        <ControlsFactory
          model={postcodeDropdownControl}
          key={postcodeDropdownControl}
          validationResult={validationResult}
          handleChildChange={(event) => {
            cardSetHandler(event, true);
            setValidationState({ isValid: true, messages: [] });
          }}
          handleBlur={handleBlur}
          handleChange={handleChange}
        />
      )}
      {!infoCardVisible && attributes.additionalText && (
        <Fragment>
          <div className="container-address-not-listed">
            <p className="form-label">Or</p>
            <button
              name={`${name}-manual-button`}
              id={`${name}-manual-button`}
              className="btn btn-link btn-address-not-listed"
              data-testid={`test-${name}-manual-button`}
              onKeyDown={(event) =>
                accessibilityHelper.replicateClickAction(event, `${name}-manual-button`)
              }
              onClick={(ev) => showManualEntryHandler(ev)}>
              {attributes.customEnterDetailsManuallyLabel ?? errorButtonLabel}
            </button>
          </div>
        </Fragment>
      )}
      {infoCardVisible && (
        <Fragment>
          <AddressLookupInfoCard
            name={`${name}-info-card`}
            noButton={true}
            noDisplayLabels={true}
            displayValues={{
              1: AppStore.getControlByName(controlNames.houseNumberManualControlName),
              2: AppStore.getControlByName(controlNames.streetControlName),
              3: AppStore.getControlByName(controlNames.cityControlName),
              4: AppStore.getControlByName(name),
            }}
            label={""} // provide no label / title for the info-card - a title will be provided by the control name.
            buttonLabel={
              attributes.customChangeAddressLabel
                ? attributes.customChangeAddressLabel
                : "Change address"
            }
          />
          <div className="info-card-btn-container">
            {attributes.customPrefixChangeAddressLabel && (
              <label className="label info-card-btn-prefix">
                {attributes.customPrefixChangeAddressLabel}
              </label>
            )}
            <button
              name={`${name}-change-address`}
              id={`${name}-info-btn`}
              className="info-card-btn"
              onClick={(ev) => showManualEntryHandler(ev)}
              onKeyDown={(event) =>
                accessibilityHelper.replicateClickAction(event, `${name}-info-btn`)
              }>
              <span className="arrow-img"></span>
              <u>
                {attributes.customChangeAddressLabel
                  ? attributes.customChangeAddressLabel
                  : "Change address"}
              </u>
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );

  const componentMapping = {
    label: (
      <Label
        key={`labelkey-${name}`}
        id={id}
        label={label}
        forInput={name}
        validation={validationState}
        defaultValue={defaultValue}
      />
    ), // forInput, validation and defaultValue are added for live validation purposes.
    tooltip: helpMessages ? (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
      />
    ) : (
      ""
    ),
    info: (
      <>
        {infoMessages && !mustHideInfoMessages ? (
          <Info
            key={`infokey-${name}`}
            infoMessages={infoMessages != null ? infoMessages.info : ""}
          />
        ) : (
          ""
        )}

        {extraInfo &&
          extraInfo.map(
            (ei) =>
              ei.text && (
                <TextArea
                  key={`extrainfotextkey-${name}`}
                  name={`extrainfotextkey-${name}`}
                  label={ei.text}
                  className="extraInfo"
                />
              )
          )}
      </>
    ),
    error: <Error key={`errorkey-${name}`} validation={validationState} name={name} />,
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `col-lg-5 col-md-6 col-sm-12 col-12`,
  };

  return (
    <React.Fragment>
      <div
        className={`form-group form-group-container form-group-${name}`}
        data-testid={`test-form-group-${name}`}>
        <div id={`row-${name}`} className={`row row-${errorClass} `}>
          <ControlRowFactory
            controlName={name}
            groupClasses={groupClasses}
            componentMappings={componentMapping}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(AddressLookupCustom);
