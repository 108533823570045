import JsCookie from "js-cookie";

/**
 * @typedef {Object} CookieAttributes
 * @property {number|Date} [expires] Define when the cookie will be removed. Value can be a Number which will be interpreted as days from time of creation or a Date instance. If omitted, the cookie becomes a session cookie.
 * @property {string} [path] Define the path where the cookie is available. Defaults to '/'
 * @property {string} [domain] Define the domain where the cookie is available. Defaults to the domain of the page where the cookie was created.
 * @property {boolean} [secure] A Boolean indicating if the cookie transmission requires a secure protocol (https). Defaults to false.
 * @property {"Strict" | "Lax" | "None"} [sameSite] Asserts that a cookie must not be sent with cross-origin requests, providing some protection against cross-site request forgery attacks (CSRF)
 * @property {number} [maxAge] Indicates the number of seconds until the cookie expires. A zero or negative number will expire the cookie immediately
 */

export const cookies = {
  /** Get cookie by name
   *
   * @param {string} name
   */
  get: (name) => {
    return JsCookie.get(name);
  },
  /** Get all cookies in key:value pairs */
  getAll: () => {
    return JsCookie.get();
  },
  /**
   * Set cookie
   *
   * @param {string} name
   * @param {any} value
   * @param {CookieAttributes} [attributes]
   */
  set: (name, value, attributes) => {
    const finalAttributes = { ...attributes };

    // map 'maxAge': number to js-cookie supported 'max-age': string;
    if (typeof finalAttributes?.maxAge === "number") {
      finalAttributes["max-age"] = finalAttributes.maxAge.toString();
      delete finalAttributes.maxAge;
    }

    return JsCookie.set(name, value, finalAttributes);
  },
  /**
   * Remove cookie
   *
   * @param {string} name
   * @param {CookieAttributes} [attributes]
   */
  remove: (name, attributes) => {
    JsCookie.remove(name, attributes);
  },
};
