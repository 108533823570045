import PropTypes from "prop-types";
import React from "react";
import reactStringReplace from "react-string-replace";
import SVGIcon from "../../../components/controls/StaticControls/SVGIcon.Component";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import { preserveQueryStringParams } from "../../AppLink";

const ExtraPagesTextArea = ({ id, name, label, attributes, handleClick }) => {
  let recallUrl =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.recallUrl)
      ? attributes.recallUrl
      : "";
  let recallText =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.recallText)
      ? attributes.recallText
      : "";
  let newQuoteUrl =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.newQuoteUrl)
      ? attributes.newQuoteUrl
      : "";
  let newQuoteText =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.newQuoteText)
      ? attributes.newQuoteText
      : "";
  let phoneNo =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.phoneNumber)
      ? attributes.phoneNumber
      : "";
  let extraPageVersion =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.extraPageVersion)
      ? attributes.extraPageVersion
      : null;
  let imageUrl =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.imageUrl)
      ? attributes.imageUrl
      : "";
  let imageName =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.imageName)
      ? attributes.imageName
      : "";
  let continueText =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.continueText)
      ? attributes.continueText
      : "";
  let clickToCallText =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.clickToCallText)
      ? attributes.clickToCallText
      : null;

  let replacedRecallUrl = `${import.meta.env.VITE_SITE_ROUTE}/${recallUrl}`;
  let replacedNewQuoteUrl = `${import.meta.env.VITE_SITE_ROUTE}/${newQuoteUrl}`;

  let textLabel = label
    .replace("%%RECALLTEXT%%", recallText)
    .replace("%%NEWQUOTETEXT%%", newQuoteText)
    .replace("%%PHONENO%%", phoneNo);

  let replacedText;

  if (phoneNo !== "") {
    replacedText = reactStringReplace(textLabel, phoneNo, (match, i) => (
      <a href={`tel:${match}`} className={`phone extra-pages-phone-anchor`}>
        <span className={`phone extra-pages-phone`}>{match}</span>
      </a>
    ));
  } else {
    replacedText = textLabel;
  }

  if (recallText !== "") {
    replacedText = reactStringReplace(replacedText, recallText, (match, i) => (
      <a href={preserveQueryStringParams(`${replacedRecallUrl}`)}>{match}</a>
    ));
  }
  if (newQuoteText !== "") {
    replacedText = reactStringReplace(replacedText, newQuoteText, (match, i) => (
      <a href={preserveQueryStringParams(`${replacedNewQuoteUrl}`)}>{match}</a>
    ));
  }

  replacedText = reactStringReplace(replacedText, "<br/>", (match, i) => <br />);

  const renderIcon = () => {
    return (
      <div className="col-10 mx-auto mb-4">
        <SVGIcon
          svgName={imageName}
          svgUrl={imageUrl}
          className={`d-flex justify-content-center mx-auto`}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="qs-label d-inline-block col-lg-12 col-md-12 col-sm-12 col-12 pull-left pl-0 pr-0">
        {extraPageVersion === "v2" && renderIcon()}
        <div className={`col-10 mx-auto ${name}-label text-center`}>
          <label className={`label label-${name}`}>{replacedText}</label>
        </div>
        {extraPageVersion === "v2" && continueText !== null && (
          <div className={`col-10 mx-auto ${name}-label-continue text-center`}>
            <label className={`label label-${name}-continue`}>{continueText}</label>
          </div>
        )}
        {extraPageVersion === "v2" && (
          <div className="button-wrapper d-flex flex-wrap col-lg-8 text-center mx-auto my-3 flex-row px-lg-3">
            {clickToCallText !== null && (
              <div className="form-group button-wrapper wrapper-back-button wrapper-call-button col-6 pl-0 pr-0 ml-0 my-0">
                <a
                  className={`btn btn-secondary btn-call d-flex justify-content-center align-items-center col-12 py-0 text-none ml-0`}
                  href={`tel:${phoneNo}`}
                  key="click-to-call">
                  {clickToCallText}
                  <label className={`phone d-inline px-2 m-0 p-0`}>{phoneNo}</label>
                </a>
              </div>
            )}
            {newQuoteText !== null && (
              <div className="form-group button-wrapper wrapper-continue-button col-6 pr-0 my-0">
                <a
                  href={preserveQueryStringParams(replacedNewQuoteUrl)}
                  className={`btn btn-primary btn-new-quote col-12 py-3 mr-0`}
                  key="new-quote">
                  {newQuoteText}
                </a>
              </div>
            )}
            {recallText !== null && (
              <div className="form-group button-wrapper wrapper-recall-button col-12 my-2">
                <button
                  id={`button-recall`}
                  name={`button-recall`}
                  key={`button-recall`}
                  type="button"
                  redirectto={`${recallUrl}`}
                  className={`btn btn-recall-quote font-weight-bold col-12 py-2`}
                  onClick={handleClick}>
                  {recallText}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

ExtraPagesTextArea.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ExtraPagesTextArea;
