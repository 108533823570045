import { action, makeObservable, observable } from "mobx";

class TooltipStore {
  visibleTooltip = null;

  constructor() {
    makeObservable(this, {
      visibleTooltip: observable,
      showTooltip: action,
      hideTooltips: action,
    });
  }

  showTooltip(name) {
    this.visibleTooltip = name;
  }

  hideTooltips() {
    this.visibleTooltip = null;
  }
}

export default new TooltipStore();
