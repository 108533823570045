import React from "react";

const AddressLookupInfoCard = ({
  noDisplayLabels,
  name,
  label,
  displayValues,
  onButtonClick,
  buttonLabel,
  handleKeyDown,
}) => {
  return (
    <div className="info-card" data-testid={`${name}-container`}>
      {!noDisplayLabels && <label className="qs-label">{label}</label>}
      {Object.keys(displayValues || {}).map((itemLabel) => {
        return !noDisplayLabels ? (
          // display the values with their associated labels
          <div key={`infocard-${itemLabel}`} className="info-card-item">
            {label === "Address" ? (
              displayValues[itemLabel]
            ) : (
              <span>
                {itemLabel} : <b>{displayValues[itemLabel]}</b>
              </span>
            )}{" "}
          </div>
        ) : (
          // display the values without their associated labels
          <div key={`infocard-${itemLabel}`} className="info-card-item">
            {label === "Address" ? (
              displayValues[itemLabel]
            ) : (
              <span>
                <b>{displayValues[itemLabel]}</b>
              </span>
            )}{" "}
          </div>
        );
      })}
      {!noDisplayLabels && (
        <button
          name={`${name}-address-lookup`}
          className="info-card-btn"
          data-testid={`test-${name}-btn`}
          onClick={() => {
            onButtonClick();
          }}
          onKeyDown={handleKeyDown}
        >
          <span className="arrow-img"></span>
          <u>{buttonLabel}</u>
        </button>
      )}
    </div>
  );
};

export default AddressLookupInfoCard;
