import dayjs from "dayjs";
import validator from "validator";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";

export default {
  parseDate: (date) => {
    let [year, month, day] = date.split("-");
    let parsedDate = new Date(year, month, day);
    return isNaN(parsedDate.getDate()) ? undefined : parsedDate;
  },

  parseDateShort: (date) => {
    let [year, month, day] = date.split("-");
    let parsedDate = new Date(day, month, year);
    return isNaN(parsedDate.getDate())
      ? undefined
      : dayjs(date).format("DD/MM/YYYY");
  },

  getDateDiffInMonths: (startDate, endDate) => {
    let startDateParsed = dayjs(startDate).format("YYYY-MM-DD");
    let endDateParsed =
      endDate === dayjs()
        ? endDate.format("YYYY-MM-DD")
        : dayjs(endDate).format("YYYY-MM-DD");
    return dayjs(endDateParsed).diff(dayjs(startDateParsed), "months");
  },

  getDateDiffInDays: (startDate, endDate) => {
    let startDateParsed = dayjs(startDate).format("YYYY-MM-DD");
    let endDateParsed =
      endDate === dayjs()
        ? endDate.format("YYYY-MM-DD")
        : dayjs(endDate).format("YYYY-MM-DD");
    return dayjs(endDateParsed).diff(dayjs(startDateParsed), "days");
  },

  getDateDiffInYears: (startDate, endDate) => {
    let startDateParsed = dayjs(startDate).format("YYYY-MM-DD");
    let endDateParsed =
      endDate === dayjs()
        ? endDate.format("YYYY-MM-DD")
        : dayjs(endDate).format("YYYY-MM-DD");
    return dayjs(endDateParsed).diff(dayjs(startDateParsed), "years");
  },

  getFriendlyDate: (date) => {
    if (date !== undefined && validator.isISO8601(date, { strict: true })) {
      let returnedDate = new Date(date);
      const formattedDate = returnedDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });

      return formattedDate;
    }
    return date;
  },

  getFriendlyTimeSpanDate: (date) => {
    let convertedDate = new Date(date);
    let returnedDate = convertedDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return returnedDate;
  },

  getFriendlyTimeSpanHours: (date) => {
    if (date !== undefined && validator.isISO8601(date, { strict: true })) {
      dayjs.extend(utc);
      dayjs.extend(timezone);
      let convertedDate = dayjs(date).utc(true);
      const returnedTime = convertedDate.local().format("HH:mm");

      return returnedTime;
    }

    return date;
  },
};
