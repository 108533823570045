import { useEffect } from "react";

const getLoaderScript = () => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://js.adsrvr.org/up_loader.1.1.0.js";
  script.async = false;

  return script;
};

const getPixelAPIScript = (advertiserID, upixelID) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.text = `ttd_dom_ready( function() {
    if (typeof TTDUniversalPixelApi === 'function') {
        var universalPixelApi = new TTDUniversalPixelApi();
        universalPixelApi.init("${advertiserID}", ["${upixelID}"], "https://insight.adsrvr.org/track/up");
    }
});`;
  script.async = false;

  return script;
};

const useAdLudioPixel = () => {
  useEffect(() => {
    const advertiserID = import.meta.env.VITE_ADLUDIO_ADVERTISER_ID;
    const upixelID = import.meta.env.VITE_ADLUDIO_UPIXEL_ID;

    if (!advertiserID || !upixelID) return;

    const head = document.getElementsByTagName("head")[0];
    const loaderScript = getLoaderScript();
    const pixelAPIScript = getPixelAPIScript(advertiserID, upixelID);

    head.appendChild(loaderScript);
    loaderScript.addEventListener("load", () => {
      head.appendChild(pixelAPIScript);
    });
  }, []);
};

export default useAdLudioPixel;
