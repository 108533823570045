import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import AppStore from "../../../store/AppStore";
import { preserveQueryStringParams } from "../../AppLink";

class QuoteSummaryAmendButton extends Component {
  render() {
    let amendButtonSettings = AppStore.getUserJourneySettings().quoteSummaryAmend;
    let showAmendButton =
      !isNullOrUndefined(amendButtonSettings) &&
      !isNullOrUndefined(amendButtonSettings.showButton) &&
      amendButtonSettings.showButton === "true"
        ? true
        : false;
    let buttonTitle =
      !isNullOrUndefined(amendButtonSettings) &&
      !isNullOrUndefined(amendButtonSettings.buttonTitle)
        ? amendButtonSettings.buttonTitle
        : "Amend quote";
    let buttonName =
      !isNullOrUndefined(amendButtonSettings) &&
      !isNullOrUndefined(amendButtonSettings.buttonName)
        ? amendButtonSettings.buttonName
        : "amend-quote";
    let cssClasses =
      !isNullOrUndefined(amendButtonSettings) &&
      !isNullOrUndefined(amendButtonSettings.cssClasses)
        ? amendButtonSettings.cssClasses
        : `btn btn-amend-quote d-inline-block m-1`;
    let redirectTo =
      !isNullOrUndefined(amendButtonSettings) &&
      !isNullOrUndefined(amendButtonSettings.redirectTo)
        ? amendButtonSettings.redirectTo
        : AppStore.getUserJourneySettings()?.generalSettings?.entryPoint ?? "/";

    return (
      <React.Fragment>
        {!isNullOrUndefined(amendButtonSettings) && showAmendButton === true && (
          <button
            type={`button`}
            className={cssClasses}
            name={buttonName}
            redirectto={redirectTo}
            onClick={(event) => {
              this.handleClick(event);
            }}>
            {buttonTitle}
          </button>
        )}
      </React.Fragment>
    );
  }

  handleClick = (ev) => {
    const redirectto = ev.target.getAttribute("redirectto").toLowerCase();
    this.props.history.push(
      preserveQueryStringParams(`${import.meta.env.VITE_SITE_ROUTE}/${redirectto}`)
    );
  };
}

export default withRouter(QuoteSummaryAmendButton);
