import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { runInAction } from "mobx";
import AppStore from "../store/AppStore";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

/**
 * @typedef {'sideImage'} Special
 */

/**
 * @typedef {Object} TimeAction
 * @property {'hideAfterDate' | 'hideBeforeDate'} type
 * @property {string} date Action date in format YYYY-MM-DD
 * @property {string} [timeZone] Time zone in IANA format in which must manipulate dates
 * @property {string[]} [controls] List of control ids
 * @property {boolean} [deleteControlsFormDataOnHide] Not only hide, but also delete control values from form data
 * @property {Special[]} [specials] List of special elements which must be handled but they are not controls
 */

function hideElements(/** @type {TimeAction} */ action) {
  action.controls?.forEach((controlName) => {
    AppStore.setVisibilityControls({ [controlName]: false });

    if (action.deleteControlsFormDataOnHide) {
      AppStore.deleteFormData(controlName);
    }
  });

  runInAction(() => {
    // use `AppStore.hiddenSpecials`
    // in other files to check if something is hidden
    action.specials?.forEach((special) => {
      AppStore.hiddenSpecials.add(special);
    });
  });
}

/**
 * @param {string} actionDateStr
 * @param {string} [timeZone]
 */
function getDatesForAction(actionDateStr, timeZone) {
  const todayDate = timeZone ? dayjs().tz(timeZone) : dayjs();
  const actionDate = timeZone
    ? dayjs.tz(actionDateStr, "YYYY-MM-DD", timeZone)
    : dayjs(actionDateStr, "YYYY-MM-DD");

  return { todayDate, actionDate };
}

/**
 *
 * @param {TimeAction[]} timeActions
 */
export function processTimeActions(timeActions = []) {
  for (const action of timeActions) {
    /** @type {ReturnType<getDatesForAction>} */
    let parsedDates;

    try {
      // timezone parsing might fail due to
      // cross-browser support for timezone names
      parsedDates = getDatesForAction(action.date, action.timeZone);
    } catch (_err) {
      // if fails, fallback to user local time
      parsedDates = getDatesForAction(action.date);
    }

    const { todayDate, actionDate } = parsedDates;

    switch (action.type) {
      case "hideAfterDate": {
        if (todayDate.isAfter(actionDate.endOf("day"), "minutes")) {
          hideElements(action);
        }

        break;
      }
      case "hideBeforeDate": {
        if (todayDate.isBefore(actionDate.startOf("day"), "minutes")) {
          hideElements(action);
        }

        break;
      }
      default:
    }
  }
}
