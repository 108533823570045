import { matchPartnership } from "@helpers/partnership";
import AppStore from "@store/AppStore";
import React from "react";
import AmendButton from "../../controls/QuoteSummary/QuoteSummaryAmendButton";

const QuoteSummaryHeader = () => {
  const partnershipLogo = matchPartnership(AppStore.getUserJourneySettings().partnership);

  return (
    <header
      className="header-bar header-quotesummary m-1"
      style={
        partnershipLogo && {
          "--affiliate-logo": `url(${partnershipLogo.imageUrl})`,
          "--affiliate-logo-height": `${partnershipLogo.imageHeightPx}px`,
        }
      }>
      <div className="col-12 text-right">
        <AmendButton />

        <button
          type={`button`}
          className={`btn btn-quotesummary-close d-inline-block m-1`}
          name={`quotesummary-close`}
          onClick={(event) => {
            window.close();
          }}>
          {`Close`}
        </button>
        <button
          type={`button`}
          className={`btn btn-quotesummary-print d-inline-block m-1`}
          name={`quotesummary-print`}
          onClick={(event) => {
            window.print();
          }}>
          {`Print`}
          <i className={`fa fas fa-print ml-2`}></i>
        </button>
      </div>
    </header>
  );
};

export default QuoteSummaryHeader;
