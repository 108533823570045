/**
 * Get correct path to a static asset, considering app base url.
 *
 * @param {String} absolutePath absolute path to an asset, e.g. `/images/icon.png` or `/favicon.ico`
 *
 * @example
 * // siteRoute is '/bike'
 * const imageUrl = resolveStaticAssetPath('/images/hello.png');
 * imageUrl === '/bike/images/hello.png'
 *
 * // siteRoute is '/' or ''
 * const jsFile = resolveStaticAssetPath('/animate.js');
 * jsFile === '/animate.js'
 *
 * // paths with http or https are kept as is
 * const imageUrl = resolveStaticAssetPath('https://some.com/cool.png');
 * imageUrl === 'https://some.com/cool.png'
 */
export const resolveStaticAssetPath = (absolutePath) => {
  const isExternal = /^((http|https):\/\/)/.test(absolutePath);

  if (isExternal) return absolutePath;

  // make sure base url does not end with "/"
  // "/bike/" becomes "/bike", or "/" becomes ""
  let baseUrl = import.meta.env.BASE_URL || "/";
  baseUrl = baseUrl[baseUrl.length - 1] === "/" ? baseUrl.slice(0, -1) : baseUrl;
  // make sure base url starts with "/" if not ""
  baseUrl = baseUrl !== "" && !baseUrl.startsWith("/") ? "/" + baseUrl : baseUrl;

  return baseUrl + absolutePath;
};
