import React from "react";
import accessibilityHelper from "../../helpers/accessibilityHelper";
import helper from "../../helpers/controlsLogicHelper";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import AppStore from "../../store/AppStore";

const Button = ({
  type,
  label,
  name,
  values,
  handleClick,
  className,
  icon,
  attributes,
  formtarget,
  currentValue,
}) => {
  const defaultCssClasses = "btn-secondary col-lg-6 col-md-10 col-sm-8";
  let separator = name.lastIndexOf("-");
  let wrapperClass = name.substring(0, separator);
  let parentClassName = className
    ? `${className}`
    : attributes?.className || defaultCssClasses;
  let additionalRedirectValue = "";
  if (!isNullOrUndefined(attributes)) {
    additionalRedirectValue =
      attributes.additionalRedirectValue !== null ||
      attributes.additionalRedirectValue !== "undefined"
        ? attributes.additionalRedirectValue
        : "";
  }
  let redirectto = values;
  let buttonIcon =
    icon ||
    (!isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.icon)
      ? attributes.icon
      : null);
  let activeElement = AppStore.getActiveElementName();
  if (
    activeElement.includes("additional") &&
    additionalRedirectValue !== "" &&
    !isNullOrUndefined(additionalRedirectValue)
  ) {
    redirectto = `${additionalRedirectValue}`;
  }
  if (additionalRedirectValue !== "" && !isNullOrUndefined(additionalRedirectValue)) {
    const conditionalRedirectAgainst = !isNullOrUndefined(
      attributes.conditionalRedirectAgainst
    )
      ? attributes.conditionalRedirectAgainst
      : "";
    const conditionalRedirectValue = !isNullOrUndefined(
      attributes.conditionalRedirectValue
    )
      ? attributes.conditionalRedirectValue.split(",")
      : null;

    if (
      conditionalRedirectAgainst !== "" &&
      !isNullOrUndefined(conditionalRedirectValue)
    ) {
      if (
        conditionalRedirectValue.includes(
          AppStore.getControlByName(conditionalRedirectAgainst)
        )
      ) {
        redirectto = `${additionalRedirectValue}`;
      }
    }
  }
  const isPhoneNumber = parseInt(values);
  const excludeFromClickEvent = ["button-address-is-manual"];

  const handleButtonClick = (event) => {
    if (!excludeFromClickEvent.includes(name)) {
      AppStore.setActiveElementName(name);
      handleClick(event);
    }

    if (values !== "convictioninformation" || values !== "accidentinformation") {
      AppStore.setJsonPath("");
    }
    helper.ManipulateControlValuesOnChange(name, currentValue);

    if (name === "back-button-AdditionalRiderInformation" && AppStore.liveValidation) {
      AppStore.setIsForcedRedirectAdditionalRider(false);
    }
  };

  return (
    <div className={`form-group button-wrapper wrapper-${wrapperClass}`}>
      {isPhoneNumber ? (
        <a name={name} className={`btn ${parentClassName}`} href={`tel:${values}`}>
          <label className={`col-12 m-0`}>{label}</label>
          <label className={`col-12 m-0 font-size-md`}>{values}</label>
        </a>
      ) : (
        <button
          type={type}
          className={`btn ${parentClassName}`}
          name={name}
          data-testid={`test-${name}`}
          form={formtarget}
          redirectto={redirectto}
          onKeyDown={(event) =>
            accessibilityHelper.executeCallbackFunction(event, handleButtonClick)
          }
          onClick={handleButtonClick}>
          {buttonIcon && buttonIcon.includes("left") && (
            <i className={`fa fas fa-${buttonIcon} mr-2`}></i>
          )}
          {label}
          {buttonIcon && !buttonIcon.includes("left") && (
            <i className={`fa fas fa-${buttonIcon} ml-2`}></i>
          )}
        </button>
      )}
    </div>
  );
};

export default Button;
