export default {
  destructure: (obj) => {
    if (obj === undefined) return {};

    return {
      key: Object.keys(obj)[0],
      value: obj[Object.keys(obj)[0]],
    };
  },
};
