import PropTypes from "prop-types";
import React, { Component, createRef } from "react";

export default class Trigger extends Component {
  static propTypes = {
    type: PropTypes.string,
    children: PropTypes.object,
    setVisibility: PropTypes.func,
    getCursorPos: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      styles: {},
    };
    this.visibility = false;
    this.triggerContainer = createRef();
  }

  componentDidMount() {
    let childStyles = this.triggerContainer.current.children[0].style;
    this.setState({
      styles: {
        width: childStyles.width,
        height: childStyles.height,
        margin: childStyles.margin,
      },
    });
  }

  render() {
    const { styles } = this.state;
    return (
      <div
        accessKey={this.props.passedAccessKey}
        onMouseOver={this.onMouseOver.bind(this)}
        onMouseOut={this.onMouseOut.bind(this)}
        onMouseMove={this.onMouseMove.bind(this)}
        onClick={this.onClick.bind(this)}
        ref={this.triggerContainer}
        style={styles}>
        {this.props.children.props.children}
      </div>
    );
  }

  onMouseOver() {
    if (!this.props.desktopToggle) {
      const { setVisibility } = this.props.children.props;
      setVisibility(true, false);
    }
  }

  onMouseOut() {
    if (!this.props.desktopToggle) {
      const { setVisibility } = this.props.children.props;
      setVisibility(false, false);
    }
  }

  onMouseMove(e) {
    const { getCursorPos } = this.props.children.props;
    getCursorPos(e);
  }

  onTouchStart() {
    const { setVisibility } = this.props.children.props;
    setVisibility(true, true);
  }

  onTouchEnd() {
    const { setVisibility } = this.props.children.props;
    setVisibility(false, true);
  }

  onClick() {
    if (this.props.desktopToggle) {
      //prop from control attribute
      const { setVisibility } = this.props.children.props;
      this.visibility = !this.visibility;
      setVisibility(this.visibility, false);
    }
  }
}
