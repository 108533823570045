import ClientLogging from "../providers/loggingProvider";
import http from "../services/httpService";
import session from "../services/sessionService";
import AppStore from "../store/AppStore";

// GetVehicleModelList
export const isVehicleModelListParamsOkShort = (
  engineCC,
  manufacturer,
  yearOfManufacture
) => engineCC && engineCC !== "" && manufacturer && yearOfManufacture;
export const isVehicleModelListParamsOkExtended = (
  engineCC,
  manufacturer,
  yearOfManufacture,
  fuelType,
  transmission
) =>
  engineCC &&
  engineCC !== "" &&
  manufacturer &&
  yearOfManufacture &&
  fuelType &&
  transmission;

const vehicleLookupHelper = {
  FindVehicle: (registrationNumber) => {
    return http
      .get(
        `/${
          import.meta.env.VITE_CLIENTREQUESTS_API_ENDPOINT
        }/getvehicledetailsbyregistrationnumber`,
        {
          headers: {
            token: session.load() !== undefined ? session.load() : "",
          },
          params: {
            skin: import.meta.env.VITE_SKIN,
            version: import.meta.env.VITE_VERSION,
            registration: registrationNumber,
          },
        }
      )
      .catch((error) => {
        ClientLogging.logError(error, "Find vehicle");
      });
  },

  MapSecurityDevices: (value) => {
    try {
      switch (value) {
        case "T1":
          return "FF92";
        case "T2":
          return "FF93";
        default:
          return "NONE";
      }
    } catch (ex) {
      ClientLogging.logError(ex, "Map security devices");
    }
  },

  ConvertToDecimal: (engineCC) => {
    try {
      var temp = parseFloat(engineCC / 1000);
      if (temp.toString().length >= 4) {
        temp = temp.toFixed(1);
      }
      if (temp.toString().length === 1) {
        temp = parseFloat(temp.toString()) + parseFloat(".0");
      }
      return temp;
    } catch (ex) {
      ClientLogging.logError(ex, "convert to decimal");
      return engineCC;
    }
  },

  GetVehicleModelDetails: (
    dataSourceControlName,
    manufacturer,
    engineSize,
    yearOfManufacture,
    fuelType
  ) => {
    try {
      let modelList = [];
      let allModelsList = AppStore.getDataSourceByControlName(dataSourceControlName);
      allModelsList.forEach((item) => {
        if (
          item.manufacturer === manufacturer &&
          item.displayedEngineCC === engineSize &&
          item.manufactureStartYear <= yearOfManufacture &&
          item.manufactureEndYear >= yearOfManufacture &&
          item.fuelType === fuelType
        ) {
          modelList.push({
            key: item.abiCode,
            description: item.displayedModel,
          });
        }
      });
      return modelList;
    } catch (error) {
      ClientLogging.logError(error, "Get vehicle model details");
    }
  },

  GetVehicleModelList: async (
    manufacturer,
    engineSize,
    yearOfManufacture,
    fuelType,
    transmissionType
  ) => {
    try {
      return http.get(
        `/${
          import.meta.env.VITE_CLIENTREQUESTS_API_ENDPOINT
        }/getvehiclelightmodeldetails`,
        {
          headers: {
            token: session.load() !== undefined ? session.load() : "",
          },
          params: {
            skin: import.meta.env.VITE_SKIN,
            manufacturer: manufacturer ? manufacturer.toUpperCase() : "",
            version: import.meta.env.VITE_VERSION,
            engineSize: engineSize ? engineSize : "",
            yearOfManufacture: yearOfManufacture ? yearOfManufacture : "",
            fuelType: fuelType ? fuelType : "",
            transmissionType: transmissionType ? transmissionType : "",
          },
        }
      );
    } catch (error) {
      ClientLogging.logError(error, "get vehicle model list");
    }
  },
};

export default vehicleLookupHelper;
