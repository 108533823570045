import queryString from "query-string";
import { cookies } from "../helpers/cookies";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";

const handleRefIdReferer = ({
  CookieTTL,
  RefererCookie,
  RefIdCookie,
  BlacklistedRefIds,
  DefaultDomain,
  IgnoreDomains,
}) => {
  const queryStringDetails = queryString.parse(window.location.search);

  const now = new Date(Date.now());
  const expirationDate = now.setDate(now.getDate() + CookieTTL);

  const lowercaseReferer = document.referrer.toLowerCase();
  const domainThreshold = 100;
  const blacklistedReferers = IgnoreDomains.split(",");
  if (lowercaseReferer !== "") {
    let refererIsBlacklisted = false;
    blacklistedReferers.forEach((blr) => {
      const occurenceIndex = lowercaseReferer.indexOf(blr);
      if (occurenceIndex !== -1 && occurenceIndex < domainThreshold)
        refererIsBlacklisted = true;
    });
    if (!refererIsBlacklisted) {
      cookies.set(RefererCookie, document.referrer, {
        secure: true,
        expires: new Date(expirationDate),
        path: "/",
        domain: DefaultDomain,
        sameSite: "Lax",
      });
    }
  }
  var refid = queryStringDetails.refid;
  if (isNullOrUndefined(refid)) {
    const qsDetails = new URLSearchParams(window.location.search);
    const qsParams = new URLSearchParams();
    for (const [name, value] of qsDetails) {
      qsParams.append(name.toLowerCase(), value);
    }
    refid = qsParams.get("refid");
  }

  if (!isNullOrUndefined(refid) && refid !== "" && !BlacklistedRefIds.includes(refid)) {
    cookies.set(RefIdCookie, refid, {
      secure: true,
      expires: new Date(expirationDate),
      path: "/",
      domain: DefaultDomain,
      sameSite: "Lax",
    });
  }
};

export default handleRefIdReferer;
