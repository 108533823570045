import AppStore from "@store/AppStore";
import $ from "jquery";
import URI from "urijs";
import { cookies } from "../helpers/cookies";

//Vistats Settings
var vistatsUrl = import.meta.env.VITE_VISTATS_V1_URL;
var vistatsDomain = import.meta.env.VITE_VISTATS_V1_VISTATS_DOMAIN;
var vistatsPrefix = "Form Fill:";
var vistatsAdCallsLevel = "None";
var vistatsLoggingEnabled = import.meta.env.VITE_VISTATS_V1_LOGGING_ENABLED;
var vistatsCookieDomain = import.meta.env.VITE_VISTATS_V1_COOKIE_DOMAIN;
var vistatsPlatform = "VASQAS";
//Vistats Vars

var vistatsEnabled = import.meta.env.VITE_VISTATS_V1_ENABLED;
var vistatsStatPath = "stat.gif";
var vistatsFormPath = "form.gif";
var invitePath = "invite.js";
var timeZone = "Europe/London";
var firstResultSeenSent = false;
var quoteLoadTimeSent = false;
var nonAutoPostBackSelectChange = {};
var autoPostBackSelectChange = {};
var priorityCallSent;
var questionSetStartedTriggered = false;
var questionSetStartedUATriggered = false;
var questionSetFinishedTriggered = false;
var questionSetStartPage = () => {
  return AppStore.getUserJourneySettings()?.generalSettings?.entryPoint ?? "aboutyou";
};
var questionSetEndPage = () => {
  return (
    AppStore.getUserJourneySettings()?.generalSettings?.questionSetEndPage ??
    "VehicleDetails"
  );
};
var questionSetQuotesPage = "yourquotes";
var startQuoteCookieName = "questionSetStart";
var finishQuoteCookieName = "questionSetFinish";
var questionSetStartPages = () => [
  // (a) root page without leading and trailing slashes. Example: "bike" (env var is "/bike")
  (import.meta.env.VITE_SITE_ROUTE ?? "").replace(/^\/|\/$/g, ""),
  // (b) first page of question set. Example: "aboutyou"
  questionSetStartPage(),
  // below entries are kept for historical purposes (to be removed in 2025 and beyond)
  // (a) and (b) cover everything based on the skin config
  // therefore, you should NOT add anything to the below list
  "bike",
  "quote",
  "quote-b",
  "quote-c",
];

//TODO: use these only for the first call of the page which will need to be written
var allowedQueryStrings = [];
var queuedEvents = [];

//taken from classic platforms without any change
export function detectDevice() {
  //Get the full viewport width
  //$(window).width(); - without scrollbar
  //innerWidth - with scrollbar
  let windowWidth = window.innerWidth;
  let suffix;
  try {
    if (typeof window.matchMedia != "undefined") {
      suffix = window.matchMedia("(orientation: portrait)").matches ? "-p" : "-l";
    } else if (typeof window.msMatchMedia != "undefined") {
      suffix = window.msMatchMedia("(orientation: portrait)").matches ? "-p" : "-l";
    } else {
      suffix = "";
    }
  } catch (ex) {
    suffix = "";
  }
  if (windowWidth >= 320 && windowWidth <= 850) {
    return "mobile" + suffix;
  }
  if (windowWidth >= 768 && windowWidth <= 1024) {
    return "tablet" + suffix;
  }
  if (windowWidth >= 1025) {
    return "desktop";
  }
  return "undefined";
}

function quoteProcessFinished() {
  let quoteProcessFinishedInputs = $("div[class$='quoteProcessFinished']");
  if (quoteProcessFinishedInputs.length > 0) {
    let input = quoteProcessFinishedInputs.eq(0);
    return input.hasClass("quoteProcessFinished");
  }
  return false;
}

//initialize vistats values
$(function () {
  if (
    typeof vistatsUrl == "undefined" ||
    vistatsUrl == null ||
    typeof vistatsDomain == "undefined" ||
    vistatsDomain == null
  ) {
    vistatsEnabled = false;
  }
  allowedQueryStrings.push("RefId");
  //dispatchInitEvent();
  //dispatchUANotif();
});

function dispatchInitEvent() {
  // if (typeof GTMh != 'undefined' && ($.isFunction(GTMh.dispatch))) {
  //     window.GTMh.dispatch('vistats-initialized');
  // }
}

function dispatchUANotif() {
  // if (typeof GTMh != 'undefined' && ($.isFunction(GTMh.dispatch))) {
  //     window.GTMh.dispatch('callUAQuestionsStarted');
  // }
}

/**
 * @deprecated
 */
function pushAllowedQueryStrings(id) {
  if ($("#" + id).length > 0) {
    let valuesToPush = JSON.parse($("#" + id).val());
    if ($.isArray(valuesToPush)) {
      allowedQueryStrings = $.merge(allowedQueryStrings, valuesToPush);
    }
  }
}

function findAutoPostbackSelects() {
  let autoPostBackSelects = $(document).find("select[onchange*='__doPostBack']");
  return autoPostBackSelects;
}

function findNonAutoPostbackSelects() {
  let nonAutoPostBackSelects = $(document).find("select:not([onchange*='__doPostBack'])");
  return nonAutoPostBackSelects;
}

function findAllInputs() {
  let textInputs = $(document).find(
    'input:text, input[type="email"], input[type="tel"], input[type="AutoComplete"]'
  );
  let radioButtons = $(document).find("input:radio");
  let checkBoxes = $(document).find("input:checkbox");
  let clickables = $(document).find("input:submit, input:button, button");
  let anchors = $(document).find("a");
  let bootStrapSwitches = $(document)
    .find("input:checkbox")
    .filter(function () {
      return $(this).closest('[id$="Copy"]').length > 0;
    });
  let helpRevealers = $(document).find(".phone-cta-body");
  let helpCustomRevealers = $(document).find(".importantstuff-cta-body");

  return [
    {
      inputs: textInputs,
      event: "blur",
      valueParser: generalValueParser,
      keyParser: generalKeyParser,
      formPrefix: getVistatsPrefix(),
    },
    {
      inputs: radioButtons,
      event: "click",
      valueParser: generalValueParser,
      keyParser: generalKeyParser,
      formPrefix: getVistatsPrefix(),
    },
    {
      inputs: checkBoxes,
      event: "change",
      valueParser: checkboxValueParser,
      keyParser: generalKeyParser,
      formPrefix: getVistatsPrefix(),
    },
    {
      inputs: clickables,
      event: "click",
      valueParser: clickInputValueParser,
      keyParser: clickKeyParser,
      formPrefix: "",
    },
    {
      inputs: anchors,
      event: "click",
      valueParser: clickAnchorValueParser,
      keyParser: clickKeyParser,
      formPrefix: "",
    },
    {
      inputs: bootStrapSwitches,
      event: "switchChange.bootstrapSwitch",
      valueParser: bootstrapSwitchValueParser,
      keyParser: generalKeyParser,
      formPrefix: "",
    },
    {
      inputs: helpRevealers,
      event: "click",
      valueParser: helpRevealerValueParser,
      keyParser: clickKeyParser,
      formPrefix: "",
    },
    {
      inputs: helpCustomRevealers,
      event: "click",
      valueParser: helpRevealerCustomValueParser,
      keyParser: clickKeyParser,
      formPrefix: "",
    },
  ];
}

function findAllQuestionSetStartedTriggers() {
  let textInputs = $(document).find('input:text, input[type="email"], input[type="tel"]');
  let radioButtons = $(document).find("input:radio");
  let checkBoxes = $(document)
    .find("input:checkbox")
    .filter(function () {
      return $(this).closest('[id$="Copy"]').length == 0;
    });
  let clickables = $(document).find("input:submit");
  let bootStrapSwitches = $(document)
    .find("input:checkbox")
    .filter(function () {
      return $(this).closest('[id$="Copy"]').length > 0;
    });
  let selects = $(document).find("select");
  return [
    { inputs: textInputs, events: ["blur"] },
    { inputs: radioButtons, events: ["click"] },
    { inputs: checkBoxes, events: ["change"] },
    { inputs: clickables, events: ["click"] },
    { inputs: bootStrapSwitches, events: ["switchChange.bootstrapSwitch"] },
    { inputs: selects, events: ["change", "mousedown", "touchstart", "focus"] },
  ];
}

function bindQuestionSetStartedTriggers(dayjs) {
  let triggers = findAllQuestionSetStartedTriggers();
  $(triggers).each(function (index, el) {
    $(el.inputs).each(function (i, e) {
      $(el.events).each(function (j, ev) {
        $(e).off(ev, callQuestionsStarted);
        $(e).on(ev, { m: dayjs }, callQuestionsStarted);
      });
    });
  });
}

function callQuestionsStarted(event) {
  let qsStartSaveValues = getQuestionSetDurationSaveValues(
    questionSetStartPage(),
    event.data.m
  );

  let segments = new URL(window.location.href).pathname.split("/");
  let currentPage = segments.pop() || segments.pop();

  if (
    qsStartSaveValues != null &&
    !questionSetStartedTriggered &&
    questionSetStartPages().includes(currentPage)
  ) {
    setCookie(qsStartSaveValues.cookieName, qsStartSaveValues.cookieValue, {
      expires: 1,
    });
    singleQueryStringNotificationAction({
      data: {
        element: {
          key: "Questions-Started",
          value: qsStartSaveValues.statsValue,
        },
        valueParser: keyValueValueParser,
        keyParser: keyValueKeyParser,
        formPrefix: "",
      },
    });
    questionSetStartedTriggered = true;
    questionSetFinishedTriggered = false;
    quoteLoadTimeSent = false;
    firstResultSeenSent = false;
  }
}

function attachNotifications(inputInformation) {
  $(inputInformation).each(function (index, el) {
    el.inputs.each(function (i, e) {
      $(e).unbind(el.event, singleQueryStringNotificationAction);
      $(e).bind(
        el.event,
        {
          element: e,
          valueParser: el.valueParser,
          keyParser: el.keyParser,
          formPrefix: el.formPrefix,
          fromBind: true,
        },
        singleQueryStringNotificationAction
      );
    });
  });
}

function attachAutoPostBackSelectsNotifications(selects) {
  $(selects).each(function (index, el) {
    $(el).unbind("mousedown", autoPostBackSelectReset);
    $(el).unbind("touchstart", autoPostBackSelectReset);
    $(el).unbind("blur", autoPostBackBlurNotification);
    $(el).unbind("change", autoPostBackSelectChangeNotification);
    $(el).bind("mousedown", autoPostBackSelectReset);
    $(el).bind("touchstart", autoPostBackSelectReset);
    $(el).bind("blur", autoPostBackBlurNotification);
    $(el).bind("change", autoPostBackSelectChangeNotification);
  });
}

function attachNonAutoPostBackSelectsNotifications(selects) {
  $(selects).each(function (index, el) {
    $(el).unbind("mousedown", nonAutoPostBackSelectReset);
    $(el).unbind("touchstart", nonAutoPostBackSelectReset);
    $(el).unbind("blur", nonAutoPostBackSelectBlurNotification);
    $(el).unbind("change", nonAutoPostBackSelectChangeNotification);
    $(el).bind("mousedown", nonAutoPostBackSelectReset);
    $(el).bind("touchstart", nonAutoPostBackSelectReset);
    $(el).bind("blur", nonAutoPostBackSelectBlurNotification);
    $(el).bind("change", nonAutoPostBackSelectChangeNotification);
  });
}

function nonAutoPostBackSelectReset(event) {
  nonAutoPostBackSelectChange[event.target.id] = false;
}

function nonAutoPostBackSelectBlurNotification(event) {
  if (nonAutoPostBackSelectChange[event.target.id]) return;
  doSelectNotification(this);
}

function nonAutoPostBackSelectChangeNotification(event) {
  nonAutoPostBackSelectChange[event.target.id] = true;
  doSelectNotification(this);
}

function autoPostBackSelectReset(event) {
  autoPostBackSelectChange[event.target.id] = false;
}

function autoPostBackSelectChangeNotification(event) {
  autoPostBackSelectChange[event.target.id] = true;
  doSelectNotification(this);
}

function autoPostBackBlurNotification(event) {
  if (autoPostBackSelectChange[event.target.id]) return;
  doSelectNotification(this);
}

function doSelectNotification(select) {
  let info = { value: $(select).val(), name: select.name };
  singleQueryStringNotificationAction({
    data: {
      element: { key: info.name, value: info.value },
      valueParser: keyValueValueParser,
      keyParser: keyValueKeyParser,
      formPrefix: getVistatsPrefix(),
    },
  });
}

function generalValueParser(element) {
  let id = element.id;
  return $("#" + $.escapeSelector(id)).val();
}

function bootstrapSwitchValueParser(element) {
  let parent = $(element).closest(".bootstrap-switch-wrapper");
  if (typeof parent == "undefined" || parent == null) return "";
  let spanIdWithValue;
  if ($(parent).hasClass("bootstrap-switch-on")) {
    spanIdWithValue = "bootstrap-switch-handle-on";
  }
  if ($(parent).hasClass("bootstrap-switch-off")) {
    spanIdWithValue = "bootstrap-switch-handle-off";
  }
  if (typeof spanIdWithValue == "undefined") return "";
  let sibling = $(element)
    .siblings("." + spanIdWithValue)
    .eq(0);
  let value = $(sibling).text();
  return value;
}

function checkboxValueParser(element) {
  let data = $(element).data("inner-item");
  if (typeof data != "undefined" && data != null) return data;
  let id = element.id;
  return $.escapeSelector("#" + id)[0].checked;
}

function clickInputValueParser(element) {
  //first check data-inner-item
  //really weird stuff... element does not have data already...
  let data = $(element).data("inner-item");
  if (typeof data != "undefined" && data != null) return data;
  let name = element.name;
  if (typeof name != "undefined" && name != null && name != "") return name;
  return element.value;
}

function clickAnchorValueParser(element) {
  //first check data-inner-item
  let data = $(element).data("inner-item");
  if (typeof data != "undefined" && data != null) return data;
  //if not href return empty
  if (element.href == "undefined" || element.href == null) return "";
  let doesPostBack = element.href.toLowerCase().indexOf("_dopostback") >= 0;
  if (doesPostBack) {
    let id = element.id;
    //return id in case of postback, empty if no id
    if (typeof id != "undefined" && id != null && id != "") return id;
    return "";
  }
  //return href otherwise
  return element.href;
}

function helpRevealerCustomValueParser(element) {
  if ($(element).hasClass("close")) {
    return "ImportantInformation-Closed";
  } else {
    return "ImportantInformation-Open";
  }
}

function helpRevealerValueParser(element) {
  if ($(element).hasClass("close")) {
    return "MoreHelp-Closed";
  } else {
    return "MoreHelp-Open";
  }
}

function clickTabsValueParser(element) {
  return element.id;
}

function keyValueValueParser(element) {
  return element.value;
}

function generalKeyParser(element) {
  return element.name;
}

function clickKeyParser(element) {
  return "Click";
}

function keyValueKeyParser(element) {
  return element.key;
}

function getCurrentPageBaseUrl() {
  return $(window.location).attr("href");
}

export function singleQueryStringNotificationAction(event) {
  //TODO: this is mainly if these are called directly from another js
  if (!vistatsEnabled) return false;
  //Fix for event action before page load on mobile with low bandwith
  if (event.data === null) return false;
  let postCallEvent = event.data.postCallEvent;
  let isInstantCall = event.data.isInstantCall;

  if (isInstantCall || priorityCallSent) {
    let element = event.data.element;
    let valueParser = event.data.valueParser;
    let keyParser = event.data.keyParser;
    let formPrefix = event.data.formPrefix;
    let fromBind = event.data.fromBind;
    let formInformation = event.data.formInformation;
    let value = valueParser(element);
    let key = keyParser(element);
    let finalAttachBasePageQueryString = false;
    let finalAttachName = true;
    let attachBasePageQueryString = event.data.attachBasePageQueryString;
    let attachName = event.data.attachName;
    if (
      typeof attachBasePageQueryString != "undefined" &&
      attachBasePageQueryString != null
    ) {
      finalAttachBasePageQueryString = attachBasePageQueryString;
    }
    if (typeof attachName != "undefined" && attachName != null) {
      finalAttachName = attachName;
    }
    let finalUrl = getDefinitiveVistatsUrl(
      getCurrentPageBaseUrl(),
      formPrefix,
      [{ key: key, value: value }],
      formInformation,
      finalAttachBasePageQueryString,
      finalAttachName
    );
    setVistatsUrlOnPage(finalUrl, postCallEvent);
  } else {
    queuedEvents.push({ event: event, type: "single" });
    if (getVistatsLoggingEnabled()) {
      console.log("pushed single call event");
    }
  }

  return true;
}

function multiQueryStringNotificationAction(event) {
  //TODO: this is mainly if these are called directly from another js (e.g. from dfp.js)
  if (!vistatsEnabled) return false;

  // Event type
  let JSErrorMessage = false;
  $.each(event.data.queryStringValues, function (key, obj) {
    if (obj.key === "JSErrorMessage") {
      JSErrorMessage = obj.value;
      return false;
    }
  });

  // Check logging inhibitor
  // if (JSErrorMessage !== false && GTMh.logging.inhibitor(JSErrorMessage, "Vistats"))
  //     return false;
  if (JSErrorMessage !== false) return false;

  let postCallEvent = event.data.postCallEvent;
  let isInstantCall = event.data.isInstantCall;
  if (isInstantCall || priorityCallSent) {
    let formPrefix = event.data.formPrefix;
    let queryStringValues = event.data.queryStringValues;
    let formInformation = event.data.formInformation;
    let finalAttachBasePageQueryString = false;
    let finalAttachName = true;
    let attachBasePageQueryString = event.data.attachBasePageQueryString;
    let attachName = event.data.attachName;
    if (
      typeof attachBasePageQueryString != "undefined" &&
      attachBasePageQueryString != null
    ) {
      finalAttachBasePageQueryString = attachBasePageQueryString;
    }
    if (typeof attachName != "undefined" && attachName != null) {
      finalAttachName = attachName;
    }
    let finalUrl = getDefinitiveVistatsUrl(
      getCurrentPageBaseUrl(),
      formPrefix,
      queryStringValues,
      formInformation,
      finalAttachBasePageQueryString,
      finalAttachName
    );
    setVistatsUrlOnPage(finalUrl, postCallEvent);
  } else {
    queuedEvents.push({ event: event, type: "multi" });
    if (getVistatsLoggingEnabled()) {
    }
  }
  return true;
}

function multiQueryStringAdCallNotificationAction(event, visible) {
  let adCallsLevel = getVistatsAdCallsLevel();
  if (adCallsLevel == "None") return false;
  if (adCallsLevel == "VisibleOnly" && !visible) return false;
  return multiQueryStringNotificationAction(event);
}

function getDefinitiveVistatsUrl(
  pageUrl,
  formPrefix,
  queryStringElements,
  formInformation,
  attachBasePageQueryString,
  attachName
) {
  let u = getSessionId();
  let d = getVistatsDomain();
  let url = getVistatsUrl();
  let queryStringParts = { u: u, d: d };
  let callPath;
  if (typeof formInformation != "undefined" && formInformation != null) {
    queryStringParts["frm"] = formInformation.formCategory;
    queryStringParts["frn"] = formInformation.formName;
    queryStringParts["frd"] = formInformation.formValue;
    callPath = vistatsFormPath;
  } else {
    let r = getReferrer();
    let p = getVistatsInfo(
      pageUrl,
      formPrefix,
      queryStringElements,
      attachBasePageQueryString
    );
    queryStringParts["p"] = p;
    queryStringParts["r"] = r;
    if (attachName) {
      let n = getProposerName();
      if (n != null) {
        queryStringParts["n"] = n;
      }
    }
    callPath = vistatsStatPath;
  }
  queryStringParts["tt"] = new Date().getTime();
  if (getVistatsLoggingEnabled()) console.log(queryStringParts);
  let queryString = URI.buildQuery(queryStringParts, false, true);
  let uri = URI(url);
  let parts = {
    protocol: URI(getCurrentPageBaseUrl()).protocol(),
    username: uri.username(),
    password: uri.password(),
    hostname: uri.hostname(),
    path: callPath,
    query: queryString,
    fragment: uri.fragment(),
  };
  let finalUrl = URI.build(parts);
  return finalUrl;
}

function getInviteScriptUrl() {
  let uri = URI(getVistatsUrl());
  let queryStringParts = { domain: getVistatsDomain() };
  let queryString = URI.buildQuery(queryStringParts, false, true);
  let parts = {
    protocol: URI(getCurrentPageBaseUrl()).protocol(),
    username: uri.username(),
    password: uri.password(),
    hostname: uri.hostname(),
    path: invitePath,
    query: queryString,
    fragment: uri.fragment(),
  };
  let finalInviteUrl = URI.build(parts);
  return finalInviteUrl;
}

function setVistatsUrlOnPage(url, postCallEvent) {
  let iWO = new Image(1, 1);
  if (typeof postCallEvent != "undefined" && postCallEvent != null) {
    $(iWO).on("load", postCallEvent);
  }
  iWO.src = url;
}

function sendNonPriorityEvents() {
  priorityCallSent = true;
  let clone = $.extend(true, [], queuedEvents);
  if (getVistatsLoggingEnabled()) {
    console.log("queued events length", clone.length);
  }
  $(clone).each(function (index, el) {
    let type = el.type;
    if (type == "single") singleQueryStringNotificationAction(el.event);
    if (type == "multi") multiQueryStringNotificationAction(el.event);
  });
}

function setScriptTagOnPage(url) {
  let scriptTag = document.createElement("script");
  scriptTag.type = "text/javascript";
  scriptTag.src = url;
  $("head").append(scriptTag);
}

function vistatsLoadCallback(sender, args) {
  let isPartialLoad = args.get_isPartialLoad();
  vistatsLoad(isPartialLoad);
}

export function vistatsLoad(isPartialLoad) {
  if (!vistatsEnabled) return;
  if (!isPartialLoad) return;
  let inputInformation = findAllInputs();
  attachNotifications(inputInformation);
  let autoPostBackSelects = findAutoPostbackSelects();
  attachAutoPostBackSelectsNotifications(autoPostBackSelects);
  let nonAutoPostBackSelects = findNonAutoPostbackSelects();
  attachNonAutoPostBackSelectsNotifications(nonAutoPostBackSelects);
  sendStatsNotifications();
}

function triggerQuestionSetStartedCallback(sender, args) {
  let isPartialLoad = args.get_isPartialLoad();
  triggerQuestionSetStarted(isPartialLoad);
}

export function triggerQuestionSetStarted(isPartialLoad, dayjs) {
  if (!vistatsEnabled) return;
  if (!isPartialLoad) return;

  let vistatsOnStartPage = getQuestionSetDurationSaveValues(
    questionSetStartPage(),
    dayjs
  );

  if (vistatsOnStartPage !== null) bindQuestionSetStartedTriggers(dayjs);
}

export function triggerSetCookieForQuestionSetFinish(dayjs) {
  if (!vistatsEnabled) return;

  $("[id$='continue-button-" + questionSetEndPage() + "']").click(function () {
    let qsFinishSaveValues = getQuestionSetDurationSaveValues(
      questionSetEndPage(),
      dayjs
    );
    if (qsFinishSaveValues != null) {
      setCookie(qsFinishSaveValues.cookieName, qsFinishSaveValues.cookieValue, {
        expires: 1,
      });
    }
  });
}

export function triggerQuestionSetDuration(dayjs) {
  if (!vistatsEnabled) return;

  $("[id$='continue-button-" + questionSetEndPage() + "']").click(function () {
    let qsFinishSaveValues = getQuestionSetDurationSaveValues(
      questionSetEndPage(),
      dayjs
    );
    if (qsFinishSaveValues != null) {
      setCookie(qsFinishSaveValues.cookieName, qsFinishSaveValues.cookieValue, {
        expires: 1,
      });
    }
  });

  if (window.location.href.includes(questionSetQuotesPage)) {
    let qsRetrieveValues = getQuestionSetDurationRetrieveValues(dayjs);
    if (qsRetrieveValues != null && !questionSetFinishedTriggered) {
      questionSetFinishedTriggered = true;
      questionSetStartedTriggered = false;
      singleQueryStringNotificationAction({
        data: {
          element: {
            key: "Questions-Finished",
            value: qsRetrieveValues.finishedStatsValue,
          },
          valueParser: keyValueValueParser,
          keyParser: keyValueKeyParser,
          formPrefix: "",
        },
      });
      singleQueryStringNotificationAction({
        data: {
          element: {
            key: "Questions-Duration",
            value: qsRetrieveValues.durationStatsValue.format("HH[h]mm[m]ss[s]"),
          },
          valueParser: keyValueValueParser,
          keyParser: keyValueKeyParser,
          formPrefix: "",
        },
      });
    }
  }
}

/* send notifications inside span controls having StatsNotification attribute and StatsKey (or control id) */
function sendStatsNotifications() {
  let statsNotificationList = $("span[StatsNotification]");
  if (statsNotificationList != undefined && statsNotificationList.length > 0) {
    for (let k = 0; k < statsNotificationList.length; k++) {
      let notifCtrl = $("span[StatsNotification]")[k];
      let statsNotification = notifCtrl.getAttribute("StatsNotification");

      let statsKey = notifCtrl.getAttribute("StatsKey");
      if (statsKey == undefined || statsKey == "") statsKey = notifCtrl.id;

      if (statsNotification != "") {
        singleQueryStringNotificationAction({
          data: {
            element: { key: statsKey, value: statsNotification },
            valueParser: keyValueValueParser,
            keyParser: keyValueKeyParser,
            formPrefix: "",
          },
        });
        notifCtrl.setAttribute("StatsNotification", "");
      }
    }
  }
}

export function resultsDurationLoad(isPartialLoad, dayjs) {
  if (!vistatsEnabled) return;
  if (!isPartialLoad) return;
  if (window.location.href.includes(questionSetQuotesPage)) {
    if (!firstResultSeenSent) {
      //send first result seen
      let selectedCover = $("#selectedCover").text();
      let coverTables = $("#cover-" + selectedCover.toLowerCase() + " .broker-row");
      if (coverTables.length > 0) {
        let now = dayjs();
        let nowStatsValue = now.format("HH:mm:ss");
        singleQueryStringNotificationAction({
          data: {
            element: { key: "FirstResultSeen", value: nowStatsValue },
            valueParser: keyValueValueParser,
            keyParser: keyValueKeyParser,
            formPrefix: "",
          },
        });
        firstResultSeenSent = true;
      }
    }

    //send duration
    if (quoteProcessFinished() && !quoteLoadTimeSent) {
      let finishedMoment = dayjs().tz(timeZone);
      let qsDurationValues = getQuestionSetDurationRetrieveValues(dayjs);
      if (qsDurationValues != null) {
        let duration = dayjs.duration(finishedMoment - qsDurationValues.finishedMoment);
        singleQueryStringNotificationAction({
          data: {
            element: {
              key: "QuoteLoadTime",
              value: duration.format("HH[h]mm[m]ss[s]"),
            },
            valueParser: keyValueValueParser,
            keyParser: keyValueKeyParser,
            formPrefix: "",
          },
        });
        quoteLoadTimeSent = true;
      }
    }
  }
}

function autoPostBackSelectRequest(sender, args) {
  autoPostBackSelectChange[args.get_postBackElement().id] = true;
}

function getVistatsDomain() {
  if (typeof vistatsDomain == "string" && vistatsDomain != null) return vistatsDomain;
  return "";
}

function getVistatsUrl() {
  if (typeof vistatsUrl == "string" && vistatsUrl != null) return vistatsUrl;
  return "";
}

function getVistatsPrefix() {
  if (typeof vistatsPrefix == "string" && vistatsPrefix != null) return vistatsPrefix;
  return "";
}

function getVistatsAdCallsLevel() {
  if (typeof vistatsAdCallsLevel == "string" && vistatsAdCallsLevel != null)
    return vistatsAdCallsLevel;
  return "None";
}

function getVistatsLoggingEnabled() {
  if (typeof vistatsLoggingEnabled == "boolean" && vistatsLoggingEnabled != null)
    return vistatsLoggingEnabled;
  return false;
}

function getVistatsCookieDomain() {
  if (typeof vistatsCookieDomain == "string" && vistatsCookieDomain != null)
    return vistatsCookieDomain;
  return "";
}

function getSessionId() {
  let vistatsCookie = cookies.get("visibility_stats");
  if (typeof vistatsCookie != "undefined") return vistatsCookie;
  let date = new Date();
  date.setTime(date.getTime() + 43200 * 60 * 1000 * 3);
  let sessionId = parseInt(Math.random() * 1000) + "-" + date.getTime(); // njsscan-ignore: c05410d0822ff28ed0796e350969b95010a42ee35cee5de654dc97ae66a17b17
  let domain = getVistatsCookieDomain();
  if (domain != "") {
    cookies.set("visibility_stats", sessionId, {
      expires: date,
      domain: domain,
      path: "/",
    });
  } else {
    cookies.set("visibility_stats", sessionId, { expires: date, path: "/" });
  }
  return sessionId;
}

function getFirstVisitOnBrowser() {
  let cookie = cookies.get("visited");
  return typeof cookie == "undefined";
}

function setFirstVisitOnBrowser() {
  let domain = getVistatsCookieDomain();
  if (domain != "") {
    setCookie("visited", "visited", { domain: domain, path: "/" });
  } else {
    setCookie("visited", "visited", { path: "/" });
  }
}

function getReferrer() {
  if (document.referrer.indexOf("secure.confused") >= 0) {
    let referrerCookieVal = cookies.get("source_referer");
    if (
      typeof referrerCookieVal != "undefined" &&
      referrerCookieVal != "" &&
      !(referrerCookieVal.indexOf("secure.confused") > 0)
    ) {
      return referrerCookieVal;
    }
    return "";
  }
  return "'" + escape(document.referrer) + "'";
}

function getProposerName() {
  let forename = $("input[name$='qsTextboxFirstname']").val();
  if (typeof forename == "undefined" || forename == null) forename = "";
  let surname = $("input[name$='qsTextBoxSurname']").val();
  if (typeof surname == "undefined" || surname == null) surname = "";
  //TODO: on current live, this checks only surname. need to spec this
  if (forename.length == 0 && surname.length == 0) return null;
  //TODO: on current live name is not encoded
  return "'" + capitalize(forename) + "_" + capitalize(surname) + "'";
}

//create the p parameter for vistats call
function getVistatsInfo(
  basePage,
  formPrefix,
  queryStringValues,
  attachBasePageQueryString
) {
  let address = URI(basePage);
  let hostname = address.hostname();
  let port = address.port();
  let protocol = address.protocol();
  let path = address.path();
  let queryString = address.search();
  let queryStringAsDictionary = URI.parseQuery(queryString);
  if (!attachBasePageQueryString) {
    queryStringAsDictionary = {};
  } else {
    let clone = $.extend(true, {}, queryStringAsDictionary);
    $.each(clone, function (index, value) {
      let found = false;
      $.each(allowedQueryStrings, function (i, v) {
        if (index.toUpperCase() == v.toUpperCase()) {
          found = true;
          return;
        }
      });
      if (!found) {
        URI.removeQuery(queryStringAsDictionary, index);
      }
    });
  }
  $.each(queryStringValues, function (index, value) {
    URI.addQuery(queryStringAsDictionary, value.key, value.value);
  });
  let resultQueryString = URI.buildQuery(queryStringAsDictionary, true, false);
  let pageName = address.filename();
  let parts = {
    //TODO: on current live formPrefix is not encoded
    path:
      pageName == ""
        ? path + formPrefix
        : path.replace(pageName, formPrefix + URI.encode(pageName)),
    query: resultQueryString,
    hostname: hostname,
    port: port,
    protocol: protocol,
  };
  return "'" + URI.build(parts) + "'";
}

function capitalize(word) {
  let capitalized = word.charAt(0).toUpperCase() + word.substring(1);
  return capitalized;
}

//page tracking logic
$(document).ready(function () {
  if (!vistatsEnabled) return;
  //vistats call
  let initialCallInformation = {
    data: {
      formPrefix: "",
      queryStringValues: [],
      formInformation: null,
      attachBasePageQueryString: true,
      attachName: false,
      postCallEvent: sendNonPriorityEvents,
      isInstantCall: true,
    },
  };
  multiQueryStringNotificationAction(initialCallInformation);
  //invite.js
  let inviteUrl = getInviteScriptUrl();
  setScriptTagOnPage(inviteUrl);
});

//screen size / view port etc related logic
$(document).ready(function () {
  if (!vistatsEnabled) return;
  if (getFirstVisitOnBrowser()) {
    let viewPort = getBrowserViewPort();
    let userAgent = getUserAgent();
    let resolution = getScreenResolution();
    let layout = getDeviceLayout();
    setFirstVisitOnBrowser();
    let queryStringValues = [];
    queryStringValues.push({
      key: "ViewPort",
      value: viewPort.width + "x" + viewPort.height,
    });
    queryStringValues.push({ key: "Layout", value: layout });
    queryStringValues.push({
      key: "Resolution",
      value: resolution.width + "x" + resolution.height,
    });
    let screenInformation = {
      data: {
        formPrefix: "",
        queryStringValues: queryStringValues,
      },
    };
    multiQueryStringNotificationAction(screenInformation);

    let deviceInformationPageCall = {
      data: {
        formPrefix: "",
        queryStringValues: [{ key: "Device", value: userAgent }],
      },
    };
    multiQueryStringNotificationAction(deviceInformationPageCall);

    let formInformation = {
      formCategory: "Device",
      formName: "Device",
      formValue: userAgent,
    };
    let deviceInformation = {
      data: {
        formPrefix: "",
        queryStringValues: [],
        formInformation: formInformation,
      },
    };
    multiQueryStringNotificationAction(deviceInformation);
  }
});

function getBrowserViewPort() {
  return { width: $(window).width(), height: $(window).height() };
}

function getScreenResolution() {
  return { width: window.screen.width, height: window.screen.height };
}

function getDeviceLayout() {
  //this could be removed, most probably vistats is loaded after global functionality, but just to be safe
  if (typeof detectDevice != "function") return "noFunction";
  return detectDevice();
}

function getUserAgent() {
  return navigator.userAgent;
}

function getQuestionSetDurationSaveValues(pageName, dayjs) {
  let segments = new URL(window.location.href).pathname.split("/");
  let currentPage = segments.pop() || segments.pop();

  if (
    questionSetStartPages().includes(currentPage) ||
    questionSetEndPage().toLowerCase().includes(currentPage)
  ) {
    let cookieName = questionSetStartPages().includes(currentPage)
      ? startQuoteCookieName
      : finishQuoteCookieName;
    let now = dayjs().tz(timeZone);
    let nowCookieValue = now.format();
    let nowStatsValue = now.format("HH:mm:ss");
    return {
      cookieName: cookieName,
      cookieValue: nowCookieValue,
      statsValue: nowStatsValue,
    };
  }
  return null;
}

function getQuestionSetDurationRetrieveValues(dayjs) {
  let qsStartCookie = cookies.get(startQuoteCookieName);
  let qsFinishCookie = cookies.get(finishQuoteCookieName);

  if (typeof qsStartCookie != "undefined" && typeof qsFinishCookie != "undefined") {
    let qsStart = new Date(qsStartCookie);
    let qsFinish = new Date(qsFinishCookie);
    return {
      finishedStatsValue: dayjs(qsFinish).tz(timeZone).format("HH:mm:ss"),
      durationStatsValue: dayjs.duration(qsFinish - qsStart),
      finishedMoment: dayjs(qsFinish).tz(timeZone),
    };
  }

  return null;
}

function setCookie(name, value, /** @type {CookieAttributes} */ attributes = {}) {
  cookies.set(name, value, {
    ...attributes,
    path: attributes.path || "/",
    domain: attributes.domain || `.${window.location.hostname.replace(/^www\./i, "")}`,
  });
}

function vistatsLog(key, value) {
  let toLog = {
    data: {
      formPrefix: "",
      queryStringValues: [{ key: key, value: value }],
    },
  };
  multiQueryStringNotificationAction(toLog);
}
