import React, { useEffect, useState } from "react";
import ControlRowFactory from "../../factories/ControlRowFactory";
import { default as helper, default as helpers } from "../../helpers/controlsLogicHelper";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import AppStore from "../../store/AppStore";
import Error from "./StaticControls/Error.Component";
import Info from "./StaticControls/Info.Component";
import Label from "./StaticControls/Label.Component";
import TextArea from "./StaticControls/TextArea.Component";
import Tooltip from "./StaticControls/Tooltip.Component";

const NumericalInput = ({
  id,
  name,
  label,
  type,
  required,
  handleBlur,
  handleChange,
  handleInteraction,
  currentValue,
  defaultValue,
  helpMessages,
  infoMessages,
  extraInfo,
  validation,
  attributes,
  logicalName,
}) => {
  const customFormGroupClass = attributes?.customFormGroupClass ?? "";
  const isNqs = attributes?.isNqs || false;

  //check if the NumericalInput component has any price value info messages in the Controls.json file for min or max value price
  const hasPriceInfoMessages =
    infoMessages &&
    (!isNullOrUndefined(infoMessages.infoPriceHigh) ||
      !isNullOrUndefined(infoMessages.infoPriceLow));

  const [isInfoPriceLowVisible, setIsInfoPriceLowVisible] = useState(false);
  const [isInfoPriceHighVisible, setIsInfoPriceHighVisible] = useState(false);
  const [isInfoPriceVisible, setIsInfoPriceVisible] = useState(false);

  //additional price info message is set based on user input. On TBI NQS we show a warning if the input value is less than 500 or higher than 20 000 (infoPriceLow & infoPriceHigh)
  //other sites use a custom info message when the input value goes over 5 digits
  //info is the regular message that is displayed by default for the Vehicle Value question
  let customInfoPriceMessages = isInfoPriceVisible
    ? infoMessages?.infoPrice || ""
    : isInfoPriceLowVisible
    ? infoMessages?.infoPriceLow || ""
    : isInfoPriceHighVisible
    ? infoMessages?.infoPriceHigh || ""
    : infoMessages?.info || "";

  const upperPriceMargin =
    infoMessages?.infoPriceHigh && infoMessages.infoPriceHigh[0].upperPriceMargin
      ? infoMessages?.infoPriceHigh[0]?.upperPriceMargin
      : "";

  const lowerPriceMargin =
    infoMessages?.infoPriceLow && infoMessages.infoPriceLow[0].lowerPriceMargin
      ? infoMessages.infoPriceLow[0].lowerPriceMargin
      : "";
  const [isKeyDown, setIsKeyDown] = useState(false);

  const errorClass = validation && validation.isValid === false ? "error" : "";

  const cssClass = isNqs
    ? ""
    : name.includes("mobile-phone")
    ? `col-lg-9 col-md-7 col-sm-12 col-12`
    : `col-lg-7 col-md-3 col-sm-4 col-4`;
  const shouldSendToUnsubscribe =
    !isNullOrUndefined(attributes?.sendToUnsubscribe) &&
    attributes.sendToUnsubscribe === "true"
      ? true
      : false;

  const isVisible =
    name === "cost-of-claim" || name === "cost-of-third-party-claim"
      ? !(currentValue === null)
      : true;

  //method used by vehicle-value input to check if the user has entered an abnormal value for their bike; visibility for warning messages is set depending on the value
  //this method is called on onBlur and triggers the message for low price only when the input goes out of focus and the value is < 500
  const handleLowValueMessage = (inputName, inputValue, lowerMargin) => {
    if (hasPriceInfoMessages) {
      if (inputName === "vehicle-value" && !isNullOrUndefined(inputValue)) {
        if (parseInt(inputValue) < lowerMargin) {
          setIsInfoPriceLowVisible(true);
        } else {
          setIsInfoPriceLowVisible(false);
        }
      }
    }
  };

  //method used by vehicle-value input to check if the user has entered an abnormal value for their bike; visibility for warning messages is set depending on the value
  //this method differs a bit from the one above as it is called whenever it detects user input (onKeyUp)
  const handleHighValueMessage = (inputName, inputValue, upperMargin) => {
    inputValue = parseInt(inputValue);

    //all products except TBI NQS trigger the message when the value contains more than 4 digits. The text is stored in controls.json in infoMessages -> infoPrice
    if (!isNullOrUndefined(infoMessages) && !isNullOrUndefined(infoMessages.infoPrice)) {
      if (logicalName === "vehicle-value-mh") {
        //motorhome exclusive functionality
        if (
          inputName === "vehicle-value" &&
          Math.round(inputValue).toString().length > 5
        ) {
          setIsInfoPriceVisible(true);
        } else {
          setIsInfoPriceVisible(false);
        }
      } else if (
        inputName === "vehicle-value" &&
        Math.round(inputValue).toString().length > 4
      ) {
        setIsInfoPriceVisible(true);
      } else {
        setIsInfoPriceVisible(false);
      }
    }
    //on TBI NQS, we check first if we have custom price info messages then check the current input and trigger the message as soon as the entire value is above 20 000
    if (hasPriceInfoMessages) {
      if (
        inputName === "vehicle-value" &&
        !isNullOrUndefined(inputValue) &&
        inputValue >= upperMargin
      ) {
        setIsInfoPriceHighVisible(true);
        setIsInfoPriceLowVisible(false);
      } else {
        setIsInfoPriceLowVisible(false);
        setIsInfoPriceHighVisible(false);
      }
    }
    if (!isNullOrUndefined(infoMessages) && !isNullOrUndefined(infoMessages.infoPrice)) {
      //caravan functionality
      if (
        (inputName === "value-of-contents" || inputName === "current-value" || inputName === "value-of-equipment") &&
        Math.round(inputValue).toString().length > 4
      ) {
        setIsInfoPriceVisible(true);
      } else {
        setIsInfoPriceVisible(false);
      }
    } 
  };

  useEffect(() => {
    helper.ManipulateControlValues(name, currentValue);
  });

  const controlComponent = (
    <div key={`controlkey-${name}`} className={`${cssClass} pl-0 pr-0 ml-0 mr-0`}>
      {attributes?.prependText && (
        <div className="input-group-prepend">
          <span className="input-group-text" id="registration-image">
            {attributes.prependText}
          </span>
        </div>
      )}
      <input
        type={attributes?.type ?? type}
        className="form-control"
        data-testid={`test-${name}`}
        id={name}
        name={name}
        required={required}
        onBlur={(event) => {
          handleLowValueMessage(
            event.target.name,
            event.target.defaultValue,
            lowerPriceMargin //values are taken from controls.json file
          );
          handleBlur(event); // required for live validation
        }}
        onChange={(event) => {
          if (isKeyDown) {
            event.target.value = helpers.ReplaceByRegex(
              name,
              event.target.value,
              "keyDown"
            );
          } else {
            event.target.value = helpers.ReplaceByRegex(name, event.target.value);
          }
          handleChange(event);

          if (shouldSendToUnsubscribe) {
            AppStore.setUnsubscribeData({ proposerPhone: event.target.value });
          }
          handleInteraction(event);
        }}
        onKeyDown={(event) => {
          if (event.ctrlKey === false) setIsKeyDown(true);
        }}
        onKeyUp={(event) => {
          //values are taken form controls.json file
          handleHighValueMessage(
            event.target.name,
            event.target.defaultValue,
            upperPriceMargin
          );
          setIsKeyDown(false);
        }}
        value={currentValue}
        maxLength={attributes?.maxLength ?? "200"}
        placeholder={attributes?.placeholder ?? ""}
      />
    </div>
  );

  const componentMapping = {
    label: (
      <Label
        key={`labelkey-${name}`}
        id={id}
        label={label}
        forInput={name}
        validation={validation ? validation : ""}
        defaultValue={defaultValue}
      />
    ), // forInput, validation and defaultValue are added for live validation purposes.
    tooltip: helpMessages ? (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
      />
    ) : (
      ""
    ),
    info: (
      <>
        {extraInfo &&
          extraInfo.map(
            (ei) =>
              ei.text && (
                <TextArea
                  key={`extrainfotextkey-${name}`}
                  name={`extrainfotextkey-${name}`}
                  label={ei.text}
                  className={"extraInfo"}
                />
              )
          )}

        {infoMessages && infoMessages.info && (
          <Info
            key={`infokey-input-${name}`}
            infoMessages={infoMessages != null ? infoMessages.info : ""}
          />
        )}

        {(isInfoPriceLowVisible || isInfoPriceHighVisible || isInfoPriceVisible) && (
          <Info
            key={`priceinfo-numericalinputkey-${name}`}
            infoMessages={customInfoPriceMessages != null ? customInfoPriceMessages : ""}
          />
        )}
      </>
    ),

    error: <Error key={`errorkey-${name}`} validation={validation ? validation : ""} />,
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `col-lg-4 col-md-8 col-sm-12 col-12`,
  };

  return (
    <React.Fragment>
      {isVisible === true && (
        <div
          className={`form-group form-group-container form-group-${name} form-group-${errorClass} ${customFormGroupClass}`}>
          <div id={`row-${name}`} className={`row row-${errorClass} `}>
            <ControlRowFactory
              controlName={name}
              groupClasses={groupClasses}
              componentMappings={componentMapping}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default NumericalInput;
