import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Actions, Categories, Messages, controlNames } from "../../../constants";
import ControlRowFactory from "../../../factories/ControlRowFactory";
import ControlsFactory from "../../../factories/ControlsFactory";
import {
  cardSetHandler,
  deleteAddressFormData,
  formatPostcode,
  handleAddressState,
  postcodeButtonClickHandler,
  postcodeControlNames,
  updateLookupStatusOnLoad,
} from "../../../helpers/addressLookupHelper";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import ClickStreamProvider from "../../../providers/ClickStreamProvider";
import AppStore from "../../../store/AppStore";
import PostcodeButton from "../SimpleButton.Component";
import Error from "../StaticControls/Error.Component";
import Info from "../StaticControls/Info.Component";
import Tooltip from "../StaticControls/Tooltip.Component";
import AddressLookupInfoCard from "./AddressLookupInfoCard";

const AddressLookup = ({
  name,
  label,
  type,
  required,
  children,
  currentValue,
  handleChange,
  handleBlur,
  handleInteraction,
  helpMessages,
  infoMessages,
  validation,
  validationResult,
  attributes,
}) => {
  const usesInfoCard = attributes.showInfoCard || false;
  const newNames = postcodeControlNames();

  const houseNumberValue = AppStore.getControlByName(
    newNames.houseNumberManualControlName
  );
  const streetValue = AppStore.getControlByName(newNames.streetControlName);
  const townValue = AppStore.getControlByName(newNames.townControlName);
  const cityValue = AppStore.getControlByName(newNames.cityControlName);

  const addressState = AppStore.getAddressState();
  const addressIsManual = addressState.addressIsManual;
  const addressListVisible = addressState.addressListVisible;
  const infoCardVisible = usesInfoCard ? addressState.infoCardVisible : false;
  const postcodeAddresses = addressState.addressListValues;
  const addressNotListedError = addressState.addressNotListedError;
  const addressEmptyError = addressState.addressEmptyError;

  const [showInfoPostcodeWarning, setShowInfoPostcodeWarning] = useState(false);

  let subControls = {};
  children.map((item) => {
    let controlLogicalName = item.LogicalName;
    subControls[controlLogicalName] = item;

    if (controlLogicalName === "postcode-dropdown") {
      subControls[controlLogicalName].ControlValuesList = postcodeAddresses;
    }
  });

  const allowedCharsRegex = new RegExp(
    attributes.allowedCharsRegex != null || attributes.allowedCharsRegex != "undefined"
      ? attributes.allowedCharsRegex
      : null
  );
  const errorClass = validation && validation.isValid === false ? "error" : "";
  let buttonLabel =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.buttonLabel)
      ? attributes.buttonLabel
      : "Find my address";
  const errorLabel = attributes.errorLabel
    ? attributes.errorLabel
    : "Sorry, we cannot seem to find your address based on the postcode entered. Please check the postcode you entered and try again, or";
  const errorButtonLabel = attributes.errorButtonLabel
    ? attributes.errorButtonLabel
    : "click here to enter your address manually ";

  useEffect(() => {
    updateLookupStatusOnLoad(usesInfoCard);
  }, []);

  useEffect(() => {
    handleAddressState(usesInfoCard, infoCardVisible, streetValue);
  }, [usesInfoCard, infoCardVisible, streetValue]);

  const controlComponent = (
    <div key={`controlkey-${name}`} className="postcode-container">
      {usesInfoCard && infoCardVisible ? (
        ""
      ) : (
        <div className="col-lg-5 col-md-5 col-sm-6 col-5 d-inline-block pr-0 pl-0">
          <input
            type={
              attributes.type != null || attributes.type != "undefined"
                ? attributes.type
                : type
            }
            data-testid={`test-${name}`}
            className="form-control"
            id={name}
            placeholder={
              attributes.placeholder != null || attributes.placeholder != "undefined"
                ? attributes.placeholder
                : ""
            }
            name={name}
            required={required}
            onChange={(ev) => {
              if (attributes.showInfoCard === "true") {
                AppStore.setAddressState({ addressListVisible: false });
              }
              handleChange(ev);
              handleInteraction(ev);
            }}
            value={currentValue}
            onKeyDown={(ev) => {
              if (!allowedCharsRegex.test(ev.key)) {
                ev.preventDefault();
              }
              if (ev.key === "Enter") {
                ev.preventDefault();
                postcodeButtonClickHandler("v1");
              }
            }}
            onBlur={(ev) => {
              ev.target.value = formatPostcode(ev.target.value);
              handleBlur(ev);
              if (AppStore.getSendFullQuoteData() == "true") {
                ClickStreamProvider.handleClickStreamEvent(
                  Categories.QuestionSetCompletion,
                  Actions.ContinueQuote,
                  ev.target.name,
                  Messages.EmptyMessage
                );
              }
            }}
            autoComplete="postal-code"
            x-autocompletetype="postal-code"
            maxLength={
              attributes.maxLength != null || attributes.maxLength != "undefined"
                ? attributes.maxLength
                : "200"
            }
          />
        </div>
      )}
      {usesInfoCard && infoCardVisible ? (
        ""
      ) : (
        <div className="button-container col-lg-7 col-md-7 col-sm-6 col-7 d-inline-block pr-0">
          <PostcodeButton
            id={`${name}-button`}
            type="button"
            className="btn-postcode"
            name={`${name}-button`}
            onClick={() => {
              postcodeButtonClickHandler("v1");
            }}
            icon="search"
            label={buttonLabel}></PostcodeButton>
        </div>
      )}
    </div>
  );

  const componentMapping = {
    label: (
      <div
        key={`labelkey-${name}`}
        className="qs-label d-inline-block col-lg-10 col-md-10 col-sm-10 col-10 pull-left">
        <label htmlFor={name}>{label}</label>
      </div>
    ),
    tooltip: helpMessages && (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
      />
    ),
    info: (
      <Info
        key={`infokey-${name}`}
        infoMessages={infoMessages != null ? infoMessages.info : ""}
      />
    ),
    infoPostcodeWarning: (
      <Info
        key={`infoExtrakey-${name}`}
        infoMessages={
          !isNullOrUndefined(infoMessages) && showInfoPostcodeWarning
            ? infoMessages.infoPostcodeWarning
            : ""
        }
      />
    ),
    error: validationResult !== undefined &&
      validationResult.find((obj) => {
        return obj[`validator-${name}`] !== undefined;
      }) !== undefined && (
        <Error
          name={name}
          key={`errorkey-${name}`}
          className="next-line"
          validation={validation ? validation : ""}
        />
      ),
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `col-lg-5 col-md-6 col-sm-12 col-12`,
  };

  const defaultOrder = {
    before: [],
    after: ["error"],
    groups: [
      {
        name: "label-group",
        elements: ["label", "tooltip"],
      },
      {
        name: "form-group",
        elements: ["info", "control", "infoPostcodeWarning"],
      },
    ],
  };

  return (
    <React.Fragment>
      <div className={`form-group form-group-container form-group-${name}`}>
        <div id={`row-${name}`} className={`row row-${errorClass} `}>
          <ControlRowFactory
            controlName={name}
            groupClasses={groupClasses}
            componentMappings={
              infoCardVisible ? (componentMapping.label = "") : componentMapping
            }
            customDefaultOrder={defaultOrder}
          />
        </div>
      </div>
      {(addressNotListedError || addressEmptyError) && (
        <div
          className={`form-group form-group-container form-group-postcode-address-not-listed`}>
          <div className={`row row-error`}>
            <div className="address-not-listed-container col-lg-12 col-md-12 col-sm-12 col-12 mt-3 mb-3">
              <div className="error-message-container col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="error" data-testid="error">
                  <span>
                    {errorLabel}
                    <br />
                    <a
                      href="javascript:void(0);"
                      className="btn btn-link btn-address-not-listed"
                      onClick={(ev) => {
                        AppStore.setAddressState({
                          addressEmptyError: false,
                          addressListVisible: false,
                          addressListValues: [],
                          addressNotListedError: false,
                          addressIsManual: true,
                        });
                        setShowInfoPostcodeWarning(false);
                        deleteAddressFormData();
                        AppStore.setFormData({
                          [newNames.postcodeDropdownControlName]: "AddressNotListed",
                        });
                      }}>
                      {errorButtonLabel}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {addressListVisible && (
        <React.Fragment>
          <ControlsFactory
            model={subControls["postcode-dropdown"]}
            key={subControls["postcode-dropdown"].Name}
            validationResult={validationResult}
            handleChildChange={(ev) => {
              let { value } = ev.target;
              if (value === "AddressNotListed") {
                deleteAddressFormData([name, newNames.postcodeDropdownControlName]);
                AppStore.setFormData({
                  [newNames.postcodeDropdownControlName]: value,
                });
                AppStore.setAddressState({
                  addressListVisible: false,
                  addressListValues: [],
                  infoCardVisible: false,
                  addressIsManual: true,
                });
              } else {
                AppStore.setDisableContinue(true);
                cardSetHandler(ev, true, usesInfoCard ? "v2" : "v1");
              }
            }}
          />
        </React.Fragment>
      )}
      {usesInfoCard && infoCardVisible && (
        <AddressLookupInfoCard
          name={`${name}-info-card`}
          displayValues={{
            1: houseNumberValue,
            2: streetValue,
            3: townValue,
            4: cityValue,
            5: currentValue,
          }}
          onButtonClick={() => {
            deleteAddressFormData();
            AppStore.deleteFormData(controlNames.postcodeDropdownControlName);
            AppStore.setAddressState({ infoCardVisible: false, addressListValues: [] });
            setShowInfoPostcodeWarning(false);
          }}
          label={"Address"}
          buttonLabel={"Change address"}
        />
      )}
      {usesInfoCard &&
        infoCardVisible &&
        !streetValue &&
        AppStore.setAddressState({ addressIsManual: true, infoCardVisible: false })}
      {addressIsManual &&
        !infoCardVisible &&
        Object.values(subControls).map((item) => {
          if (item.LogicalName !== "postcode-dropdown") {
            return (
              <ControlsFactory
                model={item}
                key={item.Name}
                validationResult={validationResult}
                handleChildBlur={(ev) => {
                  handleBlur(ev);
                }}
                handleChildChange={(ev) => {
                  handleChange(ev);
                }}
              />
            );
          }
        })}
    </React.Fragment>
  );
};

export default observer(AddressLookup);
