import PropTypes from "prop-types";
import React, { Component } from "react";
import Hover from "./hover";
import Trigger from "./trigger";

class ReactHover extends Component {
  static propTypes = {
    children: PropTypes.array.isRequired,
    options: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      children: PropTypes.object,
      hoverComponentStyle: {
        display: "none",
        position: "absolute",
      },
      touchComponentStyle: {
        display: "none",
        position: "relative",
      },
    };
  }

  renderItem(item, index) {
    if (item.type.name === "Trigger" || item.props.type === "trigger") {
      return (
        <Trigger
          key={index}
          passedAccessKey={item.props.passedAccessKey}
          desktopToggle={item.props.desktopToggle}>
          {item}
        </Trigger>
      );
    } else if (item.type.name === "hover" || item.props.type === "hover") {
      return (
        <Hover key={index} passedAccessKey={item.props.passedAccessKey}>
          {item}
        </Hover>
      );
    }
  }

  render() {
    const { hoverComponentStyle } = this.state;
    let childrenWithProps = [];
    for (let child of this.props.children) {
      if (child.props) {
        if (child.type.name === "Trigger" || child.props.type === "trigger") {
          childrenWithProps.push(
            React.cloneElement(child, {
              setVisibility: this.setVisibility.bind(this),
              getCursorPos: this.getCursorPos.bind(this),
              desktopToggle: child.props.desktopToggle,
              passedAccessKey: child.props.passedAccessKey,
            })
          );
        } else if (child.type.name === "Hover" || child.props.type === "hover") {
          childrenWithProps.push(
            React.cloneElement(child, {
              styles: hoverComponentStyle,
              setVisibility: this.setVisibility.bind(this),
              getCursorPos: this.getCursorPos.bind(this),
              className: this.state.className,
              passedAccessKey: child.props.passedAccessKey,
            })
          );
        }
      }
    }

    return (
      <div className={this.props.className}>
        {childrenWithProps.map((item, index) => this.renderItem(item, index))}
      </div>
    );
  }

  setVisibility(flag, isMobile) {
    let { hoverComponentStyle, touchComponentStyle } = this.state;
    let updatedStyles = null;
    if (flag) {
      if (isMobile) {
        updatedStyles = { ...touchComponentStyle, display: "block" };
      } else {
        updatedStyles = { ...hoverComponentStyle, display: "block" };
      }
    } else {
      if (isMobile) {
        updatedStyles = { ...touchComponentStyle, display: "none" };
      } else {
        updatedStyles = { ...hoverComponentStyle, display: "none" };
      }
    }
    this.setState({
      hoverComponentStyle: updatedStyles,
      touchComponentStyle: updatedStyles,
    });
  }

  getCursorPos(e) {
    const cursorX = e.pageX;
    const cursorY = e.pageY;
    let {
      options: { followCursor, shiftX, shiftY },
    } = this.props;
    let { hoverComponentStyle } = this.state;
    let updatedStyles = null;
    if (!followCursor) {
      return;
    }
    if (isNaN(shiftX)) {
      shiftX = 0;
    }
    if (isNaN(shiftY)) {
      shiftY = 0;
    }

    updatedStyles = { ...hoverComponentStyle, top: 20, left: 30 };
    this.setState({
      hoverComponentStyle: updatedStyles,
    });
  }
}

ReactHover.Trigger = Trigger;
ReactHover.Hover = Hover;

export default ReactHover;
