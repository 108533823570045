import TagManager from "react-gtm-module";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";

const GTMComponent = {
  load() {
    const isGtmEnabled =
      !isNullOrUndefined(import.meta.env.VITE_GTM_ENABLED) &&
      import.meta.env.VITE_GTM_ENABLED === "true";

    if (isGtmEnabled) {
      const gtmID = import.meta.env.VITE_GTM_ID;
      const gtmDataLayerName = import.meta.env.VITE_DATALAYER;

      const tagManagerArgs = {
        gtmId: gtmID,
        dataLayer: {
          originalLocation:
            document.location.protocol +
            "//" +
            document.location.hostname +
            document.location.pathname +
            document.location.search,
        },
        dataLayerName: gtmDataLayerName,
      };

      TagManager.initialize(tagManagerArgs);
    } else return;
  },
};

export default GTMComponent;
